import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import Series from '../form-elements/series.component';
import { maxLengthValidation } from '../../utils/validations.util';

const VideoSeriesCategorization = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      series: videoDetail.seriesId
        ? { id: videoDetail.seriesId, title: videoDetail.seriesTitle || '' }
        : null,
      season: videoDetail.season || '',
      episodeNumber: videoDetail.episodeNumber || '',
      categories: videoDetail.categories || '',
      plotKeywords: videoDetail.plotKeywords || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      series: data?.series?.id || '',
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Series & Categorization data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form
        id="series-categorization-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="series"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Series
                  id="series"
                  name="series"
                  label="Series"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.series?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="season"
              control={control}
              rules={maxLengthValidation(2)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Season"
                  type="number"
                  error={!!errors.season}
                  helperText={errors?.season?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="episodeNumber"
              control={control}
              rules={maxLengthValidation(3)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Episode Number"
                  type="number"
                  error={!!errors.episodeNumber}
                  helperText={errors?.episodeNumber?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="categories"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Categories"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.categories}
                  helperText={errors?.categories?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="plotKeywords"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Plot Keywords"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.plotKeywords}
                  helperText={errors?.plotKeywords?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="series-categorization-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Series & Categorization
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoSeriesCategorization.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoSeriesCategorization;
