import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Series module configurations
export const LISTING_COLUMNS = [
  {
    id: 'landscapePoster',
    label: 'Landscape Poster',
    width: '10%',
    align: 'left',
    dataKey: 'landscapePoster',
    sortable: false,
    type: CELL_DATA_TYPES.LIST_IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '25%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'year',
    label: 'Year',
    width: '10%',
    align: 'left',
    dataKey: 'year',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbId',
    label: 'IMDB',
    width: '10%',
    align: 'left',
    dataKey: 'imdbId',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'right',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
  {
    action: ACTIONS.MANAGE_EPISODES.value,
    label: ACTIONS.MANAGE_EPISODES.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'landscapePoster',
    label: 'Landscape Poster (16x9)',
    dataKey: 'landscapePoster',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'portraitPoster',
    label: 'Portrair Poster (3x4)',
    dataKey: 'portraitPoster',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'year',
    label: 'Year',
    dataKey: 'year',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbId',
    label: 'IMDB ID',
    dataKey: 'imdbId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
