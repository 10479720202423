import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { VIDEOJS_OPTIONS } from '../../config/const.config';

const Player = ({ videoUrl, height, width, autoplay }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  let player = null;
  const playerOptions = VIDEOJS_OPTIONS;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = window.document.createElement('video-js');
      videoElement.id = 'video-player';
      videoElement.classList.add('vjs-default-skin');
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.style.width = '100%';
      videoElement.style.height = '100%';

      videoRef.current.appendChild(videoElement);

      playerRef.current = window.videojs(videoElement, {
        ...playerOptions,
        sources: [{ src: videoUrl }],
        autoplay,
      });

      player = playerRef.current;
    } else {
      player = playerRef.current;

      player.autoplay(autoplay);
      player.src(playerOptions.sources);
    }
  }, [videoRef, videoUrl]);

  useEffect(() => {
    player = playerRef.current;

    return () => {
      player.dispose();
      playerRef.current = null;
    };
  }, [playerRef, videoUrl]);

  return (
    <div className="data-vjs-player" style={{ width, height }}>
      <div ref={videoRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

Player.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  autoplay: PropTypes.bool.isRequired,
};

export default Player;
