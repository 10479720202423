import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import { VALID_ADMIN_ROLES } from '../config/const.config';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentChannelsView from '../views/content/channels.view';
import ContentVideosView from '../views/content/videos.view';
import ContentPlaylistsView from '../views/content/playlists.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SMTPSettingsView from '../views/smtp/smtp-settings.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import CouponCodesView from '../views/coupons/coupon-codes.view';
import BillingSubscriptionsView from '../views/billing/subscriptions.view';
import BillingPaymentsView from '../views/billing/payments.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsAppSessionsView from '../views/reports/app-sessions.view';
import ReportsActiveSubscribers from '../views/reports/active-subscribers.view';
import ReportsMigrationStatus from '../views/reports/migration-status.view';
import ReportsMigrationUsers from '../views/reports/migration-users.view';
import ReportsTrialSubscribers from '../views/reports/trial-subscribers.view';
import ReportsUsersCountOverview from '../views/reports/users-count-overview.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentAppsView from '../views/content/apps.view';
import AppContentView from '../views/content/app-content.view';
import AppDefaultContentView from '../views/content/app-default-content.view';
import AppEditView from '../views/content/app-edit.view';
import TagsLibraryView from '../views/advertisers/tag-library.view';
import AssignTagsView from '../views/advertisers/assign-tags.view';
import DeployedTagsView from '../views/advertisers/deployed-tags.view';
import GenresView from '../views/content/genres.view';
import CollectionsView from '../views/content/collections.view';
import PlaylistsView from '../views/content/content-playlists.view';
import VideosView from '../views/content/content-videos.view';
import VideoEditView from '../views/content/content-video-edit.view';
import SeriesView from '../views/content/series.view';
import ImportView from '../views/import-export/import.view';
import ChannelGenresView from '../views/content/channel-genres.view';
import ManageDescriptionsView from '../views/content/manage-descriptions.view';
import ExportView from '../views/import-export/export.view';
import ContentOwnersView from '../views/content-owner/content-owner.view';
import AutoLogin from '../views/auth/AutoLogin';
import WorkspaceView from '../views/workspace/workspace.view';
import useAccess from '../hooks/access.hook';
import VideoViewDetails from '../views/content/content-video-details.view';
import ChannelAssignmentView from '../views/content/channel-assignment.view';
import LicenseManagementView from '../views/content/license-management.view';
import AppViewDetails from '../views/content/app-details.view';
import ManageRules from '../views/rulesets/manage-rules.view';
import ProfileView from '../views/auth/profile.view';
import PortalUsersView from '../views/content-owner/portal-users.view';
import VideoPreviewView from '../views/content/content-video-preview.view';
import SchedulingContentView from '../views/rulesets/scheduling-content.view';
import HeroSchedulingContentView from '../views/rulesets/hero-scheduling-content.view';
import AssignContentView from '../views/rulesets/assign-content.view';

const AppRouter = ({ isLoggedIn, accessRole }) => {
  const { hasAccess, hasOperationAccess, hasAdminAccess } = useAccess();
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.DASHBOARD,
      element: <DashboardView adminUserRole={accessRole} />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REALTIME_DASHBOARD,
      element: <RealtimeDashboardView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_LIVE_USERS,
      element: <ReportsLiveUsersView />,
      exact: true,
    });
    if (hasAdminAccess()) {
      routeMappings.push({
        path: ROUTE_PATH.SMTP_SETTINGS,
        element: <SMTPSettingsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.COUPONS_CONFIG,
        element: <CouponCodesView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.IMPORT,
        element: <ImportView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.EXPORT,
        element: <ExportView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.WORKSPACE_SETTINGS,
        element: <WorkspaceView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_OWNERS,
        element: <ContentOwnersView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.PORTAL_USERS,
        element: <PortalUsersView />,
        exact: true,
      });

      if (accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN) {
        routeMappings.push({
          path: ROUTE_PATH.ADMIN_USERS,
          element: <AdminUsersView />,
          exact: true,
        });
        routeMappings.push({
          path: ROUTE_PATH.AUTOLOGIN,
          element: <AutoLogin />,
          exact: true,
        });
        routeMappings.push({
          path: ROUTE_PATH.CHANNEL_DEFAULT_CONTENT,
          element: <AppDefaultContentView />,
          exact: true,
        });
      }
    }

    if (hasAccess('analytics')) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW,
        element: <ReportsOverviewView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_MIGRATION_STATUS,
        element: <ReportsMigrationStatus />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_MIGRATION_USERS,
        element: <ReportsMigrationUsers />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_ACTIVE_SUBSCRIBERS,
        element: <ReportsActiveSubscribers />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_TRIAL_SUBSCRIBERS,
        element: <ReportsTrialSubscribers />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_CHANNEL_ALL_USERS,
        element: <ReportsUsersCountOverview />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNELS,
        element: <ContentChannelsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_PLAYLISTS,
        element: <ContentPlaylistsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_APP_SESSIONS,
        element: <ReportsAppSessionsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.USER_PROFILE,
      element: <ProfileView />,
      exact: true,
    });

    if (hasAccess('channel_management')) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_ALL_CHANNELS,
        element: <ContentAppsView />,
        exact: true,
      });

      if (hasOperationAccess('channel_management', 'edit'))
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_CHANNEL_EDIT,
          element: <AppEditView />,
          exact: true,
        });

      routeMappings.push({
        path: ROUTE_PATH.CHANNEL_CONTENT,
        element: <AppContentView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNEL_GENRES,
        element: <ChannelGenresView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CHANNEL_VIEW_DETAILS,
        element: <AppViewDetails />,
        exact: true,
      });
    }

    if (hasAccess('video_library')) {
      routeMappings.push({
        path: ROUTE_PATH.VIDEOS,
        element: <VideosView />,
        exact: true,
      });

      if (hasOperationAccess('video_library', 'edit')) {
        routeMappings.push({
          path: ROUTE_PATH.VIDEO_EDIT,
          element: <VideoEditView />,
          exact: true,
        });
      }

      routeMappings.push({
        path: ROUTE_PATH.VIDEO_VIEW_DETAILS,
        element: <VideoViewDetails />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.VIDEO_PREVIEW,
        element: <VideoPreviewView />,
        exact: true,
      });
    }
    if (hasAccess('channel_assignment')) {
      routeMappings.push({
        path: ROUTE_PATH.MANAGE_SCHEDULE,
        element: <ChannelAssignmentView />,
        exact: true,
      });
    }
    if (hasAccess('advertising_tag_library')) {
      routeMappings.push({
        path: ROUTE_PATH.ADVERTISERS_TAG_LIBRARY,
        element: <TagsLibraryView />,
        exact: true,
      });
    }
    if (hasOperationAccess('advertising_assign_tags', 'manage')) {
      routeMappings.push({
        path: ROUTE_PATH.ADVERTISERS_TAG_ASSIGN,
        element: <AssignTagsView />,
        exact: true,
      });
    }
    if (hasOperationAccess('advertising_assign_tags', 'view')) {
      routeMappings.push({
        path: ROUTE_PATH.ADVERTISERS_TAG_DEPLOYED,
        element: <DeployedTagsView />,
        exact: true,
      });
    }
  }

  if (hasAccess('genres')) {
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_GENRES,
      element: <GenresView />,
      exact: true,
    });
  }
  if (hasAccess('rulesets')) {
    if (accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.MANAGE_RULES,
        element: <ManageRules />,
        exact: true,
      });
    }
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_ASSIGNMENT,
      element: <AssignContentView />,
      exact: true,
    });
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_SCHEDULING,
      element: <SchedulingContentView />,
      exact: true,
    });
    routeMappings.push({
      path: ROUTE_PATH.HERO_CONTENT_SCHEDULING,
      element: <HeroSchedulingContentView />,
      exact: true,
    });
  }
  if (hasAccess('collections')) {
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_COLLECTIONS,
      element: <CollectionsView />,
      exact: true,
    });
  }
  if (hasAccess('playlists')) {
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_ALL_PLAYLISTS,
      element: <PlaylistsView />,
      exact: true,
    });
  }

  if (hasAccess('series')) {
    routeMappings.push({
      path: ROUTE_PATH.SERIES,
      element: <SeriesView />,
      exact: true,
    });
  }

  if (hasAccess('manage_descriptions')) {
    routeMappings.push({
      path: ROUTE_PATH.CONTENT_MANAGE_DESCRIPTIONS,
      element: <ManageDescriptionsView />,
      exact: true,
    });
  }

  if (hasAccess('license_management')) {
    routeMappings.push({
      path: ROUTE_PATH.LICENSE_MANAGEMENT,
      element: <LicenseManagementView />,
      exact: true,
    });
  }

  if (hasAccess('manage_users')) {
    routeMappings.push({
      path: ROUTE_PATH.SUBSCRIBERS,
      element: <SubscribersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.BILLING_SUBSCRIPTIONS,
      element: <BillingSubscriptionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.BILLING_PAYMENTS,
      element: <BillingPaymentsView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
