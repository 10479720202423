import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getRulesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/rules/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchRulesList = async () => {
  const options = prepareRequestOptions(
    `/admin/rules/search-list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const manageRulesData = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/rules/manage`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewRuleById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/rules/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteRuleDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/rules/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
