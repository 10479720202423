import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Grid,
  Typography,
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Checkbox,
  Card,
  AccordionActions,
  Chip,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  bulkUpdateDataByIds,
  getListData,
} from '../../services/videos.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import ListVideosData from '../table-elements/list-videos-data.component';
import Workspace from '../form-elements/workspace.component';
import FilterVideos from '../filter-videos/filter-video.component';
import prepareDynamicColumns, {
  getEditableCellDefaultValue,
} from '../../utils/dynamic-columns.util';
import {
  DEFAULT_LIST_COLUMNS,
  DYNAMIC_COLUMNS_CONFIG,
} from '../../config/module-configs/content-videos.config';
import EditableCellValue from '../table-elements/editable-cell-value.component';
import ConfirmPopup from '../common/confirm-popup.component';
import useAccess from '../../hooks/access.hook';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const EditVideosList = ({
  title,
  onCancel,
  viewModeWorkspace,
  videoListFilters,
}) => {
  const { hasOperationAccess } = useAccess();
  const { control, handleSubmit, getValues } = useForm();

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    columns: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: videoListFilters,
    filtersCounter: 0,
  });
  const [selectedWorkspace, setSelectedWorkspace] = useState(viewModeWorkspace);
  const [expandBulkEdit, setExpandBulkEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [openConfirmDialog, setOpenConfirmDailog] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDeleteFilters = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }

    const updatedFilters = { ...options.appliedFilters };
    delete updatedFilters[field];

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSave = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    if (!selectedColumns.length || !selectedRows.length) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'info',
          message: !selectedColumns.length
            ? 'You must select atleast one field to apply bulk update.'
            : 'You must select atleast one video for bulk update.',
        });
      }, 50);
      setFormSubmitted(false);
      return;
    }

    setOpenConfirmDailog(true);
  };

  const onFormSubmit = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const data = getValues();
    const payload = {
      videoIds: selectedRows,
      columns: selectedColumns.map((item) => {
        let columnValue = null;
        if (
          data[`cellvalue_${item}`] &&
          typeof data[`cellvalue_${item}`] === 'object' &&
          !Array.isArray(data[`cellvalue_${item}`])
        ) {
          columnValue = dayjs(data[`cellvalue_${item}`]).format('YYYY-MM-DD');
        } else if (
          data[`cellvalue_${item}`] &&
          typeof data[`cellvalue_${item}`] === 'boolean'
        ) {
          columnValue = Number(data[`cellvalue_${item}`]);
        } else {
          columnValue = data[`cellvalue_${item}`];
        }

        return {
          [item]: columnValue,
        };
      }),
    };
    bulkUpdateDataByIds(payload)
      .then((res) => {
        if (res.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Bulk edit completed successfully.',
          });
          setSelectedRows([]);
          setSelectedColumns([]);
          handleRefreshData();
        }
        setFormSubmitted(false);
        setOpenConfirmDailog(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
        setOpenConfirmDailog(false);
      });
  };

  useEffect(() => {
    setOptions({ ...options, loading: true });

    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    if (selectedWorkspace?.id) {
      params.push(`workspaceId=${selectedWorkspace.id}`);
    }

    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }

    const uri = '';
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(uri + paramsQuery, { filter: filters })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          columns: selectedWorkspace?.id
            ? prepareDynamicColumns(res?.data?.columnsFields)
            : DEFAULT_LIST_COLUMNS,
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter, selectedWorkspace?.id]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullScreen
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="calc(100% - 300px)"
      >
        <DialogTitle id="add-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          size="small"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ic:outline-close" />
        </IconButton>
      </Box>

      <DialogContent dividers id="add-dialog-description">
        <Box width="calc(100% - 300px)">
          <Box flex={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ py: 2 }}
            >
              <Workspace
                id="workspace"
                name="workspace"
                label="Workspace"
                defaultValue={selectedWorkspace}
                onChange={(v) => {
                  setSelectedWorkspace(v);
                  setSelectedColumns([]);
                  setSelectedRows([]);
                }}
                sx={{ width: '30%' }}
              />
              <Button
                color="inherit"
                variant="contained"
                startIcon={<Iconify icon="ic:twotone-refresh" />}
                onClick={handleRefreshData}
                disabled={options.loading}
              >
                Refresh
              </Button>
            </Stack>

            {options.appliedFilters &&
              Object.values(options.appliedFilters).some(
                (filter) => filter.apply
              ) && (
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    display="inline"
                  >
                    Filters Applied:{' '}
                    <Chip
                      key="all"
                      label="Clear all"
                      onDelete={() => handleDeleteFilters('clear-all')}
                      sx={{ ml: 1, color: 'red' }}
                    />
                  </Typography>

                  {Object.entries(options.appliedFilters)
                    .filter(([, filter]) => filter.apply)
                    .map(([field, filter]) => (
                      <Chip
                        key={field}
                        label={filter.title || 'Active'}
                        onDelete={() => handleDeleteFilters(field)}
                        sx={{ ml: 1 }}
                      />
                    ))}
                </Box>
              )}

            <form
              id="bulk-edit-form"
              onSubmit={handleSubmit(onFormSubmit)}
              style={{ marginBottom: 16 }}
            >
              <Accordion
                key="bulk-edit-metadata"
                expanded={expandBulkEdit}
                onChange={() => setExpandBulkEdit(!expandBulkEdit)}
                variant="outlined"
              >
                <AccordionSummary
                  expandIcon={
                    <Iconify icon="ic:round-expand-more" width={25} />
                  }
                >
                  <Typography variant="subtitle1">
                    Bulk Edit Video Metadata
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        {!options.columns.filter((item) => item.editable)
                          .length && (
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant="body2">
                                No data found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}

                        {options.columns.filter((item) => item.editable)
                          .length > 0 && (
                          <TableRow>
                            {options.columns
                              .filter((item) => item.editable)
                              .map((c) => (
                                <TableCell
                                  key={`col-${c.id}`}
                                  align={c.align}
                                  width={c.width}
                                  sx={{
                                    cursor: c.sortable ? 'pointer' : 'inherit',
                                    padding: '16px',
                                    fontSize: '0.875rem !important',
                                  }}
                                >
                                  <Checkbox
                                    checked={selectedColumns.includes(c.id)}
                                    onChange={() => {
                                      setSelectedColumns((prevSelected) =>
                                        prevSelected.includes(c.id)
                                          ? prevSelected.filter(
                                              (colId) => colId !== c.id
                                            )
                                          : [...prevSelected, c.id]
                                      );
                                    }}
                                  />
                                  {c.label}
                                </TableCell>
                              ))}
                          </TableRow>
                        )}
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          {options.columns
                            .filter((item) => item.editable)
                            .map((c) => (
                              <TableCell
                                key={`data-col-${c.id}`}
                                align={c.align}
                                width={c.width}
                                sx={{
                                  padding: '16px',
                                  fontSize: '0.875rem !important',
                                }}
                                variant="string"
                              >
                                <Controller
                                  name={`cellvalue_${c.dataKey}`}
                                  control={control}
                                  defaultValue={getEditableCellDefaultValue(
                                    c.dataKey,
                                    DYNAMIC_COLUMNS_CONFIG[c.dataKey]
                                      .editCellType
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <EditableCellValue
                                      type={
                                        DYNAMIC_COLUMNS_CONFIG[c.dataKey]
                                          .editCellType
                                      }
                                      dataKey={c.dataKey}
                                      value={value}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
                <AccordionActions sx={{ justifyContent: 'flex-start', px: 2 }}>
                  {hasOperationAccess('video_library', 'edit') && (
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={() => handleSave()}
                      disabled={formSubmitted}
                    >
                      Apply Bulk
                    </Button>
                  )}
                </AccordionActions>
              </Accordion>
            </form>

            <Card>
              <ListVideosData
                columns={options.columns}
                rows={options.rows}
                page={options.page}
                rowsPerPage={options.rowsPerPage}
                totalRows={options.totalRows}
                loading={options.loading}
                actions={[]}
                error={options.error}
                sortBy={options.sortBy}
                sortOrder={options.sortOrder}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                onSortChange={handleSortingChange}
                fromDailog
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                allowInlineEdit
              />
            </Card>
          </Box>

          <Drawer
            anchor="right"
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: 300,
                overflow: 'auto',
              },
            }}
          >
            <FilterVideos
              values={options.appliedFilters}
              onSuccess={handleApplyFilters}
              onClear={handleClearFilters}
              from="edit-videos-list"
            />
          </Drawer>
        </Box>
      </DialogContent>

      <Box width="calc(100% - 300px)">
        <StyledDialogActions>
          <Grid>
            {hasOperationAccess('video_library', 'edit') && (
              <Button
                type="submit"
                form="inline-edit-form"
                color="primary"
                variant="contained"
                disabled={formSubmitted}
                sx={{ marginRight: 2 }}
              >
                Save
              </Button>
            )}
            <Button
              color="secondary"
              variant="contained"
              onClick={onCancel}
              disabled={formSubmitted}
            >
              Cancel
            </Button>
          </Grid>
        </StyledDialogActions>
      </Box>

      {openConfirmDialog && (
        <ConfirmPopup
          title="Bulk Update Confirmation"
          message="Are you sure you want to bulk update selected data?"
          onCancel={() => setOpenConfirmDailog(false)}
          onSuccess={onFormSubmit}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

EditVideosList.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  viewModeWorkspace: PropTypes.object.isRequired,
  videoListFilters: PropTypes.object.isRequired,
};

export default EditVideosList;
