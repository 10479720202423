// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CHANNELS: '/engagement/apps',
  CONTENT_VIDEOS: '/engagement/videos',
  CONTENT_PLAYLISTS: '/engagement/playlists',
  SUBSCRIBERS: '/users/all',
  ADMIN_USERS: '/settings/access',
  SMTP_SETTINGS: '/settings/email',
  COUPONS_CONFIG: '/settings/coupon-codes',
  BILLING_SUBSCRIPTIONS: '/users/subscribers',
  BILLING_PAYMENTS: '/users/payments',
  REPORTS_OVERVIEW: '/reports/overview',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/engagement/video-sessions',
  REPORTS_APP_SESSIONS: '/engagement/app-sessions',
  REPORTS_MIGRATION_STATUS: '/reports/migration',
  REPORTS_MIGRATION_USERS: '/reports/migration-users',
  REPORTS_ACTIVE_SUBSCRIBERS: '/reports/active-subscribers',
  REPORTS_TRIAL_SUBSCRIBERS: '/reports/trial-subscribers',
  REPORTS_CHANNEL_ALL_USERS: '/reports/users-count-overview',
  CONTENT_ALL_CHANNELS: '/content/channels',
  CONTENT_CHANNEL_EDIT: '/content/channels/:id/edit',
  CHANNEL_CONTENT: '/content/channels/:id',
  CHANNEL_DEFAULT_CONTENT: '/content/channel/default-content',
  MANAGE_SCHEDULE: '/content/manage-schedule',
  VIDEOS: '/content/videos',
  VIDEO_EDIT: '/content/videos/:id/edit',
  ADVERTISERS_TAG_LIBRARY: '/advertisers/tags',
  ADVERTISERS_TAG_ASSIGN: '/advertisers/tag-assign',
  ADVERTISERS_TAG_DEPLOYED: '/advertisers/deployed-tags',
  CONTENT_GENRES: '/content/genres',
  CONTENT_COLLECTIONS: '/content/collections',
  CONTENT_ALL_PLAYLISTS: '/content/playlists',
  MANAGE_RULES: '/content/manage-rules',
  SERIES: '/content/series',
  CONTENT_CHANNEL_GENRES: '/content/channel/genres',
  CONTENT_MANAGE_DESCRIPTIONS: '/content/manage-descriptions',
  LICENSE_MANAGEMENT: '/content/license',
  CONTENT_OWNERS: '/distributor',
  PORTAL_USERS: '/portal-users',
  AUTOLOGIN: '/auto-login',
  IMPORT: '/import',
  EXPORT: '/export',
  WORKSPACE_SETTINGS: '/settings/workspace',
  VIDEO_VIEW_DETAILS: '/content/videos/:id/view',
  CHANNEL_VIEW_DETAILS: '/content/channels/:id/view',
  USER_PROFILE: '/profile',
  VIDEO_PREVIEW: '/content/videos/:id/popup-view',
  CONTENT_SCHEDULING: '/content/scheduling',
  CONTENT_ASSIGNMENT: '/content/assignment',
  HERO_CONTENT_SCHEDULING: '/content/hero/scheduling',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;
