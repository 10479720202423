import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListContentPlaylists from '../../components/content/list-content-playlists.component';

const PlaylistsView = () => {
  const pageTitle = 'Organize Content / Playlists';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListContentPlaylists />
    </AppLayout>
  );
};

export default PlaylistsView;
