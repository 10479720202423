import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/playlists/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSubPlaylists = async (parentId) => {
  const options = prepareRequestOptions(
    `/admin/playlist/${parentId}/child-playlists`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/playlists/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getPlaylists = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/playlists/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChannelWisePlaylists = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/playlists/list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateChannelPlaylistsSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/channel/playlists/sequence/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateSubPlaylistsSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/playlists/sub-playlist/sequence/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const addVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/add-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const removeVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/remove-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateFilterDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/playlists/${id}/filters/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
