import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Admin users module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '15%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'username',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'type',
    label: 'Type',
    width: '10%',
    align: 'left',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.CONTENT_OWNER_ROLE,
  },
  {
    id: 'royality',
    label: 'Royality',
    width: '25%',
    align: 'left',
    dataKey: 'royality',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.CHANGE_PASSWORD.value,
    label: ACTIONS.CHANGE_PASSWORD.label,
    conditional: (val) => Number(val.isLoginEnable),
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
  {
    action: ACTIONS.MANAGE_VIDEOS.value,
    label: ACTIONS.MANAGE_VIDEOS.label,
  },
  {
    action: ACTIONS.LOGIN_AS_THIS_USER.value,
    label: ACTIONS.LOGIN_AS_THIS_USER.label,
    conditional: (val) => Number(val.isLoginEnable),
  },
  {
    action: ACTIONS.ENABLE_LOGIN.value,
    label: ACTIONS.ENABLE_LOGIN.label,
    conditional: (val) => !Number(val.isLoginEnable),
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'username',
    label: 'Username',
    dataKey: 'username',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.CONTENT_OWNER_ROLE,
  },
  {
    id: 'timezone',
    label: 'Time zone',
    dataKey: 'timezone',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'royality',
    label: 'Royality',
    dataKey: 'royality',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'bank',
    label: 'Bank',
    dataKey: 'bank',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'bankAcc',
    label: 'Bank Account',
    dataKey: 'bankAcc',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'address',
    label: 'Address',
    dataKey: 'address',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'country',
    label: 'Country',
    dataKey: 'country',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'info',
    label: 'Info',
    dataKey: 'info',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'phone',
    label: 'Phone',
    dataKey: 'phone',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'website',
    label: 'Website',
    dataKey: 'website',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'zipCode',
    label: 'Zip Code',
    dataKey: 'zipCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'canEditLicense',
    label: 'Can Edit License',
    dataKey: 'canEditLicense',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
