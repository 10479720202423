import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/series/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/series/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchableListData = async () => {
  const options = prepareRequestOptions(
    '/admin/series/search-list',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const addEpisodesById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/add-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const removeEpisodesById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/series/${id}/remove-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
