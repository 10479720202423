const validateFileType = (allowedExtensions, files) => {
  let errorMsg = '';

  for (let loop = 0; loop < files.length; loop += 1) {
    if (!allowedExtensions.exec(files[loop]?.name)) {
      errorMsg = 'Invalid file selected. Please select csv file only.';
    }
  }

  return errorMsg || null;
};

const validateCsvFile = (files = null) => {
  let errorMsg = '';

  if (!files || files?.length === 0) {
    errorMsg = 'Please select csv file to upload.';
    return errorMsg;
  }
  if (files?.length > 1) {
    errorMsg = 'Single csv file selection allowed.';
    return errorMsg;
  }

  const allowedExtensions = /(\.csv)$/i;
  errorMsg = validateFileType(allowedExtensions, files);

  return errorMsg || null;
};

export default validateCsvFile;
