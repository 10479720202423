const RULE_ASSIGNMENT_FIELDS = {
  title_name_only: { label: 'Title', type: 'text' },
  rel_genre_id: { label: 'Genres', type: 'select' },
  video_type: { label: 'Video Type', type: 'select' },
  aka: { label: 'Also Known As', type: 'text' },
  description: { label: 'Description', type: 'text' },
  description_status: { label: 'Description Status', type: 'text' },
  duration_minutes: { label: 'Duration', type: 'number' },
  year: { label: 'Year', type: 'number' },
  decade: { label: 'Decade', type: 'select' },
  country: { label: 'Country', type: 'select' },
  region: { label: 'Region', type: 'select' },
  directors: { label: 'Directors', type: 'text' },
  writers: { label: 'Writers', type: 'text' },
  cast: { label: 'Cast', type: 'text' },
  imdb_id: { label: 'IMDB ID', type: 'text' },
  UUID: { label: 'UUID', type: 'text' },
  date_creation: { label: 'Date Added', type: 'date' },
  series_id: { label: 'Series', type: 'select' },
  season: { label: 'Season Number', type: 'text' },
  episode_num: { label: 'Episode Number', type: 'text' },
  distributor_id: { label: 'Distributor', type: 'select' },
  plot_keywords: { label: 'Plot Keywords', type: 'text' },
  trashy: { label: 'Trashy', type: 'select' },
  good_bad: { label: 'Good Bad', type: 'select' },
  pnm_exclusives: { label: 'Exclusives', type: 'select' },
  rating_override: { label: 'Rating Override', type: 'number' },
  trashy_rating: { label: 'Trashy Rating', type: 'number' },
  five_star_rating: { label: '5 Star rating', type: 'number' },
  imdb_ranking: { label: 'IMDB Ranking', type: 'select' },
  mpaa_rating: { label: 'MPAA Rating', type: 'select' },
  offline: { label: 'Offline', type: 'select' },
  offline_reason: { label: 'Offline Reason', type: 'text' },
  restricted_content: { label: 'Restricted Content', type: 'select' },
  restricted_content_reason: {
    label: 'Restricted Content Reason',
    type: 'select',
  },
  exclude_from_db: { label: 'Exclude From DB', type: 'select' },
  date_license_expiration: { label: 'Date License Expiration', type: 'date' },
  ad_timing_status: { label: 'Ad timing status', type: 'select' },
  genre_status: { label: 'Genre Status', type: 'select' },
  duplicate_imdb: { label: 'Duplicate IMDB Check', type: 'select' },
  svod: { label: 'SVOD Date', type: 'date' },
  avod: { label: 'AVOD Date', type: 'date' },
  avod_validated: { label: 'AVOD License', type: 'select' },
  avod_watched: { label: 'AVOD Minutes Watched', type: 'number' },
  geofencing_allow: { label: 'GeoFencing Allow', type: 'text' },
  geofencing_deny: { label: 'GeoFencing Deny', type: 'text' },
  resolution: { label: 'Resolution', type: 'select' },
  trailer_url: { label: 'Trailer URL', type: 'text' },
  video_url: { label: 'Video URL', type: 'text' },
  poster_16x9_url: { label: 'Poster 16x9', type: 'text' },
  subtitle_en_url: { label: 'Subtitle-EN URL', type: 'text' },
  mm_notes: { label: 'MM Notes', type: 'text' },
  ms_notes: { label: 'MS Notes', type: 'text' },
  quality_video: { label: 'Video URL check', type: 'select' },
  quality_poster_16x9: { label: 'Poster 16x9 URL check', type: 'select' },
  quality_trailer: { label: 'Trailer URL Check', type: 'select' },
  quality_poster_3x4: { label: 'Poster 3x4 URL check', type: 'select' },
  quality_subtitle: { label: 'Subtitle URL check', type: 'select' },
  quality_source_url: { label: 'Source URL Check', type: 'select' },
  // series_title: 'Series',
  // collections_name: 'Collections',
  // restricted_content_source: 'Restricted Content Source',
  // video_status: 'Video Status',
  // video_status_reason: 'Video Status Reason',
  // video_status_reason_details: 'Video Status Details',
  // trailer_status: 'Trailer Status',
  // trailer_status_reason: 'Trailer Reason',
  // tvod: 'TVOD Street Date',
  // tvod_enabled: 'TVOD Enabled',
  // tvod_iap: 'TVOD Price',
  // poster_16x9_status: 'Poster 16x9 Status',
  // avod_watched_7: 'AVOD Minutes Watched (week)',
  // svod_watched: 'SVOD Minutes Watched',
  // ad_timing: 'Ad Timing',
  // ad_timing_detected: 'Auto-detected ad times',
  // distributor_sku: 'Distributor SKU',
  // distributor__Type: 'Distributor Type',
  // distributor__name: 'Distributor Name',
  // distributor__royality: 'Royalty',
  // public_domain: 'Public Domain',
  // custom_royality: 'Custom Royality',
  // schedule_priority: 'Priority Schedule',
  // imdb_duration_minutes: 'IMDB Duration',
  // imdb_language: 'IMDB Language',
  // imdb_year: 'IMDB Year',
  // imdb_plot_keywords: 'IMDB Keywords',
  // imdb_directors: 'IMDB Directors',
  // imdb_writers: 'IMDB Writers',
  // imdb_cast: 'IMDB Cast',
  // imdb_country: 'IMDB Country',
  // imdb_description: 'IMDB Description',
};

export default RULE_ASSIGNMENT_FIELDS;
