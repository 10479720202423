import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSeries from '../../components/content/list-series.component';

const SeriesView = () => {
  const pageTitle = 'Manage Content / Series Library';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListSeries />
    </AppLayout>
  );
};

export default SeriesView;
