import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
  Chip,
  IconButton,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import Distributors from '../form-elements/distributors.component';
import Datepicker from '../form-elements/datepicker.component';
import TimeInput from '../form-elements/time-input.component';
import Iconify from '../common/iconify.component';
import GenerateAdTimingsModal from './generate-ad-timings.modal.component';

const VideoDistributorMonetization = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const durationInMinutes = Number(videoDetail.duration);
  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      avod: videoDetail.avod || '',
      svod: videoDetail.svod || '',
      tvod: videoDetail.tvod || '',
      distributor:
        videoDetail.distributorId && videoDetail.distributorName
          ? { id: videoDetail.distributorId, name: videoDetail.distributorName }
          : '' || '',
      distributorSku: videoDetail.distributorSku || '',
      distributorSku2: videoDetail.distributorSku2 || '',
      distributorSkuSeries: videoDetail.distributorSkuSeries || '',
      adTiming: videoDetail.adTiming || '',
      adTimingDetected: videoDetail.adTimingDetected || '',
    },
  });

  const [inputValue, setInputValue] = useState(''); // Temporary input storage
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const adTimingArray = data.adTiming ? data.adTiming.split(',') : [];
    const sortedAdTiming = adTimingArray
      .map((time) => time.trim())
      .filter((time) => time)
      .sort((a, b) => {
        const timeA = dayjs(a, 'HH:mm:ss');
        const timeB = dayjs(b, 'HH:mm:ss');
        return timeA.isBefore(timeB) ? -1 : 1;
      })
      .join(',');

    const payload = {
      ...data,
      distributor: data?.distributor?.id || null,
      avod: data.avod ? dayjs(data.avod).format('YYYY-MM-DD') : '',
      svod: data.svod ? dayjs(data.svod).format('YYYY-MM-DD') : '',
      tvod: data.tvod ? dayjs(data.tvod).format('YYYY-MM-DD') : '',
      adTiming: sortedAdTiming,
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Distributor & Monetization data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  const adTimings = watch('adTiming');

  const handleAddTiming = () => {
    if (inputValue.trim()) {
      const existingValues = adTimings ? adTimings.split(',') : [];
      const updatedValues = [...existingValues, inputValue].join(',');
      setValue('adTiming', updatedValues);
      setInputValue('');
    }
  };

  const handleRemoveTiming = (index) => {
    const valuesArray = adTimings.split(',').filter(Boolean);
    valuesArray.splice(index, 1);
    setValue('adTiming', valuesArray.join(','));
  };

  const handleSaveGeneratedTimings = (generatedTimings) => {
    setValue('adTiming', generatedTimings.join(','));
  };

  return (
    <Box component={Paper} padding={2}>
      <form
        id="distributor-monetization-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="avod"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  id="avod"
                  name="avod"
                  label="AVOD"
                  defaultValue={value}
                  clearable
                  onChange={onChange}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="svod"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  id="svod"
                  name="svod"
                  label="SVOD"
                  defaultValue={value}
                  clearable
                  onChange={onChange}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="tvod"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  id="tvod"
                  name="tvod"
                  label="TVOD"
                  defaultValue={value}
                  clearable
                  onChange={onChange}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="distributor"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Distributors
                  id="distributor"
                  name="distributor"
                  label="Distributor"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.distributor?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="distributorSku"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Distributor SKU"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.distributorSku}
                  helperText={errors?.distributorSku?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="distributorSku2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Distributor SKU2"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.distributorSku2}
                  helperText={errors?.distributorSku2?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="distributorSkuSeries"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Distributor SKU Series"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.distributorSkuSeries}
                  helperText={errors?.distributorSkuSeries?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />
          <Grid item xs={12} sm={6} md={6} container alignItems="center">
            <Grid item xs={11.5}>
              <TimeInput value={inputValue} onChange={setInputValue} />
            </Grid>
            <Grid item xs={0.5}>
              <IconButton onClick={() => handleAddTiming()}>
                <Iconify icon="lets-icons:add-duotone" width={30} />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              {adTimings &&
                adTimings
                  .split(',')
                  .map((time, index) => (
                    <Chip
                      key={index}
                      label={time}
                      onDelete={() => handleRemoveTiming(index)}
                      style={{ margin: '4px' }}
                      variant="outlined"
                    />
                  ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() => setIsModalOpen(true)}
              color="primary"
              variant="contained"
            >
              Generate Ad Timings
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="adTimingDetected"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Ad Timing Detected"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.adTimingDetected}
                  helperText={errors?.adTimingDetected?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <GenerateAdTimingsModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        durationInMinutes={durationInMinutes}
        onSave={handleSaveGeneratedTimings}
      />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="distributor-monetization-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Distributor & Monetization
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoDistributorMonetization.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoDistributorMonetization;
