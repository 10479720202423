import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListUsers from '../../components/admin-users/list-users.component';

const PortalUsersView = () => {
  const pageTitle = 'Manage Distributors / Portal Users';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListUsers from="contentOwner" />
    </AppLayout>
  );
};

export default PortalUsersView;
