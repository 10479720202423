import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import QualityMaxResolution from '../form-elements/qaulity-max-resolution.component';

const VideoSystemAdmin = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      trailerUrl: videoDetail.trailerUrl || '',
      videoUrl: videoDetail.videoUrl || '',
      poster16x9Url: videoDetail.poster16x9Url || '',
      poster3x4Url: videoDetail.poster3x4Url || '',
      subtitleEnUrl: videoDetail.subtitleEnUrl || '',
      msNotes: videoDetail.msNotes || '',
      mmNotes: videoDetail.mmNotes || '',
      mmNotes2Trailer: videoDetail.mmNotes2Trailer || '',
      qualityMaxResolution: videoDetail.qualityMaxResolution || 'NC',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'System & Admin data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="system-admin-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="trailerUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Trailer URL"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.trailerUrl}
                  helperText={errors?.trailerUrl?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Video URL"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.videoUrl}
                  helperText={errors?.videoUrl?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="poster16x9Url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Poster 16x9 URL"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.poster16x9Url}
                  helperText={errors?.poster16x9Url?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="poster3x4Url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Poster 3x4 URL"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.poster3x4Url}
                  helperText={errors?.poster3x4Url?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="subtitleEnUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Subtitle EN URL"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.subtitleEnUrl}
                  helperText={errors?.subtitleEnUrl?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="msNotes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Ms Notes"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.msNotes}
                  helperText={errors?.msNotes?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="mmNotes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Mm Notes"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.mmNotes}
                  helperText={errors?.mmNotes?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="mmNotes2Trailer"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Mm Notes2 Trailer"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.mmNotes2Trailer}
                  helperText={errors?.mmNotes2Trailer?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityMaxResolution"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityMaxResolution
                  id="quality_max_resolution"
                  name="quality_max_resolution"
                  label="Quality Max Resolution"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityMaxResolution?.message || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="system-admin-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save System & Admin
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoSystemAdmin.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoSystemAdmin;
