import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Chip,
  Box,
  Grid,
  IconButton,
  Slide,
} from '@mui/material';
import StyledDialogActions from '../../theme/dialogActionStyles';
import Iconify from '../common/iconify.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const GenerateAdTimingsModal = ({
  open,
  onClose,
  durationInMinutes,
  onSave,
}) => {
  const [intervalMinutes, setIntervalMinutes] = useState('');
  const [generatedTimings, setGeneratedTimings] = useState([]);

  const handleGenerateTimings = () => {
    if (!intervalMinutes || intervalMinutes.isNaN || intervalMinutes <= 0) {
      return;
    }

    const interval = parseInt(intervalMinutes, 10);
    const timings = [];
    let currentTime = 0;

    while (currentTime <= durationInMinutes) {
      const hours = Math.floor(currentTime / 60);
      const minutes = currentTime % 60;
      const formattedTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}:00`;
      timings.push(formattedTime);
      currentTime += interval;
    }

    setGeneratedTimings(timings);
  };

  const handleRemoveTiming = (index) => {
    const valuesArray = generatedTimings.filter((_, i) => i !== index);
    setGeneratedTimings(valuesArray);
  };

  const handleSave = () => {
    onSave(generatedTimings);
    onClose();
  };

  const handleClose = () => {
    setGeneratedTimings([]);
    setIntervalMinutes('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Generate Ad Timings</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>
      <DialogContent dividers>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={9} md={9}>
            <TextField
              label="Interval (minutes)"
              value={intervalMinutes}
              onChange={(e) => setIntervalMinutes(e.target.value)}
              placeholder="Enter interval in minutes"
              type="number"
              variant="filled"
              fullWidth
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={handleGenerateTimings}
              color="secondary"
              variant="contained"
              maxWidth="sm"
              sx={{ mb: 2 }}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
        <Box>
          {generatedTimings.map((time, index) => (
            <Chip
              key={index}
              label={time}
              onDelete={() => handleRemoveTiming(index)}
              style={{ margin: '4px' }}
              variant="outlined"
            />
          ))}
        </Box>
      </DialogContent>
      <StyledDialogActions>
        <Grid>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={generatedTimings.length === 0}
            sx={{ marginRight: 2 }}
          >
            Use generated intervals
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

GenerateAdTimingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  durationInMinutes: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default GenerateAdTimingsModal;
