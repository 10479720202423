import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useContext,
} from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Chip,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import { LISTING_COLUMNS } from '../../config/module-configs/manage-videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getListData } from '../../services/videos.service';
import { FiltersContext } from '../../context/filter.context';
import { updateFilterDataById } from '../../services/playlists.service';
import SnackbarInfo from '../common/snackbar-info.component';
import FilterPlaylistVideos from '../filter-videos/filter-playlist-video.component';
import { formatTimestamp } from '../../utils/datetime.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const PlaylistVideoAssignment = ({
  dataId,
  title,
  onCancel,
  filters,
  onSuccess,
  lastRefreshedAt,
}) => {
  const columns = LISTING_COLUMNS;
  const defaultValueRef = useRef([]);
  const { handleToggleFilters } = useContext(FiltersContext);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: filters,
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });

    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    updateFilterDataById(dataId, {
      filters: { ...selectedFilters },
    })
      .then((res) => {
        if (res.data.success) {
          onSuccess({ ...selectedFilters });
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Playlist filters saved successfully',
          });
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message:
            err.response?.data?.message ||
            'Something went wrong, please try again.',
        });
        setFormSubmitted(false);
      });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }

    const updatedFilters = { ...options.appliedFilters };
    delete updatedFilters[field];

    updatedFilters[field] = {
      ...updatedFilters[field],
      value: Array.isArray(updatedFilters[field]?.value) ? [] : null || '',
    };

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    setSelectAllPages(false);
    setSelectedRows([]);
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const appliedFilters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));

    if (appliedFilters.search) {
      params.push(`q=${encodeURIComponent(appliedFilters.search)}`);
    }

    const uri = '';
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(uri + paramsQuery, {
      videoIds: defaultValueRef.current,
      // from,
      // action: activeAction,
      filter: appliedFilters,
    })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullScreen
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="calc(100% - 300px)"
      >
        <DialogTitle id="add-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          size="small"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ic:outline-close" />
        </IconButton>
      </Box>
      <DialogContent dividers id="add-dialog-description">
        <Box
          sx={{
            transition: 'margin 0.3s ease, width 0.3s ease',
            width: '100%',
          }}
        >
          <Box display="flex" width="calc(100% - 300px)">
            <Box flex={1}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ px: 0, py: 2 }}
              >
                <Box display="flex" gap={2} alignItems="center">
                  {!!lastRefreshedAt && (
                    <Typography variant="body2" color="secondary">
                      Last Synced At:{' '}
                      {formatTimestamp(lastRefreshedAt || null, 'lll')}
                    </Typography>
                  )}
                  <Button
                    color="inherit"
                    variant="contained"
                    startIcon={<Iconify icon="ic:twotone-refresh" />}
                    onClick={handleRefreshData}
                    disabled={options.loading}
                  >
                    Refresh
                  </Button>
                </Box>
              </Stack>

              {options.appliedFilters &&
                Object.values(options.appliedFilters).some(
                  (filter) => filter.apply
                ) && (
                  <Box mb={2}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      display="inline"
                    >
                      Filters Applied:{' '}
                      <Chip
                        key="all"
                        label="Clear all"
                        onDelete={() => handleDeleteFilter('clear-all')}
                        sx={{ ml: 1, color: 'red' }}
                      />
                    </Typography>
                    {Object.entries(options.appliedFilters)
                      .filter(([, filter]) => filter.apply)
                      .map(([field, filter]) => (
                        <Chip
                          key={field}
                          label={filter.title || 'Active'}
                          onDelete={() => handleDeleteFilter(field)}
                          sx={{ ml: 1 }}
                        />
                      ))}
                  </Box>
                )}

              <Card>
                <ListData
                  columns={columns}
                  rows={options.rows}
                  page={options.page}
                  rowsPerPage={options.rowsPerPage}
                  totalRows={options.totalRows}
                  loading={options.loading}
                  actions={[]}
                  error={options.error}
                  sortBy={options.sortBy}
                  sortOrder={options.sortOrder}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onSortChange={handleSortingChange}
                  onAction={handleActionSelect}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAllPages={selectAllPages}
                />
              </Card>
            </Box>

            <Drawer
              anchor="right"
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: 300,
                  overflow: 'auto',
                },
              }}
            >
              <FilterPlaylistVideos
                values={options.appliedFilters}
                onCancel={handleToggleFilters}
                onSuccess={handleApplyFilters}
                isFormSubmitted={formSubmitted}
                from="video-assignment"
              />
            </Drawer>
          </Box>
        </Box>
      </DialogContent>
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

PlaylistVideoAssignment.propTypes = {
  dataId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  onSuccess: PropTypes.func.isRequired,
  lastRefreshedAt: PropTypes.number,
};

PlaylistVideoAssignment.defaultProps = {
  filters: [],
  lastRefreshedAt: 0,
};
export default PlaylistVideoAssignment;
