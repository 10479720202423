import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';

const ImportExportDataList = ({
  id,
  name,
  label,
  defaultValue,
  onChange,
  validOptions,
  disabled,
  sx,
}) => (
  <FormControl fullWidth variant="standard">
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      disabled={disabled}
      sx={sx}
    >
      {!validOptions.length ? (
        <MenuItem
          key={`${id}-no-data`}
          value=""
          disabled
          sx={{ display: 'flex', justifyContent: 'center', paddingY: 3 }}
        >
          No Data Available
        </MenuItem>
      ) : (
        validOptions.map((k) => (
          <MenuItem key={`${id}-v-${k}`} value={k}>
            {k.downloadFileName}
          </MenuItem>
        ))
      )}
    </Select>
  </FormControl>
);

ImportExportDataList.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.shape(),
};
ImportExportDataList.defaultProps = {
  disabled: false,
  sx: {},
};

export default ImportExportDataList;
