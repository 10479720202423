import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/playlists.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import PlaylistLevels from '../form-elements/playlist-level.component';
import Playlists from '../form-elements/playlists.component';
import VideoOrder from '../form-elements/video-order.component';
import { VIDEO_ORDER_FIELDS } from '../../config/const.config';
import Channels from '../form-elements/channels.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditPlaylist = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      channels: [],
      description: null,
      playlistLevel: 1,
      parentPlaylist: { id: 0, name: 'No Parent' },
      image: null,
      globalPlaylist: false,
      playlistEnable: false,
      checkChannelVideoAssignment: true,
      videoOrder: 'title_name_only',
      videoSortOrder: 'asc',
      status: 1,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'channels',
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleAddChannel = () => {
    append();
  };

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New Playlist created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Playlist details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      name: data.name,
      channels: data.channels.map((channel) => ({
        id: channel.data.id,
        title: channel.data.title,
        code: channel.data.code,
        thumbnail: channel.thumbnail,
      })),
      description: data.description,
      playlistLevel: data.playlistLevel,
      parentPlaylist:
        data.playlistLevel === 1 ? data.parentPlaylist?.id || null : null,
      image:
        data.playlistLevel === 1 && data.parentPlaylist?.id ? data.image : null,
      globalPlaylist: data.globalPlaylist,
      checkChannelVideoAssignment: data.checkChannelVideoAssignment,
      playlistEnable: data.playlistEnable,
      videoOrder: data.videoOrder,
      videoSortOrder: data.videoSortOrder,
      status: data.status,
    };

    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        const channels = res.data.channels.map((channel) => ({
          data: {
            id: channel.id || null,
            title: channel.name || '',
            code: channel.code || '',
          },
          thumbnail: channel.thumbnail || null,
        }));

        setValue('name', res.data?.name || '');
        setValue('channels', channels);
        setValue('description', res.data?.description || null);
        setValue('playlistLevel', res.data?.level || null);
        setValue(
          'parentPlaylist',
          res.data?.parentId
            ? { id: res.data.parentId, name: res.data.parentPlaylistName }
            : { id: 0, name: 'No Parent' } || { id: 0, name: 'No Parent' }
        );
        setValue('image', res.data?.image || null);
        setValue('globalPlaylist', !!res.data?.globalPlaylist || false);
        setValue(
          'checkChannelVideoAssignment',
          !!res.data?.checkChannelVideoAssignment || false
        );
        setValue('playlistEnable', !!res.data?.live || false);
        setValue(
          'videoOrder',
          res.data?.videoSort?.startsWith('-')
            ? res.data?.videoSort?.substring(1)
            : res.data?.videoSort || 'title_name_only'
        );
        setValue(
          'videoSortOrder',
          res.data?.videoSort.startsWith('-') ? 'desc' : 'asc'
        );

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(128),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description "
                      type="text"
                      multiline
                      minRows={5}
                      maxRows={5}
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="playlistLevel"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <PlaylistLevels
                      id="playlist_level"
                      name="playlist_level"
                      label="Playlist level"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 2]}
                      sx={{ width: '100%' }}
                      error={errors?.playlistLevel?.message || ''}
                    />
                  )}
                />
              </Grid>
              {watch('playlistLevel') === 1 && (
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="parentPlaylist"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Playlists
                        id="parent_playlist"
                        name="parent_playlist"
                        label="Select parent playlist "
                        defaultValue={value}
                        multiple={false}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        parentPlaylist
                      />
                    )}
                  />
                </Grid>
              )}
              {watch('playlistLevel') === 1 &&
                !!watch('parentPlaylist')?.id && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="image"
                      control={control}
                      rules={{
                        ...validationRules.OPTIONAL_URL,
                        ...maxLengthValidation(128),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Playlist Image"
                          type="text"
                          error={!!errors.image}
                          helperText={errors?.image?.message || null}
                          fullWidth
                          variant="filled"
                        />
                      )}
                    />
                  </Grid>
                )}
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="globalPlaylist"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={onChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label="Global Playlist"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="playlistEnable"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={onChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label="Playlist Enabled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="checkChannelVideoAssignment"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={onChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color="primary"
                        />
                      }
                      label="Check Channel Video Assignment"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="videoOrder"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <VideoOrder
                      id="video_order"
                      name="video_order"
                      label="Video Order"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={Object.keys(VIDEO_ORDER_FIELDS)}
                      sx={{ width: '100%' }}
                      error={errors?.videoOrder?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="videoSortOrder"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <FormControl component="fieldset">
                      <p
                        style={{
                          marginBottom: '4px',
                          marginTop: 0,
                          fontWeight: 'bold',
                        }}
                      >
                        Select video sorting order
                      </p>
                      <RadioGroup
                        {...field}
                        style={{ flexDirection: 'row' }}
                        aria-label="type"
                        name="type"
                      >
                        <FormControlLabel
                          value="asc"
                          control={<Radio />}
                          label="Ascending"
                        />
                        <FormControlLabel
                          value="desc"
                          control={<Radio />}
                          label="Descending"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              {fields.map((field, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  key={field.id}
                >
                  <Grid item xs={5}>
                    <Controller
                      name={`channels.${index}.data`}
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Channels
                          id={`playlist_channels_${index}`}
                          name={`playlist_channels_${index}`}
                          label="Select Channel *"
                          defaultValue={value}
                          multiple={false}
                          onChange={(channel) => {
                            onChange(channel);
                          }}
                          sx={{ width: '100%' }}
                          error={errors?.channels?.[index]?.data?.message || ''}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Controller
                      name={`channels.${index}.thumbnail`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Poster URL"
                          value={value || ''}
                          onChange={(e) => onChange(e.target.value)}
                          fullWidth
                          variant="filled"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => remove(index)}>
                      <Iconify icon="material-symbols:delete-forever-outline-rounded" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                justifyContent="center"
                alignContent="center"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAddChannel}
                >
                  {fields.length ? ' Add Channel' : 'Select Channel'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditPlaylist.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditPlaylist;
