import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListImport from '../../components/import-export/list-import.component';

const ImportView = () => {
  const pageTitle = 'Data Management / Import';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListImport />
    </AppLayout>
  );
};

export default ImportView;
