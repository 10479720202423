import React, { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Stack,
  TableBody,
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
} from '@mui/material';
import Loading from '../table-elements/loading.component';
import {
  getRulesetScheduleData,
  manageChannelScheduleContentInfo,
} from '../../services/ruleset.service';
import Sortable from '../table-elements/sortable.component';
import Error from '../table-elements/error.component';
import Channels from '../form-elements/channels.component';
import AddEditScheduleRulesetRules from './add-edit-schedule-ruleset-rules.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ListExecutedRulesetsVideos from './list-executed-rulesets-videos.component';

const ContentAssign = () => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [options, setOptions] = useState({
    columns: [],
    rows: [],
    info: {},
    error: false,
    loading: false,
    reloadCounter: 0,
  });
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedActionData, setSelectedActionData] = useState({
    id: null,
    data: {},
    open: false,
  });
  const [openVideoDialoag, setOpenVideoDialog] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handleRefreshData = () => {
    setSelectedActionData({ open: false, id: null, data: {} });
    setOpenVideoDialog(false);
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const manageScheduleInfoData = (payload, fromDefault = false) => {
    manageChannelScheduleContentInfo(selectedChannel?.id, payload)
      .then(() => {
        if (!fromDefault) {
          setSnackbarInfo({
            show: true,
            type: 'success',
            message: 'Data saved successfully',
          });
          setFormSubmitted(false);
        } else {
          handleRefreshData();
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          columns: [],
          rows: [],
          info: {},
          error: true,
        });
      });
  };

  useEffect(() => {
    if (selectedChannel?.id) {
      setOptions({
        ...options,
        loading: true,
      });
      getRulesetScheduleData(selectedChannel?.id, 'ASSIGNMENT')
        .then((res) => {
          if (!res.data?.info?.id) {
            const payload = {
              targetMovies: 0,
              targetEpisodes: 0,
              type: 'ASSIGNMENT',
            };
            manageScheduleInfoData(payload, true);
          } else {
            const columns = [
              {
                id: 'title',
                label: '',
                align: 'left',
                dataKey: 'rulesetName',
              },
              {
                id: 'videosFound',
                label: 'Videos Found',
                align: 'center',
                dataKey: 'videosFound',
              },
            ];
            setOptions({
              ...options,
              loading: false,
              columns,
              rows: res?.data?.rows || [],
              info: res.data.info,
              error: false,
            });
          }
        })
        .catch(() => {
          setOptions({
            ...options,
            loading: false,
            columns: [],
            rows: [],
            error: true,
          });
        });
    } else {
      setOptions({
        ...options,
        columns: [],
        rows: [],
        info: {},
        error: false,
        loading: false,
      });
    }
  }, [options.reloadCounter]);

  const showSnackbarMessage = (message = '', messageType = 'success') => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: messageType,
          message,
        });
      }, 50);
    }
  };

  const handleTestRun = () => {
    if (!openVideoDialoag) {
      setSelectedActionData({ open: false, id: null, data: {} });
      setOpenVideoDialog(true);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Channels
              id="channel"
              name="channel"
              label="Select Channel"
              defaultValue={selectedChannel}
              onChange={(c) => {
                setSelectedChannel(c);
                handleRefreshData();
              }}
              disabled={options.loading}
              sx={{ width: '70%' }}
            />
          </Grid>
          {!options.loading && selectedChannel?.id && (
            <>
              <Grid item xs={4}>
                <Typography variant="body2" mb={1}>
                  <strong>Total Videos:</strong>{' '}
                  {options.info.totalMovies + options.info.totalEpisodes}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total Movies:</strong> {options.info.totalMovies}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total Episodes:</strong> {options.info.totalEpisodes}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" gap={1} width="50%">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={options.loading || formSubmitted}
                    onClick={handleTestRun}
                  >
                    Test Run
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={options.loading || formSubmitted}
                  >
                    Run Assignment Rules
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Stack>
      {options.loading && <Loading />}
      {!options.loading && !selectedChannel?.id && (
        <Table>
          <TableRow>
            <TableCell align="center">
              <Typography variant="body2" sx={{ my: 20 }}>
                Select channel to manage assignment
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      )}
      {!options.loading && selectedChannel?.id && (
        <TableContainer sx={{ width: '50%' }}>
          <Table>
            {!options.error && options.rows.length > 0 && (
              <TableHead>
                <TableRow>
                  {options.columns.map((c, cindex) => (
                    <TableCell
                      key={`col-${c.id}`}
                      align={c.align}
                      width={c.width}
                      sx={{
                        cursor: c.sortable ? 'pointer' : 'inherit',
                        padding: '16px',
                        fontSize: '0.875rem !important',
                        position: cindex === 0 && 'sticky',
                        left: cindex === 0 && 0,
                      }}
                    >
                      <Stack direction="row" justifyContent={c.align}>
                        {c.sortable && (
                          <Sortable
                            dataKey={c.dataKey}
                            sortBy={options.sortBy}
                            sortOrder={options.sortOrder}
                          />
                        )}
                        {c.label}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {!options.error &&
                options.rows.length > 0 &&
                options.rows.map((r, index) => (
                  <TableRow key={`row-${r.id}-${index}`}>
                    {options.columns.map((c, cindex) => (
                      <TableCell
                        key={`data-col-${c.id}-${r[c.dataKey]}`}
                        align={c.align}
                        width={c.width}
                        sx={{
                          padding: '16px',
                          fontSize: '0.875rem !important',
                          backgroundColor: '#FFF',
                          position: cindex === 0 && 'sticky',
                          left: cindex === 0 && 0,
                        }}
                        variant="string"
                      >
                        {c.id === 'title' ? (
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              cursor: r.rulesetId ? 'pointer' : 'default',
                            }}
                            onClick={() => {
                              if (r.rulesetId) {
                                setOpenVideoDialog(false);
                                setSelectedActionData({
                                  open: true,
                                  id: r.rulesetId,
                                  data: r,
                                });
                              }
                            }}
                          >
                            {r[c.dataKey] || '0'}
                          </Typography>
                        ) : (
                          <Typography variant="body2" component="span">
                            {r[c.dataKey] || '0'}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              {!options.error && !options.rows.length && (
                <TableRow>
                  <TableCell align="center" colSpan={options.columns.length}>
                    <Typography variant="body2" sx={{ my: 20 }}>
                      No rulesets found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {options.error && (
                <TableRow>
                  <TableCell align="center" colSpan={options.columns.length}>
                    <Error />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!options.loading && selectedChannel?.id && (
        <Button
          color="primary"
          variant="contained"
          disabled={options.loading || formSubmitted}
          onClick={() => {
            setOpenVideoDialog(false);
            setSelectedActionData({ open: true, id: null, data: {} });
          }}
          sx={{ my: 2 }}
        >
          Add Ruleset
        </Button>
      )}
      {!options.loading && selectedChannel?.id && selectedActionData.open && (
        <Grid
          item
          sx={{
            position: 'fixed',
            bottom: 0,
            left: isMobileView ? '24px' : '300px',
            width: isMobileView ? 'calc(100% - 48px)' : 'calc(100% - 324px)',
            height: '450px',
            backgroundColor: 'white',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            overflowY: 'auto',
            zIndex: 100,
          }}
        >
          <Stack spacing={2}>
            <AddEditScheduleRulesetRules
              title={
                selectedActionData.id
                  ? `Edit ${selectedActionData?.data?.rulesetName} Ruleset`
                  : 'Add New Ruleset'
              }
              channelContentScheduleInfoId={options.info.id}
              dataId={selectedActionData.id}
              rulesetType="ASSIGNMENT"
              channelId={selectedChannel?.id}
              onSave={handleRefreshData}
              onCancel={() =>
                setSelectedActionData({
                  id: null,
                  data: {},
                  open: false,
                })
              }
              showSnackbarMessage={showSnackbarMessage}
              selectedRulesetName={selectedActionData.data.rulesetName}
            />
          </Stack>
        </Grid>
      )}
      {!options.loading && selectedChannel?.id && openVideoDialoag && (
        <Grid
          item
          sx={{
            position: 'fixed',
            bottom: 0,
            left: isMobileView ? '24px' : '300px',
            width: isMobileView ? 'calc(100% - 48px)' : 'calc(100% - 324px)',
            height: '450px',
            backgroundColor: 'white',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            padding: '16px',
            overflowY: 'auto',
            zIndex: 100,
          }}
        >
          <Stack spacing={2}>
            <ListExecutedRulesetsVideos
              title="View Assignment"
              channelContentScheduleInfoId={options.info.id}
              channelId={selectedChannel?.id}
              onClose={() => setOpenVideoDialog(false)}
            />
          </Stack>
        </Grid>
      )}
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ContentAssign;
