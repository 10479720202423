import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListWorkspace from '../../components/workspace/list-workspace.component';

const WorkspaceView = () => {
  const pageTitle = 'Settings / Workspaces';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListWorkspace />
    </AppLayout>
  );
};

export default WorkspaceView;
