import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../../config/const.config';
import ROUTE_PATH from '../../../config/routes.config';
import { getLocalValue } from '../../../utils/local-cache.util';
import { GREY, PRIMARY } from '../../../theme/theme-colors';
import Iconify from '../../common/iconify.component';
import useAccess from '../../../hooks/access.hook';

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'white',
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 20,
});

const NavItem = ({ title, path, icon, subNavItem }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&:hover': {
        color: 'white',
        bgcolor: GREY[900],
      },
      '&.active': {
        color: 'white',
        bgcolor: PRIMARY.main,
        fontWeight: 'fontWeightBold',
      },
      bgcolor: subNavItem ? GREY[800] : 'inherit',
      pl: subNavItem ? 2 : 0,
    }}
  >
    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
    <ListItemText disableTypography primary={title} />
  </StyledNavItem>
);

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  subNavItem: PropTypes.bool,
};

NavItem.defaultProps = {
  subNavItem: false,
};

const CollapsibleNavSection = ({ title, icon, children }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem onClick={handleToggle}>
        <StyledNavItemIcon>{icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {open ? (
          <Iconify icon="ic:round-expand-less" width={25} />
        ) : (
          <Iconify icon="ic:round-expand-more" width={25} />
        )}
      </StyledNavItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{children}</List>
      </Collapse>
    </>
  );
};

CollapsibleNavSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

const NavItems = () => {
  const accessRole = getLocalValue(LS_ADMIN_USER_ROLE);
  const { hasAccess, hasOperationAccess } = useAccess();

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        <NavItem
          title="Dashboard"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />

        {(hasAccess('video_library') ||
          hasAccess('series_library') ||
          hasAccess('manage_descriptions')) && (
          <CollapsibleNavSection
            title="Manage Content"
            icon={<Iconify icon="material-symbols:bookmark-manager-outline" />}
          >
            {hasAccess('video_library') && (
              <NavItem
                title="Video Library"
                path={ROUTE_PATH.VIDEOS}
                icon={<Iconify icon="octicon:video-16" />}
                subNavItem
              />
            )}
            {hasAccess('series_library') && (
              <NavItem
                title="Series Library"
                path={ROUTE_PATH.SERIES}
                icon={<Iconify icon="bxs:videos" />}
                subNavItem
              />
            )}
            {hasAccess('manage_descriptions') && (
              <NavItem
                title="Manage Descriptions"
                path={ROUTE_PATH.CONTENT_MANAGE_DESCRIPTIONS}
                icon={<Iconify icon="fluent:text-description-16-filled" />}
                subNavItem
              />
            )}
            {hasAccess('license_management') && (
              <NavItem
                title="License Management"
                path={ROUTE_PATH.LICENSE_MANAGEMENT}
                icon={<Iconify icon="fluent:text-description-16-filled" />}
                subNavItem
              />
            )}
          </CollapsibleNavSection>
        )}

        {(hasAccess('genres') ||
          hasAccess('playlists') ||
          hasAccess('collections')) && (
          <CollapsibleNavSection
            title="Organize Content"
            icon={<Iconify icon="fluent:organization-16-regular" />}
          >
            {hasAccess('genres') && (
              <NavItem
                title="Genres"
                path={ROUTE_PATH.CONTENT_GENRES}
                icon={<Iconify icon="teenyicons:attachment-outline" />}
                subNavItem
              />
            )}
            {hasAccess('playlists') && (
              <NavItem
                title="Playlists"
                path={ROUTE_PATH.CONTENT_ALL_PLAYLISTS}
                icon={<Iconify icon="mingcute:playlist-line" />}
                subNavItem
              />
            )}
            {hasAccess('collections') && (
              <NavItem
                title="Collections"
                path={ROUTE_PATH.CONTENT_COLLECTIONS}
                icon={<Iconify icon="bytesize:folder" />}
                subNavItem
              />
            )}
          </CollapsibleNavSection>
        )}

        {(hasAccess('channel_management') ||
          hasAccess('rulesets') ||
          hasAccess('channel_assignment')) && (
          <CollapsibleNavSection
            title="Manage Channels"
            icon={<Iconify icon="carbon:gui-management" />}
          >
            {hasAccess('channel_management') && (
              <NavItem
                title="Channels"
                path={ROUTE_PATH.CONTENT_ALL_CHANNELS}
                icon={<Iconify icon="ic:twotone-checklist" />}
                subNavItem
              />
            )}
            {hasAccess('rulesets') && (
              <>
                {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
                  <NavItem
                    title="Manage Rules"
                    path={ROUTE_PATH.MANAGE_RULES}
                    icon={<Iconify icon="bx:edit" />}
                    subNavItem
                  />
                )}
                <NavItem
                  title="Assign Content"
                  path={ROUTE_PATH.CONTENT_ASSIGNMENT}
                  icon={
                    <Iconify icon="material-symbols:assignment-add-outline-rounded" />
                  }
                  subNavItem
                />
                <NavItem
                  title="Schedule Content"
                  path={ROUTE_PATH.CONTENT_SCHEDULING}
                  icon={
                    <Iconify icon="material-symbols:schedule-outline-rounded" />
                  }
                  subNavItem
                />
              </>
            )}
            {hasAccess('channel_assignment') && (
              <NavItem
                title="Channel Assignment"
                path={ROUTE_PATH.MANAGE_SCHEDULE}
                icon={<Iconify icon="akar-icons:schedule" />}
                subNavItem
              />
            )}

            {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
              accessRole
            ) && (
              <NavItem
                title="Promo Codes"
                path={ROUTE_PATH.COUPONS_CONFIG}
                icon={<Iconify icon="mdi:coupon" />}
                subNavItem
              />
            )}
            {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
              <NavItem
                title="Global Channel Settings"
                path={ROUTE_PATH.CHANNEL_DEFAULT_CONTENT}
                icon={<Iconify icon="healthicons:default-outline" />}
                subNavItem
              />
            )}
            {hasAccess('channel_management') && (
              <NavItem
                title="Channel Genres"
                path={ROUTE_PATH.CONTENT_CHANNEL_GENRES}
                icon={<Iconify icon="teenyicons:attachment-outline" />}
                subNavItem
              />
            )}
          </CollapsibleNavSection>
        )}

        {hasAccess('manage_users') && (
          <CollapsibleNavSection
            title="Manage Users"
            icon={<Iconify icon="ci:users" />}
          >
            <NavItem
              title="All Users"
              path={ROUTE_PATH.SUBSCRIBERS}
              icon={<Iconify icon="ci:users" />}
              subNavItem
            />
            <NavItem
              title="All Subscribers"
              path={ROUTE_PATH.BILLING_SUBSCRIPTIONS}
              icon={<Iconify icon="ic:outline-receipt-long" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}

        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Manage Distributors"
            icon={<Iconify icon="fa6-solid:users-gear" />}
          >
            <NavItem
              title="Distributors"
              path={ROUTE_PATH.CONTENT_OWNERS}
              icon={<Iconify icon="mdi:users-check" />}
              subNavItem
            />
            <NavItem
              title="Portal Users"
              path={ROUTE_PATH.PORTAL_USERS}
              icon={<Iconify icon="mdi:users-check" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}

        {(hasAccess('advertising_tag_library') ||
          hasAccess('advertising_assign_tags')) && (
          <CollapsibleNavSection
            title="Advertising"
            icon={<Iconify icon="tabler:ad" />}
          >
            {hasAccess('advertising_tag_library') && (
              <NavItem
                title="Tag Library"
                path={ROUTE_PATH.ADVERTISERS_TAG_LIBRARY}
                icon={<Iconify icon="mdi:tag-outline" />}
                subNavItem
              />
            )}
            {hasOperationAccess('advertising_assign_tags', 'manage') && (
              <NavItem
                title="Assign Tags"
                path={ROUTE_PATH.ADVERTISERS_TAG_ASSIGN}
                icon={<Iconify icon="mdi:tag-plus-outline" />}
                subNavItem
              />
            )}
            {hasOperationAccess('advertising_assign_tags', 'view') && (
              <NavItem
                title="Deploy Tags"
                path={ROUTE_PATH.ADVERTISERS_TAG_DEPLOYED}
                icon={
                  <Iconify icon="material-symbols-light:list-alt-check-outline" />
                }
                subNavItem
              />
            )}
          </CollapsibleNavSection>
        )}

        {hasAccess('analytics') && (
          <CollapsibleNavSection
            title="Analytics"
            icon={<Iconify icon="ic:outline-analytics" />}
          >
            <NavItem
              title="Overview"
              path={ROUTE_PATH.REPORTS_OVERVIEW}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
            <NavItem
              title="Realtime Apps"
              path={ROUTE_PATH.REALTIME_DASHBOARD}
              icon={<Iconify icon="tabler:activity-heartbeat" />}
              subNavItem
            />
            <NavItem
              title="Realtime Users"
              path={ROUTE_PATH.REPORTS_LIVE_USERS}
              icon={<Iconify icon="ic:round-live-tv" />}
              subNavItem
            />
            <NavItem
              title="Active Subscribers"
              path={ROUTE_PATH.REPORTS_ACTIVE_SUBSCRIBERS}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
            <NavItem
              title="Trial Period Subscribers"
              path={ROUTE_PATH.REPORTS_TRIAL_SUBSCRIBERS}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
            <NavItem
              title="Users Count"
              path={ROUTE_PATH.REPORTS_CHANNEL_ALL_USERS}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
            <NavItem
              title="Views by App"
              path={ROUTE_PATH.CONTENT_CHANNELS}
              icon={<Iconify icon="ic:twotone-checklist" />}
              subNavItem
            />
            <NavItem
              title="Views by Video"
              path={ROUTE_PATH.CONTENT_VIDEOS}
              icon={<Iconify icon="octicon:video-16" />}
              subNavItem
            />
            <NavItem
              title="Views by Playlist"
              path={ROUTE_PATH.CONTENT_PLAYLISTS}
              icon={<Iconify icon="ic:round-playlist-add" />}
              subNavItem
            />
            <NavItem
              title="Views by Session (Video)"
              path={ROUTE_PATH.REPORTS_SESSIONS}
              icon={<Iconify icon="ic:outline-list-alt" />}
              subNavItem
            />
            <NavItem
              title="Views by Session (App)"
              path={ROUTE_PATH.REPORTS_APP_SESSIONS}
              icon={<Iconify icon="ion:analytics-sharp" />}
              subNavItem
            />
            <NavItem
              title="Migration Status"
              path={ROUTE_PATH.REPORTS_MIGRATION_STATUS}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
            <NavItem
              title="Migration Users"
              path={ROUTE_PATH.REPORTS_MIGRATION_USERS}
              icon={<Iconify icon="ic:outline-analytics" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}

        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Data Management"
            icon={<Iconify icon="carbon:import-export" />}
          >
            <NavItem
              title="Import"
              path={ROUTE_PATH.IMPORT}
              icon={<Iconify icon="iconoir:import" />}
              subNavItem
            />
            <NavItem
              title="Export"
              path={ROUTE_PATH.EXPORT}
              icon={<Iconify icon="uil:export" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}

        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <CollapsibleNavSection
            title="Settings"
            icon={<Iconify icon="ic:outline-settings" />}
          >
            <NavItem
              title="Workspaces"
              path={ROUTE_PATH.WORKSPACE_SETTINGS}
              icon={<Iconify icon="carbon:workspace" />}
              subNavItem
            />
            {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
              <NavItem
                title="User Permissions"
                path={ROUTE_PATH.ADMIN_USERS}
                icon={<Iconify icon="mdi:users-check" />}
                subNavItem
              />
            )}
            <NavItem
              title="Mail Server"
              path={ROUTE_PATH.SMTP_SETTINGS}
              icon={<Iconify icon="ic:outline-mail" />}
              subNavItem
            />
          </CollapsibleNavSection>
        )}
      </List>
    </Box>
  );
};

export default NavItems;
