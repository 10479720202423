import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const EditScheduleInfo = ({
  title,
  onCancel,
  onSuccess,
  defaultValues,
  formSubmitted,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      targetMovies: defaultValues.targetMovies || 0,
      targetEpisodes: defaultValues.targetEpisodes || 0,
    },
  });

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={formSubmitted}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        <form
          id="add-edit-schedule-form"
          onSubmit={handleSubmit(onSuccess)}
          className="w-full"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="targetMovies"
                control={control}
                rules={validationRules.REQUIRED}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Target #Movies *"
                    variant="filled"
                    fullWidth
                    size="small"
                    error={!!errors?.targetMovies}
                    helperText={errors?.targetMovies?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Controller
                name="targetEpisodes"
                control={control}
                rules={validationRules.REQUIRED}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Target #Episodes *"
                    variant="filled"
                    fullWidth
                    size="small"
                    error={!!errors?.targetEpisodes}
                    helperText={errors?.targetEpisodes?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-schedule-form"
            color="primary"
            variant="contained"
            sx={{ marginRight: 1 }}
            disabled={formSubmitted}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

EditScheduleInfo.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
};

export default EditScheduleInfo;
