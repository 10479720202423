import React from 'react';
import AppLayout from '../../layout/app.layout';
import ChannelAssignment from '../../components/content/channel-assignment.component';

const ChannelAssignmentView = () => {
  const pageTitle = 'Manage Channels / Channel Assignment';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ChannelAssignment />
    </AppLayout>
  );
};

export default ChannelAssignmentView;
