import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const TimeInput = ({ value = '', onChange }) => {
  const [time, setTime] = useState(value);
  useEffect(() => {
    setTime(value);
  }, [value]);

  const handleTimeChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9:]/g, '');
    const digits = inputValue.replace(/:/g, '').slice(0, 6);
    let formattedTime = '';

    const hh = digits.slice(0, 2);
    let mm = digits.slice(2, 4);
    let ss = digits.slice(4, 6);

    // Ensure MM and SS are between 00-59
    if (mm && parseInt(mm, 10) > 59) mm = '59';
    if (ss && parseInt(ss, 10) > 59) ss = '59';

    // Construct formatted time
    if (hh) formattedTime += hh;
    if (mm) formattedTime += `:${mm}`;
    if (ss) formattedTime += `:${ss}`;

    setTime(formattedTime);
    onChange(formattedTime);
  };

  return (
    <TextField
      label="Ad Timing (HH:MM:SS)"
      value={time}
      onChange={handleTimeChange}
      placeholder="HH:MM:SS"
      type="text"
      variant="filled"
      fullWidth
    />
  );
};

TimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeInput;
