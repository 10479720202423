import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import { maxLengthValidation } from '../../utils/validations.util';
import Countries from '../form-elements/countries.component';

const VideoImdbSettings = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      imdbDuration: videoDetail.imdbDuration || '',
      imdbYear: videoDetail.imdbYear || '',
      imdbAka: videoDetail.imdbAka || '',
      imdbPlotKeywords: videoDetail.imdbPlotKeywords || '',
      imdbDirectors: videoDetail.imdbDirectors || '',
      imdbWriters: videoDetail.imdbWriters || '',
      imdbCast: videoDetail.imdbCast || '',
      imdbTagline: videoDetail.imdbTagline || '',
      imdbLanguage: videoDetail.imdbLanguage || '',
      imdbBudget: videoDetail.imdbBudget || '',
      imdbCountry: videoDetail.imdbCountry || 'UN',
      imdbDescription: videoDetail.imdbDescription || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'IMDB Settings updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="imdb-settings-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbDuration"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Duration (In Minutes)"
                  type="number"
                  error={!!errors.imdbDuration}
                  helperText={errors?.imdbDuration?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbYear"
              control={control}
              rules={maxLengthValidation(4)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Year"
                  type="number"
                  error={!!errors.imdbYear}
                  helperText={errors?.imdbYear?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbAka"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Aka"
                  type="text"
                  error={!!errors.imdbAka}
                  helperText={errors?.imdbAka?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbPlotKeywords"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Plot Keywords"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbPlotKeywords}
                  helperText={errors?.imdbPlotKeywords?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbDescription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Description"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbDescription}
                  helperText={errors?.imdbDescription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbDirectors"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Directors"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbDirectors}
                  helperText={errors?.imdbDirectors?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbWriters"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Writers"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbWriters}
                  helperText={errors?.imdbWriters?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbCast"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Cast"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbCast}
                  helperText={errors?.imdbCast?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbTagline"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Tagline"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbTagline}
                  helperText={errors?.imdbTagline?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbLanguage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Language"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.imdbLanguage}
                  helperText={errors?.imdbLanguage?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbBudget"
              control={control}
              rules={maxLengthValidation(32)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Budget"
                  type="text"
                  error={!!errors.imdbBudget}
                  helperText={errors?.imdbBudget?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbCountry"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Countries
                  id="imdb_country"
                  name="imdb_country"
                  label="IMDB Country"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.imdbCountry?.message || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="imdb-settings-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save IMDB Settings
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoImdbSettings.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoImdbSettings;
