import CELL_DATA_TYPES from '../config/cell-types.config';
import { DYNAMIC_COLUMNS_CONFIG } from '../config/module-configs/content-videos.config';

const prepareDynamicColumns = (data) => {
  const columns = [
    {
      id: 'title',
      label: 'Title',
      align: 'left',
      dataKey: 'title',
      sortable: true,
      type: CELL_DATA_TYPES.TOOLTIP_TEXT,
      editable: false,
    },
  ];

  data.forEach((item) => {
    if (item.fieldName === 'series_id') {
      columns.push({
        id: 'series_name',
        label: 'Series',
        align: 'left',
        dataKey: 'series_name',
        sortable: true,
        type: CELL_DATA_TYPES.TEXT,
        editable: false,
      });
    } else if (item.fieldName === 'distributor_id') {
      columns.push({
        id: 'distributor_name',
        label: 'Distributor Name',
        align: 'left',
        dataKey: 'distributor_name',
        sortable: true,
        type: CELL_DATA_TYPES.TEXT,
        editable: false,
      });
    } else {
      columns.push({
        id: item.fieldName,
        label: item.fieldName
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
        align: 'left',
        dataKey: item.fieldName,
        sortable: true,
        type:
          DYNAMIC_COLUMNS_CONFIG[item.fieldName]?.cellType ||
          CELL_DATA_TYPES.TEXT,
        editable: DYNAMIC_COLUMNS_CONFIG[item.fieldName]?.editable || false,
      });
    }
  });

  return columns;
};

export const getEditableCellDefaultValue = (dataKey, cellType) => {
  if (cellType === 'switch') return false;

  const defaultValues = {
    restricted_content: 'NC',
    avod_validated: 'NC',
    restricted_content_reason: 'NA',
    good_bad: 'NA',
    trashy: 'NO',
    country: 'UN',
    ad_timing_status: 'NC',
    public_domain: 'NO',
    poster_16x9_status: 'NC',
  };

  if (!defaultValues[dataKey] && cellType === 'selection') return null;

  return defaultValues[dataKey] ?? '';
};

export default prepareDynamicColumns;
