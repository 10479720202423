import React, { useState, forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  Typography,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import Channels from '../form-elements/channels.component';
import {
  getChannelWisePlaylists,
  updateChannelPlaylistsSequence,
} from '../../services/playlists.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ListSequencePlaylists = ({
  title,
  handleCloseDialog,
  handleSequenceNotification,
}) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const intialPlaylist = useRef([]);

  useEffect(() => {
    if (selectedChannel?.id) {
      setLoading(true);
      setIsOrderChanged(false);
      getChannelWisePlaylists(selectedChannel.id)
        .then((res) => {
          intialPlaylist.current = res.data;
          setPlaylists(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setPlaylists([]);
      setLoading(false);
    }
  }, [selectedChannel]);

  const handleOnDragEndPlaylists = (result) => {
    if (result.destination) {
      const newPlaylists = Array.from(playlists);
      const [draggedItem] = newPlaylists.splice(result.source.index, 1);

      newPlaylists.splice(result.destination.index, 0, draggedItem);
      if (
        JSON.stringify(newPlaylists) === JSON.stringify(intialPlaylist.current)
      ) {
        setIsOrderChanged(false);
      } else {
        setIsOrderChanged(true);
      }
      setPlaylists(newPlaylists);
    }
  };

  const handleUpdatePlaylistsSequence = () => {
    setFormSubmitted(true);
    const updatedChannelPlaylistsSequence = playlists.map(
      (playlist) => playlist.playlistId
    );

    const payload = {
      channelId: selectedChannel?.id,
      newSequence: updatedChannelPlaylistsSequence,
    };

    updateChannelPlaylistsSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          setFormSubmitted(false);
          handleSequenceNotification(
            'Playlist sequence updated successfully.',
            'success'
          );
          handleCloseDialog();
        }
      })
      .catch(() => {
        setFormSubmitted(false);
        handleSequenceNotification('Something went wrong.', 'error');
      });
  };

  return (
    <Dialog
      open
      aria-describedby="channel-playlists"
      aria-labelledby="channel-playlists"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Channels
          id="playlist_channels"
          name="playlist_channels"
          label="Select Channels *"
          defaultValue={selectedChannel}
          multiple={false}
          onChange={(e) => {
            setSelectedChannel(e || null);
          }}
          sx={{ width: '100%', marginBottom: '16px' }}
          defaultSelectFirst
        />

        {loading && <Loading />}

        {!loading && (
          <DragDropContext onDragEnd={handleOnDragEndPlaylists}>
            <Droppable droppableId="playlists">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {playlists.length > 0 &&
                    playlists.map((playlist, index) => (
                      <Draggable
                        key={`playlist-${playlist.id}`}
                        draggableId={playlist.id.toString()}
                        index={index}
                      >
                        {(provide) => (
                          <Card
                            sx={{
                              marginBottom: '5px',
                            }}
                            ref={provide.innerRef}
                            {...provide.draggableProps}
                            {...provide.dragHandleProps}
                          >
                            <CardContent
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingY: '12px',
                                '&:last-child': {
                                  paddingBottom: '12px',
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                color="inherit"
                              >
                                {playlist.name}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    marginLeft: 20,
                                  }}
                                >
                                  <Iconify icon="ic:round-table-rows" />
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                    ))}

                  {playlists.length === 0 && (
                    <Paper
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: '40vh',
                        alignItems: 'center',
                      }}
                    >
                      No data available.
                    </Paper>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>

      {!loading && (
        <DialogActions>
          {playlists.length > 0 && (
            <Button
              onClick={handleUpdatePlaylistsSequence}
              disabled={
                playlists.length === 0 ||
                playlists.length === 1 ||
                !isOrderChanged ||
                formSubmitted
              }
            >
              Save
            </Button>
          )}
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ListSequencePlaylists.propTypes = {
  title: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleSequenceNotification: PropTypes.func.isRequired,
};

export default ListSequencePlaylists;
