import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  LISTING_COLUMNS,
  SUB_LISTING_COLUMNS,
} from '../../config/module-configs/workspace.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  getFieldsListById,
  getListData,
} from '../../services/workspace.service';

const ListWorkspace = ({ openFilters }) => {
  const columns = LISTING_COLUMNS;
  const subRowColumns = SUB_LISTING_COLUMNS;
  const actions = [];

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: null,
    rows: [],
    reloadCounter: 0,
    error: false,
    loading: true,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const handleShowSubRow = (rowData) => {
    if (!rowData?.subRows || (rowData?.subRows && !rowData.subRows.length)) {
      setOptions({
        ...options,
        rows: options.rows.map((item) =>
          item.id === rowData.id
            ? { ...item, subRowsLoading: true, subRows: [] }
            : item
        ),
      });

      getFieldsListById(rowData.id)
        .then((res) => {
          setOptions({
            ...options,
            rows: options.rows.map((item) =>
              item.id === rowData.id
                ? { ...item, subRowsLoading: false, subRows: res?.data }
                : item
            ),
          });
        })
        .catch(() => {
          // Skipped
        });
    } else {
      setOptions({
        ...options,
        rows: options.rows.map((item) =>
          item.id === rowData.id
            ? { ...item, subRowsLoading: false, subRows: [] }
            : item
        ),
      });
    }
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          rows: res?.data || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilters ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 0, py: 2 }}
      >
        <div />
        <div>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          onSortChange={() => {}}
          onAction={handleActionSelect}
          subRowColumns={subRowColumns}
          handleShowSubRow={handleShowSubRow}
        />
      </Card>
    </Box>
  );
};

ListWorkspace.propTypes = {
  openFilters: PropTypes.bool.isRequired,
};
export default ListWorkspace;
