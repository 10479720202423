import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import { viewDataById } from '../../services/videos.service';
import ROUTE_PATH from '../../config/routes.config';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import PageNotFoundView from '../errors/not-found.view';
import ViewGridData from '../../components/table-elements/view-grid-data.component';
import {
  VIEW_ASSIGNMENTS_COLUMNS,
  VIEW_DISTRIBUTOR_COLUMNS,
  VIEW_IMDB_COLUMNS,
  VIEW_METADATA_COLUMNS,
  VIEW_OTHERS_COLUMNS,
  VIEW_RATING_COLUMNS,
  VIEW_SCHEDULING_COLUMNS,
  VIEW_SERIES_COLUMNS,
  VIEW_STATUS_COLUMNS,
  VIEW_SYSTEM_ADMIN_COLUMNS,
} from '../../config/module-configs/content-videos.config';
import useAccess from '../../hooks/access.hook';
import Iconify from '../../components/common/iconify.component';
import replaceIdUrl from '../../utils/url.util';

const TABS_CONFIG = [
  {
    value: 'metadata',
    label: 'Basic Metadata',
    columns: VIEW_METADATA_COLUMNS,
  },
  {
    value: 'series',
    label: 'Series & Categorization',
    columns: VIEW_SERIES_COLUMNS,
  },
  {
    value: 'rating',
    label: 'Rating & Classification',
    columns: VIEW_RATING_COLUMNS,
  },
  { value: 'status', label: 'Status', columns: VIEW_STATUS_COLUMNS },
  {
    value: 'distributor',
    label: 'Distributor & Monetization',
    columns: VIEW_DISTRIBUTOR_COLUMNS,
  },
  {
    value: 'scheduling',
    label: 'Scheduling',
    columns: VIEW_SCHEDULING_COLUMNS,
  },
  {
    value: 'systemAdmin',
    label: 'System & Admin',
    columns: VIEW_SYSTEM_ADMIN_COLUMNS,
  },
  { value: 'imdb', label: 'IMDB Settings', columns: VIEW_IMDB_COLUMNS },
  { value: 'others', label: 'Others', columns: VIEW_OTHERS_COLUMNS },
  {
    value: 'assignments',
    label: 'Assignments',
    columns: VIEW_ASSIGNMENTS_COLUMNS,
  },
];

const VideoViewDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { hasOperationAccess } = useAccess();
  const [tabValue, setTabValue] = useState(TABS_CONFIG[0].value);
  const [loading, setLoading] = useState(true);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    viewDataById(id)
      .then((res) => {
        setVideoDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShowPageNotFound(true);
          return;
        }
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [id]);

  return Number.isNaN(Number(id)) || showPageNotFound ? (
    <PageNotFoundView />
  ) : (
    <AppLayout
      pageTitle={`View Video Details - ${videoDetail.title}`}
      showBack
      handleBack={() => navigate(ROUTE_PATH.VIDEOS)}
    >
      {hasOperationAccess('video_library', 'edit') && (
        <Box display="flex" justifyContent="flex-end" my={1}>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="bx:edit" />}
            onClick={() => {
              const url = replaceIdUrl(ROUTE_PATH.VIDEO_EDIT, id);
              navigate(url);
            }}
            disabled={loading}
            sx={{ mr: 1 }}
          >
            Edit This Video
          </Button>
        </Box>
      )}
      <Box height="100%" display="flex">
        <Tabs
          value={tabValue}
          aria-label="tabs"
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="transparent"
          sx={{ minWidth: 150, maxWidth: 150 }}
          onChange={(_, v) => setTabValue(v)}
        >
          {TABS_CONFIG.map((tab, index) => (
            <Tab
              key={index + 1}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabValue === tab.value && '#fff',
                borderLeft: tabValue === tab.value && '2px solid #FD0100',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            />
          ))}
        </Tabs>

        <Box flexGrow={1}>
          {loading && <Loading />}

          {!loading && (
            <ViewGridData
              columns={
                TABS_CONFIG.filter((tab) => tab.value === tabValue)[0]?.columns
              }
              row={videoDetail}
            />
          )}
        </Box>
      </Box>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default VideoViewDetails;
