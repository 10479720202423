import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Playlists module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '30%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'rule',
    label: 'Rule',
    width: '30%',
    align: 'left',
    dataKey: 'rule',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '10%',
    align: 'left',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const OVERVIEW_LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '90%',
    align: 'left',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'totalVideos',
    label: 'Total Videos',
    width: '10%',
    align: 'center',
    dataKey: 'totalVideos',
    type: CELL_DATA_TYPES.NUMBER,
  },
];
