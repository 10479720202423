// Define admin roles list
const TIMEZONE_LIST = [
  { id: 'GMT', title: 'GMT Greenwich Mean Time (GMT)' },
  { id: 'GMT+1:00', title: 'GMT+1:00 European Central Time (ECT)' },
  { id: 'GMT+2:00', title: 'GMT+2:00 Eastern European Time (EET)' },
  { id: 'GMT+3:00', title: 'GMT+3:00 Eastern African Time (EAT)' },
  { id: 'GMT+3:30', title: 'GMT+3:30 Middle East Time (MET)' },
  { id: 'GMT+4:00', title: 'GMT+4:00 Near East Time (NET)' },
  { id: 'GMT+5:00', title: 'GMT+5:00 Pakistan Lahore Time (PLT)' },
  { id: 'GMT+5:30', title: 'GMT+5:30 India Standard Time (IST)' },
  { id: 'GMT+6:00', title: 'GMT+6:00 Bangladesh Standard Time (BST)' },
  { id: 'GMT+7:00', title: 'GMT+7:00 Vietnam Standard Time (VST)' },
  { id: 'GMT+8:00', title: 'GMT+8:00 China Taiwan Time (CTT)' },
  { id: 'GMT+9:00', title: 'GMT+9:00 Japan Standard Time (JST)' },
  { id: 'GMT+9:30', title: 'GMT+9:30 Australia Central Time (ACT)' },
  { id: 'GMT+10:00', title: 'GMT+10:00 Australia Eastern Time (AET)' },
  { id: 'GMT+11:00', title: 'GMT+11:00 Solomon Standard Time (SST)' },
  { id: 'GMT+12:00', title: 'GMT+12:00 New Zealand Standard Time (NST)' },
  { id: 'GMT-11:00', title: 'GMT-11:00 Midway Islands Time (MIT)' },
  { id: 'GMT-10:00', title: 'GMT-10:00 Hawaii Standard Time (HST)' },
  { id: 'GMT-9:00', title: 'GMT-9:00 Alaska Standard Time (AST)' },
  { id: 'GMT-8:00', title: 'GMT-8:00 Pacific Standard Time (PST)' },
  { id: 'GMT-7:00', title: 'GMT-7:00 Phoenix Standard Time (PNT)' },
  { id: 'GMT-6:00', title: 'GMT-6:00 Central Standard Time (CST)' },
  { id: 'GMT-5:00', title: 'GMT-5:00 Eastern Standard Time (EST)' },
  {
    id: 'GMT-4:00',
    title: 'GMT-4:00 Puerto Rico and US Virgin Islands Time (PRT)',
  },
  { id: 'GMT-3:30', title: 'GMT-3:30 Canada Newfoundland Time (CNT)' },
  { id: 'GMT-3:00', title: 'GMT-3:00 Argentina Standard Time (AGT)' },
  { id: 'GMT-1:00', title: 'GMT-1:00 Central African Time (CAT)' },
];

export const getTimezoneLabel = (val) => {
  let label = '';

  TIMEZONE_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export default TIMEZONE_LIST;
