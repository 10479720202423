import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListPayments from '../../components/billing/list-payments.component';

const BillingPaymentsView = () => {
  const pageTitle = 'User Management / Payments';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListPayments />
    </AppLayout>
  );
};

export default BillingPaymentsView;
