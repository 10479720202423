import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListExport from '../../components/import-export/list-export.component';

const ExportView = () => {
  const pageTitle = 'Data Management / Export';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListExport />
    </AppLayout>
  );
};

export default ExportView;
