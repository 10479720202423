import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListCollections from '../../components/content/list-collections.component';

const CollectionsView = () => {
  const pageTitle = 'Organize Content / Collections';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListCollections />
    </AppLayout>
  );
};

export default CollectionsView;
