import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Chip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_LIST_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/content-videos.config';
import Iconify from '../common/iconify.component';
import { getListData } from '../../services/videos.service';
import { ACTIONS } from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import replaceIdUrl from '../../utils/url.util';
import ROUTE_PATH from '../../config/routes.config';
import CreateContentVideo from './create-content-video.component';
import FilterVideos from '../filter-videos/filter-video.component';
import Workspace from '../form-elements/workspace.component';
import { FiltersContext } from '../../context/filter.context';
import prepareDynamicColumns from '../../utils/dynamic-columns.util';
import ListVideosData from '../table-elements/list-videos-data.component';
import EditVideosList from './edit-videos-list.component';
import useAccess from '../../hooks/access.hook';
import useFilteredActions from '../../hooks/filtered-action.hook';
import ManageUserEdits from '../video-edit-approvals/index.component';
import AssignChannelsVideo from './assign-channels-video.component';

const ListContentVideos = () => {
  const actions = useFilteredActions('video_library', LISTING_ACTIONS);

  const navigate = useNavigate();
  const { hasOperationAccess } = useAccess();
  const { openFilter, handleToggleFilters } = useContext(FiltersContext);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    columns: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {},
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [enabledEditMode, setEnabledEditMode] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    if (selectedAction === ACTIONS.EDIT.value) {
      const url = replaceIdUrl(ROUTE_PATH.VIDEO_EDIT, selectedData.id);
      navigate(url);
    } else if (selectedAction === ACTIONS.VIEW.value) {
      const url = replaceIdUrl(ROUTE_PATH.VIDEO_VIEW_DETAILS, selectedData.id);
      navigate(url);
    } else if (selectedAction === ACTIONS.PLAY_VIDEO.value) {
      const url = replaceIdUrl(ROUTE_PATH.VIDEO_PREVIEW, selectedData.id);
      const popup = window.open(url, '_blank', 'width=720,height=405');
      if (!popup) {
        setTimeout(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'warning',
            message: 'Popup blocked! Please allow popups for this site.',
          });
        }, 50);
      }
    }
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }

    const updatedFilters = { ...options.appliedFilters };
    delete updatedFilters[field];

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    setOptions({ ...options, loading: true });

    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    if (selectedWorkspace?.id) {
      params.push(`workspaceId=${selectedWorkspace.id}`);
    }

    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }

    const uri = '';
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(uri + paramsQuery, { filter: filters })
      .then((res) => {
        const columns = selectedWorkspace?.id
          ? prepareDynamicColumns(res?.data?.columnsFields)
          : DEFAULT_LIST_COLUMNS;

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          columns: columns.map((item) =>
            !enabledEditMode &&
            item.id === 'genres' &&
            item.dataKey === 'genres'
              ? { ...item, id: 'genres_name', dataKey: 'genres_name' }
              : item
          ),
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter, selectedWorkspace?.id]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0, py: 2 }}
      >
        <Box width="60%" display="flex" alignItems="center">
          {hasOperationAccess('video_library', 'create') && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}
          <Workspace
            id="workspace"
            name="workspace"
            label="Workspace"
            defaultValue={selectedWorkspace}
            onChange={(v) => setSelectedWorkspace(v)}
            sx={{ width: '50%', ml: 2 }}
          />
        </Box>
        <div>
          {hasOperationAccess('video_library', 'edit') && (
            <Button
              color="inherit"
              variant="contained"
              startIcon={<Iconify icon="bx:edit" />}
              onClick={() => setEnabledEditMode(true)}
              disabled={options.loading}
              sx={{ mr: 1 }}
            >
              Edit Mode
            </Button>
          )}
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      {options.appliedFilters &&
        Object.values(options.appliedFilters).some(
          (filter) => filter.apply
        ) && (
          <Box mb={2}>
            <Typography variant="body1" fontWeight="bold" display="inline">
              Filters Applied:{' '}
              <Chip
                key="all"
                label="Clear all"
                onDelete={() => handleDeleteFilter('clear-all')}
                sx={{ ml: 1, color: 'red' }}
              />
            </Typography>

            {Object.entries(options.appliedFilters)
              .filter(([, filter]) => filter.apply)
              .map(([field, filter]) => (
                <Chip
                  key={field}
                  label={filter.title || 'Active'}
                  onDelete={() => handleDeleteFilter(field)}
                  sx={{ ml: 1 }}
                />
              ))}
          </Box>
        )}

      <Card>
        <ListVideosData
          columns={options.columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterVideos
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.CREATE.value && (
        <CreateContentVideo
          title="Create New Video"
          copyChannelId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.ASSIGN_CHANNELS.value && (
        <AssignChannelsVideo
          title={`Assign Channels to Video - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {enabledEditMode && (
        <EditVideosList
          title="Content Management / Videos Library - Edit Mode"
          onCancel={() => setEnabledEditMode(false)}
          viewModeWorkspace={selectedWorkspace}
          videoListFilters={options.appliedFilters}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_EDITS.value && (
        <ManageUserEdits
          title={`Manage Edits For Video - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          byVideo
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ListContentVideos;
