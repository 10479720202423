import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Chip,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  TableBody,
} from '@mui/material';
import dayjs from 'dayjs';
import Iconify from '../common/iconify.component';
import {
  getListData,
  updateLicenseExpirationDate,
} from '../../services/videos.service';
import SnackbarInfo from '../common/snackbar-info.component';
import FilterVideos from '../filter-videos/filter-video.component';
import { FiltersContext } from '../../context/filter.context';
import Loading from '../table-elements/loading.component';
import NoData from '../table-elements/no-data.component';
import Error from '../table-elements/error.component';
import Datepicker from '../form-elements/datepicker.component';
import Sortable from '../table-elements/sortable.component';
import useAccess from '../../hooks/access.hook';

const ListLicense = () => {
  const { hasOperationAccess } = useAccess();
  const { openFilter, handleToggleFilters } = useContext(FiltersContext);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {},
    filtersCounter: 0,
  });

  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const [changedDates, setChangedDates] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }

    const updatedFilters = { ...options.appliedFilters };
    delete updatedFilters[field];

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDateChange = (id, newDate) => {
    setChangedDates((prev) => ({
      ...prev,
      [id]: dayjs(newDate).format('YYYY-MM-DD'),
    }));
  };

  const handleSaveChanges = () => {
    setFormSubmitted(true);

    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    updateLicenseExpirationDate(changedDates)
      .then(() => {
        setFormSubmitted(false);
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'License Expiration Date updated Successfully',
        });
        setChangedDates({});
        handleRefreshData();
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setFormSubmitted(false);
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  useEffect(() => {
    setOptions({ ...options, loading: true });

    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }

    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }

    const uri = '';
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(uri + paramsQuery, { filter: filters })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0, py: 2 }}
      >
        <Box>
          {Object.keys(changedDates).length > 0 &&
            hasOperationAccess('license_management', 'manage') && (
              <>
                <Typography variant="body1" fontWeight="bold" display="inline">
                  Changes are not saved!
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSaveChanges || formSubmitted}
                  sx={{ ml: 2 }}
                >
                  Save
                </Button>
              </>
            )}
        </Box>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      {options.appliedFilters &&
        Object.values(options.appliedFilters).some(
          (filter) => filter.apply
        ) && (
          <Box mb={2}>
            <Typography variant="body1" fontWeight="bold" display="inline">
              Filters Applied:{' '}
              <Chip
                key="all"
                label="Clear all"
                onDelete={() => handleDeleteFilter('clear-all')}
                sx={{ ml: 1, color: 'red' }}
              />
            </Typography>

            {Object.entries(options.appliedFilters)
              .filter(([, filter]) => filter.apply)
              .map(([field, filter]) => (
                <Chip
                  key={field}
                  label={filter.title || 'Active'}
                  onDelete={() => handleDeleteFilter(field)}
                  sx={{ ml: 1 }}
                />
              ))}
          </Box>
        )}

      <Card>
        <form id="add-edit-form">
          {options.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.totalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}

          <TableContainer>
            {options.loading && <Loading />}

            {!options.loading && options.rows.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      width="10%"
                      sx={{
                        cursor: 'pointer',
                        padding: '16px',
                        fontSize: '0.875rem !important',
                      }}
                      onClick={() => {
                        if (options.sortOrder === '') {
                          handleSortingChange('title', 'asc');
                        } else if (options.sortOrder === 'asc') {
                          handleSortingChange('title', 'desc');
                        } else if (options.sortOrder === 'desc') {
                          handleSortingChange('', '');
                        }
                      }}
                    >
                      <Stack direction="row">
                        <Sortable
                          dataKey="title"
                          sortBy={options.sortBy}
                          sortOrder={options.sortOrder}
                        />
                        Video
                      </Stack>
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        cursor: 'pointer',
                        padding: '16px',
                        fontSize: '0.875rem !important',
                      }}
                      onClick={() => {
                        if (options.sortOrder === '') {
                          handleSortingChange('distributorSku', 'asc');
                        } else if (options.sortOrder === 'asc') {
                          handleSortingChange('distributorSku', 'desc');
                        } else if (options.sortOrder === 'desc') {
                          handleSortingChange('', '');
                        }
                      }}
                    >
                      <Stack direction="row">
                        <Sortable
                          dataKey="distributorSku"
                          sortBy={options.sortBy}
                          sortOrder={options.sortOrder}
                        />
                        SKU
                      </Stack>
                    </TableCell>
                    <TableCell
                      width="15%"
                      sx={{
                        cursor: 'pointer',
                        padding: '16px',
                        fontSize: '0.875rem !important',
                      }}
                      onClick={() => {
                        if (options.sortOrder === '') {
                          handleSortingChange('licenseExpiration', 'asc');
                        } else if (options.sortOrder === 'asc') {
                          handleSortingChange('licenseExpiration', 'desc');
                        } else if (options.sortOrder === 'desc') {
                          handleSortingChange('', '');
                        }
                      }}
                    >
                      <Stack direction="row">
                        <Sortable
                          dataKey="licenseExpiration"
                          sortBy={options.sortBy}
                          sortOrder={options.sortOrder}
                        />
                        License Expiration
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {options.rows.length > 0 &&
                    options.rows.map((v) => (
                      <TableRow key={`row-${v.id}`}>
                        <TableCell width="10%" sx={{ verticalAlign: 'center' }}>
                          {v.title}
                        </TableCell>
                        <TableCell
                          width="15%"
                          sx={{
                            verticalAlign: 'center',
                          }}
                        >
                          {v.distributorSku || '-'}
                        </TableCell>
                        <TableCell
                          width="15%"
                          sx={{
                            verticalAlign: 'center',
                          }}
                        >
                          <Datepicker
                            id="license_expiration_date"
                            name="license_expiration_date"
                            label="License Expiration Date"
                            defaultValue={v.licenseExpiration}
                            clearable
                            onChange={(e) => {
                              handleDateChange(v.id, e);
                            }}
                            sx={{ width: '100%' }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  {!options.loading &&
                    !options.error &&
                    options.totalRows === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <NoData />
                        </TableCell>
                      </TableRow>
                    )}

                  {!options.loading &&
                    options.error &&
                    options.totalRows === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Error />
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {options.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.totalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}
        </form>
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterVideos
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ListLicense;
