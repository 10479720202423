import React from 'react';
import AppLayout from '../../layout/app.layout';
import ManageDescriptions from '../../components/content/manage-descriptions.component';

const ManageDescriptionsView = () => {
  const pageTitle = 'Manage Content / Manage Descriptions';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ManageDescriptions />
    </AppLayout>
  );
};

export default ManageDescriptionsView;
