import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
  minLengthValidation,
} from '../../utils/validations.util';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import VideoTypes from '../form-elements/video-types.component';
import Countries from '../form-elements/countries.component';
import Regions from '../form-elements/regions.component';
import Resolution from '../form-elements/resolution.component';
import ManualStatus from '../form-elements/manual-status.component';
import Decades from '../form-elements/decades.component';
import { COUNTRIES, DECADES } from '../../config/const.config';

const VideoMetadata = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: videoDetail.title || '',
      videoType: videoDetail.videoType || 'FE',
      aka: videoDetail.aka || '',
      status: videoDetail.status || 0,
      description: videoDetail.description || '',
      duration: videoDetail.duration || '',
      resolution: videoDetail.resolution || 'UK',
      year: videoDetail.year || '',
      decade: videoDetail.decade || '',
      country: videoDetail.country || 'UN',
      region: videoDetail.region || 'UK',
      directors: videoDetail.directors || '',
      writers: videoDetail.writers || '',
      cast: videoDetail.cast || '',
      imdbId: videoDetail.imdbId || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const watchYearValue = watch('year');
  const watchCountryValue = watch('country');

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Video metadata updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (watchYearValue.length === 4) {
      const filterDecade = Object.keys(DECADES)
        .map((item) => Number(item.replace('s', '')))
        .filter((item) => item <= watchYearValue);

      setValue('decade', `${filterDecade[filterDecade.length - 1]}s`);
    } else {
      setValue('decade', '');
    }
  }, [watchYearValue]);

  useEffect(() => {
    if (watchCountryValue) {
      const regionCode = COUNTRIES.filter(
        (item) => item.value === watchCountryValue
      )[0]?.regionCode;

      setValue('region', regionCode);
    }
  }, [watchCountryValue]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="video-metadata-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="title"
              control={control}
              rules={{
                ...validationRules.REQUIRED,
                ...maxLengthValidation(128),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title *"
                  type="text"
                  error={!!errors.title}
                  helperText={errors?.title?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoType"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <VideoTypes
                  id="video_type"
                  name="video_type"
                  label="Type"
                  defaultValue={value}
                  onChange={onChange}
                  error={errors?.videoType?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="aka"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Aka"
                  type="text"
                  error={!!errors.aka}
                  helperText={errors?.aka?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="status"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <ManualStatus
                  id="video_status"
                  name="video_status"
                  label="Status"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={[0, 1]}
                  sx={{ width: '100%' }}
                  error={errors?.status?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.description}
                  helperText={errors?.description?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="duration"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Duration (In Minutes)"
                  type="number"
                  error={!!errors.duration}
                  helperText={errors?.duration?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="resolution"
              control={control}
              rules={maxLengthValidation(10)}
              render={({ field: { onChange, value } }) => (
                <Resolution
                  id="resolution"
                  name="resolution"
                  label="Resolution"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.resolution?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="year"
              control={control}
              rules={{ ...maxLengthValidation(4), ...minLengthValidation(4) }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Year"
                  type="number"
                  error={!!errors.year}
                  helperText={errors?.year?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="decade"
              control={control}
              rules={maxLengthValidation(10)}
              render={({ field: { onChange, value } }) => (
                <Decades
                  id="decade"
                  name="decade"
                  label="Decade"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={Object.keys(DECADES)}
                  sx={{ width: '100%' }}
                  error={errors?.decade?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Countries
                  id="country"
                  name="country"
                  label="Country"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.country?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="region"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Regions
                  id="region"
                  name="region"
                  label="Region"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.region?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="directors"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Directors"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.directors}
                  helperText={errors?.directors?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="writers"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Writers"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.writers}
                  helperText={errors?.writers?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="cast"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cast"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.cast}
                  helperText={errors?.cast?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbId"
              control={control}
              rules={maxLengthValidation(16)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Id"
                  type="text"
                  error={!!errors.imdbId}
                  helperText={errors?.imdbId?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="video-metadata-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Metadata
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoMetadata.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoMetadata;
