import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListLicense from '../../components/content/list-license.component';

const LicenseManagementView = () => {
  const pageTitle = 'Manage Content / License Management';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListLicense />
    </AppLayout>
  );
};

export default LicenseManagementView;
