import React from 'react';
import AppLayout from '../../layout/app.layout';
import ContentSchedule from '../../components/ruleset/content-schedule.component';
import { CONTENT_SCHEDULE_TYPE } from '../../config/const.config';

const SchedulingContentView = () => {
  const pageTitle = 'Manage Channels / Schedule Content';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ContentSchedule type={CONTENT_SCHEDULE_TYPE.GENERAL} />
    </AppLayout>
  );
};

export default SchedulingContentView;
