import axios from 'axios';
import prepareRequestOptions, {
  prepareUploadRequestOptions,
} from '../utils/admin-apis.util';

export const importCsvFile = async (payload) => {
  const options = prepareUploadRequestOptions(
    `/admin/csv/import`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getImportedCsvReportsData = async () => {
  const options = prepareRequestOptions(
    `/admin/csv/import-report`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const downloadImportedCsvReport = async (id) => {
  const options = prepareRequestOptions(
    `/admin/import-report/${id}/download`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const exportCsvFile = async (payload) => {
  const options = prepareUploadRequestOptions(
    `/admin/csv/export`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getExportedCsvList = async () => {
  const options = prepareRequestOptions(
    `/admin/csv/export/list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const downloadExportedCsvReport = async (id) => {
  const options = prepareRequestOptions(
    `/admin/export-file/${id}/download`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
