import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ListData from '../table-elements/list-data.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { LISTING_COLUMNS } from '../../config/module-configs/manage-videos.config';
import {
  getChannelAssignRulesetVideos,
  rulesetbaseAddVideoById,
} from '../../services/ruleset.service';
import { getListData } from '../../services/videos.service';

const ListExecutedRulesetsVideos = ({
  title,
  channelContentScheduleInfoId,
  channelId,
  onClose,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [activeTab, setActiveTab] = useState('productionAssignment');
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [videosList, setVideosList] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    columns: LISTING_COLUMNS,
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });
  // const [processing, setProcessing] = useState(false);

  const handleGlobalSelection = () => {
    setSelectAllPages(!selectAllPages);
    setSelectedRows([]);
  };

  const handlePageChange = (v) => {
    setVideosList({
      ...videosList,
      page: v,
      reloadCounter: videosList.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setVideosList({
      ...videosList,
      rowsPerPage: v,
      page: 0,
      reloadCounter: videosList.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setVideosList({
      ...videosList,
      sortBy,
      sortOrder,
      reloadCounter: videosList.reloadCounter + 1,
      loading: true,
    });
  };

  const handleAssignVideo = () => {
    setVideosList({ ...videosList, loading: true });
    setSnackbarInfo({ ...snackbarInfo, show: false });

    if (selectedRows.length > 0) {
      const assignVideoToChannelPayload = {
        videos: selectedRows,
        isAllSelected: selectAllPages,
      };
      rulesetbaseAddVideoById(channelId, assignVideoToChannelPayload)
        .then(() => {
          setVideosList({
            ...videosList,
            reloadCounter: videosList.reloadCounter + 1,
          });
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: `Selected videos assigned to channel successfully.`,
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    }
  };

  useEffect(() => {
    setSelectAllPages(false);
    setSelectedRows([]);

    const params = [];
    params.push(`page=${videosList.page + 1}`);
    params.push(`perPage=${videosList.rowsPerPage}`);
    if (videosList.sortBy && videosList.sortOrder) {
      params.push(`sortBy=${videosList.sortBy}`);
      params.push(`sortOrder=${videosList.sortOrder}`);
    }
    if (activeTab === 'productionAssignment') {
      const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
      getListData(paramsQuery, {
        id: channelId,
        videoIds: [],
        from: 'channelVideoAssignment',
        action: 'view',
        filter: [],
      })
        .then((res) => {
          setVideosList({
            ...videosList,
            loading: false,
            totalRows: res.data.totalRows || 0,
            columns: LISTING_COLUMNS.filter((item) => item.dataKey === 'title'),
            rows: res.data.rows || [],
            page: 0,
            rowsPerPage: 25,
            error: false,
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    } else {
      const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
      getChannelAssignRulesetVideos(
        channelContentScheduleInfoId,
        channelId,
        paramsQuery
      )
        .then((res) => {
          setVideosList({
            ...videosList,
            loading: false,
            totalRows: res.data.totalRows || 0,
            columns: LISTING_COLUMNS.filter((item) => item.dataKey === 'title'),
            rows: res.data.rows || [],
            page: 0,
            rowsPerPage: 25,
            error: false,
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    }
  }, [videosList.reloadCounter]);

  return (
    <Box marginY={2}>
      <Typography variant="h6" py={1} px={2}>
        {title}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Box width="50%" display="flex" alignItems="center">
          <Tabs
            value={activeTab}
            aria-label="tabs"
            indicatorColor="primary"
            onChange={(_, newValue) => {
              handlePageChange(0);
              setActiveTab(newValue);
            }}
          >
            <Tab value="productionAssignment" label="Production Assignment" />
            <Tab value="testRun" label="Test Run" />
          </Tabs>
          {activeTab === 'testRun' && (
            <>
              <Checkbox
                sx={{
                  padding: 1,
                }}
                checked={selectAllPages}
                onChange={handleGlobalSelection}
                disabled={videosList.loading || !videosList.rows.length}
              />
              <Typography variant="body2" color="text.secondary">
                Select All{' '}
                {!videosList.loading ? `(${videosList.totalRows} videos)` : ''}
              </Typography>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            color="inherit"
            variant="contained"
            onClick={onClose}
            sx={{ mx: 1 }}
          >
            Cancel
          </Button>
          {activeTab === 'testRun' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAssignVideo()}
              disabled={!selectedRows.length && !videosList.loading}
            >
              Assign Video
            </Button>
          )}
        </Box>
      </Stack>

      <Card>
        <ListData
          columns={videosList.columns}
          rows={videosList.rows}
          page={videosList.page}
          rowsPerPage={videosList.rowsPerPage}
          totalRows={videosList.totalRows}
          loading={videosList.loading}
          actions={[]}
          error={videosList.error}
          sortBy={videosList.sortBy}
          sortOrder={videosList.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          from={activeTab === 'testRun' ? 'video-assignment' : ''}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectAllPages={selectAllPages}
        />
      </Card>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

ListExecutedRulesetsVideos.propTypes = {
  title: PropTypes.string.isRequired,
  channelContentScheduleInfoId: PropTypes.number.isRequired,
  channelId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ListExecutedRulesetsVideos;
