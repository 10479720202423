import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  TextField,
  Button,
  Typography,
  TableHead,
  Stack,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Card,
  Drawer,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import Channels from '../form-elements/channels.component';
import {
  getVideoDescriptionList,
  updateVideoDescription,
} from '../../services/videos.service';
import { getChannelListByGenreId } from '../../services/channel-genres.service';
import ChannelGenres from '../form-elements/channel-genres.component';
import Loading from '../table-elements/loading.component';
import ConfirmPopup from '../common/confirm-popup.component';
import useAccess from '../../hooks/access.hook';
import { FiltersContext } from '../../context/filter.context';
import FilterVideos from '../filter-videos/filter-video.component';

const ManageDescriptions = () => {
  const { control, handleSubmit, setValue, watch } = useForm({});

  const { hasOperationAccess } = useAccess();

  const { openFilter, handleToggleFilters } = useContext(FiltersContext);
  const [channelsDetail, setChannelsDetail] = useState({
    rows: [],
    selectedChannelGenre: { id: null, name: 'None' },
    selectedChannel: { id: null, title: 'None', code: null },
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    videoTotalRows: 0,
    videoRows: [],
    reloadCounter: 0,
    appliedFilters: {},
    loading: false,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const firstChannelIdRef = useRef(null);
  const selectedVideoIdRef = useRef(null);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      loading: true,
      reloadCounter: options.reloadCounter + 1,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      loading: true,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const fetchVideoData = (id, type) => {
    setOptions({ ...options, loading: true });

    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (id && type === 'channelGenreId') {
      params.push(`channelGenreId=${id}`);
    } else if (id && type === 'channelId') {
      params.push(`channelId=${id}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));
    getVideoDescriptionList(paramsQuery, { filter: filters })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          videoTotalRows: res?.data?.videoDetail?.totalRows || 0,
          videoRows: res?.data?.videoDetail?.rows || [],
        });
        Object.keys(res.data.videoDescriptions).forEach((item) => {
          setValue(item, res.data.videoDescriptions[item]);
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          videoTotalRows: 0,
          videoRows: [],
        });
      });
  };

  const handleChannelGenreChange = (v) => {
    if (v?.id) {
      getChannelListByGenreId(v.id)
        .then((res) => {
          setChannelsDetail({
            ...channelsDetail,
            rows: res.data,
            selectedChannelGenre: v || {},
            selectedChannel: { id: null, title: 'None', code: null },
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });

      fetchVideoData(v.id, 'channelGenreId');
    } else {
      setChannelsDetail({
        ...channelsDetail,
        rows: [],
        selectedChannelGenre: v || {},
        selectedChannel: { id: null, title: 'None', code: null },
      });
    }
  };

  const handleChannelChange = (v) => {
    setChannelsDetail({
      ...channelsDetail,
      rows: v?.id ? [v] : [],
      selectedChannel: v || {},
      selectedChannelGenre: { id: null, name: 'None' },
    });

    if (v?.id) fetchVideoData(v.id, 'channelId');
  };

  const handleConfirmData = () => {
    if (firstChannelIdRef.current) {
      setValue(`apply_for_all_${selectedVideoIdRef.current}`, true);
      const watchFirstValue = watch(
        `description_${selectedVideoIdRef.current}_${firstChannelIdRef.current}`
      );

      channelsDetail.rows?.forEach((c) => {
        setValue(
          `description_${selectedVideoIdRef.current}_${c.id}`,
          watchFirstValue
        );
      });
    }
    setShowConfirmDailog(false);
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({ ...snackbarInfo, show: false });

    const formattedData = Object.entries(data).map(([key, value]) => {
      if (key.includes('description')) {
        return {
          description: value,
          videoId: key?.split('_')?.[1] || null,
          channelId: key?.split('_')?.[2] || null,
        };
      }
      return null;
    });

    const payload = {
      descriptions: formattedData.filter((item) => item && item),
    };
    updateVideoDescription(payload)
      .then((res) => {
        if (res.data.success) {
          setFormSubmitted(false);
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Descriptions updated successfully.',
          });
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setFormSubmitted(false);

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    if (options.reloadCounter) {
      fetchVideoData(
        channelsDetail.selectedChannel.id ||
          channelsDetail.selectedChannelGenre.id,
        channelsDetail.selectedChannel.id ? 'channelId' : 'channelGenreId'
      );
    }
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0, py: 2 }}
      >
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <ChannelGenres
            id="channel_genres"
            name="channel_genres"
            label="Select Channel Genre"
            defaultValue={channelsDetail.selectedChannelGenre}
            onChange={(v) => handleChannelGenreChange(v)}
            sx={{ width: '100%' }}
          />
          OR
          <Channels
            id="channels"
            name="channels"
            label="Select Channel"
            defaultValue={channelsDetail.selectedChannel}
            onChange={(v) => handleChannelChange(v)}
            sx={{ width: '100%' }}
            showNoneOption
          />
        </Box>

        <Box>
          {hasOperationAccess('manage_descriptions', 'manage') && (
            <Button
              type="submit"
              form="add-edit-form"
              color="primary"
              variant="contained"
              disabled={
                (!channelsDetail.selectedChannel.id &&
                  !channelsDetail.selectedChannelGenre.id) ||
                (channelsDetail.selectedChannelGenre.id &&
                  !channelsDetail.rows.length) ||
                formSubmitted
              }
            >
              Save
            </Button>
          )}
        </Box>
      </Stack>

      <Card>
        <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          {options.videoTotalRows > 0 && channelsDetail.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.videoTotalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}

          <TableContainer>
            {options.loading && <Loading />}

            {!options.loading && !channelsDetail.rows.length && (
              <Table>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body2" sx={{ my: 20 }}>
                      {options.videoRows.length > 0 &&
                      channelsDetail.selectedChannelGenre.id
                        ? 'No assigned channel found for selected channel genre'
                        : 'Select channel genre or channel'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            )}

            {!options.loading && channelsDetail.rows.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">
                      <Typography variant="subtitle2">Video</Typography>
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle2">
                        Distributor Name
                      </Typography>
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle2">
                        PNM Description
                      </Typography>
                    </TableCell>
                    {channelsDetail.selectedChannelGenre.id && (
                      <TableCell width="10%" />
                    )}
                    <TableCell width="50%">
                      {channelsDetail.rows?.map((c) => (
                        <TableCell
                          key={`channel-${c.id}`}
                          sx={{ textAlign: 'center' }}
                        >
                          <Typography variant="subtitle2" minWidth="150px">
                            {c.title}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!options.videoRows.length && (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={channelsDetail.rows.length + 3}
                      >
                        <Typography variant="body2" sx={{ my: 20 }}>
                          No video Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {options.videoRows.length > 0 &&
                    options.videoRows.map((v) => {
                      const watchApplyForAll = watch(`apply_for_all_${v.id}`);
                      firstChannelIdRef.current = channelsDetail.rows?.[0]?.id;

                      return (
                        <TableRow key={`row-${v.id}`}>
                          <TableCell
                            width="10%"
                            sx={{ verticalAlign: 'center' }}
                          >
                            {v.title}
                          </TableCell>
                          <TableCell
                            width="15%"
                            sx={{
                              textAlign: 'center',
                              verticalAlign: 'center',
                            }}
                          >
                            {v.distributor_name || '-'}
                          </TableCell>
                          <TableCell
                            width="15%"
                            sx={{
                              textAlign: 'center',
                              verticalAlign: 'center',
                            }}
                          >
                            <TextField
                              type="text"
                              defaultValue={v.description}
                              multiline
                              minRows={4}
                              maxRows={4}
                              fullWidth
                              variant="filled"
                              disabled
                              sx={{ minWidth: '150px' }}
                            />
                          </TableCell>
                          {channelsDetail.selectedChannelGenre.id && (
                            <TableCell width="10%">
                              <Controller
                                name={`apply_for_all_${v.id}`}
                                control={control}
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={`apply_for_all_${v.id}`}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            selectedVideoIdRef.current = v.id;
                                            setShowConfirmDailog(true);
                                            return;
                                          }
                                          onChange(e.target.checked);
                                        }}
                                        checked={value}
                                      />
                                    }
                                    label="Apply for all"
                                    labelPlacement="bottom"
                                  />
                                )}
                              />
                            </TableCell>
                          )}
                          <TableCell width="50%">
                            {channelsDetail.rows?.map((c, index) => (
                              <TableCell
                                key={`description-${v.id}-${c.id}`}
                                sx={{
                                  border: 0,
                                  textAlign: 'center',
                                  verticalAlign: 'center',
                                }}
                              >
                                <Controller
                                  name={`description_${v.id}_${c.id}`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: { onChange, value } }) => (
                                    <TextField
                                      type="text"
                                      multiline
                                      minRows={4}
                                      maxRows={4}
                                      onChange={(e) => {
                                        onChange(e.target.value);
                                        if (watchApplyForAll) {
                                          channelsDetail.rows?.forEach((cd) => {
                                            setValue(
                                              `description_${v.id}_${cd.id}`,
                                              e.target.value
                                            );
                                          });
                                        }
                                      }}
                                      value={value}
                                      fullWidth
                                      variant="filled"
                                      disabled={index > 0 && watchApplyForAll}
                                      sx={{ minWidth: '150px' }}
                                    />
                                  )}
                                />
                              </TableCell>
                            ))}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {options.videoTotalRows > 0 && channelsDetail.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.videoTotalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}
        </form>
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterVideos
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {showConfirmDailog && (
        <ConfirmPopup
          title="Apply for all channels confirmation"
          message={`Do you want to apply ${channelsDetail.rows[0].title} description to all other channels?`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={() => handleConfirmData()}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ManageDescriptions;
