import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListContentOwners from '../../components/content-owner/list-content-owners.component';

const ContentOwnersView = () => {
  const pageTitle = 'Manage Distributors / Distributors';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListContentOwners />
    </AppLayout>
  );
};

export default ContentOwnersView;
