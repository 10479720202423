import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Stack, Box, Paper } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import Collections from '../form-elements/collections.component';
import Playlists from '../form-elements/playlists.component';
import Channels from '../form-elements/channels.component';
import Genres from '../form-elements/genres.component';
import RokuPrimaryGenres from '../form-elements/roku-primary-genres.component';

const VideoManageAssignments = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      collections: videoDetail.collectionId
        ? {
            id: videoDetail.collectionId,
            name: videoDetail.collectionName || '',
          }
        : null,
      playlists: videoDetail.playlists || [],
      channels: videoDetail.channels || [],
      genres: videoDetail.genres || [],
      primaryGenres: videoDetail.primaryGenres || [],
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      collections: data?.collections?.id || null,
      playlists: data?.playlists?.map((item) => item.id) || [],
      channels: data?.channels?.map((item) => item.id) || [],
      genres: data?.genres?.map((item) => item.id) || [],
      primaryGenres: data?.primaryGenres?.map((item) => item.id) || [],
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Assignments data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="video-assignments-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="collections"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Collections
                  id="collections"
                  name="collections"
                  label="Collections"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.collections?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="playlists"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Playlists
                  id="playlists"
                  name="playlists"
                  label="Playlists"
                  defaultValue={value}
                  multiple
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.playlists?.message || ''}
                  firstLevelPlaylist
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="channels"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Channels
                  id="channels"
                  name="channels"
                  label="Channels"
                  defaultValue={value}
                  multiple
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.channels?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="genres"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Genres
                  id="genres"
                  name="genres"
                  label="Genres"
                  defaultValue={value}
                  multiple
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.genres?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="primaryGenres"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RokuPrimaryGenres
                  id="primary_genres"
                  name="primaryGenres"
                  label="Primary Genres"
                  defaultValue={value}
                  multiple
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.primaryGenres?.message || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="video-assignments-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Assignments
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoManageAssignments.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoManageAssignments;
