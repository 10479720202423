import React from 'react';
import AppLayout from '../../layout/app.layout';
import ContentSchedule from '../../components/ruleset/content-schedule.component';
import { CONTENT_SCHEDULE_TYPE } from '../../config/const.config';

const HeroSchedulingContentView = () => {
  const pageTitle = 'Manage Channels / Hero Ruleset Content Schedule V2';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ContentSchedule type={CONTENT_SCHEDULE_TYPE.HERO} />
    </AppLayout>
  );
};

export default HeroSchedulingContentView;
