import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  styled,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  Stack,
} from '@mui/material';
import JsFileDownloader from 'js-file-download';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  downloadImportedCsvReport,
  getImportedCsvReportsData,
  importCsvFile,
} from '../../services/import-export.service';
import ImportExportDataList from '../form-elements/import-export-data-list.component';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import validateCsvFile from '../../utils/file-validations.util';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ListImport = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [incrementalImport, setIncrementalImport] = useState(false);
  const [reportsData, setReportsData] = useState({});
  const [downloadFile, setDownloadFile] = useState(null);
  const [options, setOptions] = useState({
    reloadCounter: 0,
    loading: true,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [processing, setProccessing] = useState(false);
  const selectFileInputRef = useRef(null);

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleImportFile = () => {
    setProccessing(true);
    setSnackbarInfo({ ...snackbarInfo, show: false });

    const validationError = validateCsvFile(selectedFile);
    if (validationError) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: validationError,
        });
      }, 50);
    } else {
      const importFilePayload = {
        file: selectedFile[0],
        incrementalImport: Number(incrementalImport),
      };
      importCsvFile(importFilePayload)
        .then((res) => {
          if (res.data.success) {
            setProccessing(false);
            setSnackbarInfo({
              ...snackbarInfo,
              show: true,
              type: 'success',
              message: 'File imported successfully.',
            });
            setSelectedFile(null);
            setIncrementalImport(false);
          }
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          setProccessing(false);

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });
    }
  };

  const handleDownloadFile = () => {
    setProccessing(true);
    downloadImportedCsvReport(downloadFile.id)
      .then((res) => {
        setProccessing(false);
        // eslint-disable-next-line no-unused-vars
        const download = new JsFileDownloader(
          res,
          `${downloadFile.downloadFileName}`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setProccessing(false);
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  useEffect(() => {
    getImportedCsvReportsData()
      .then((res) => {
        setReportsData(res.data);
        setDownloadFile(res.data?.importedData?.[0] || null);
        setOptions({ ...options, loading: false });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setOptions({ ...options, loading: false });
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 0, py: 2 }}>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      {options.loading && <Loading />}

      {!options.loading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardHeader
                title="Import Metadata CSV"
                subheader="Imported CSV will be loaded into the database and will be accessible thorugh bulk-editor. Importer will do the following:"
              />

              <Box paddingX={5} paddingBottom={3} paddingTop={1}>
                <Typography variant="body2">
                  Create series/distributors/playlist objects
                </Typography>
                <Typography variant="body2">
                  NOT Check for valid video/poster/subtitle content
                </Typography>
                <Typography variant="body2">
                  NOT crawl IMDB automatically
                </Typography>
              </Box>

              <Divider />

              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Incremental import
                </Typography>
                <Typography variant="body2" color="#637381">
                  Incremental imports allow for bulk importing a small subset of
                  videos. CSV format is same as with regular import, but new
                  videos will be distinguished by blank UUID field.
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={incrementalImport}
                      onChange={() => setIncrementalImport(!incrementalImport)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Incremental Import"
                />
              </CardContent>

              <Divider />

              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  border="1px solid #ced4da"
                  borderRadius="6px"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    selectFileInputRef.current &&
                    selectFileInputRef.current.click()
                  }
                  onChange={(e) => setSelectedFile(e.target.files)}
                >
                  <Typography variant="body2" color="#637381" paddingLeft={2}>
                    {selectedFile?.[0]?.name || 'Choose CSV File'}
                  </Typography>

                  <Button
                    component="label"
                    variant="contained"
                    color="secondary"
                  >
                    Browse
                  </Button>
                  <VisuallyHiddenInput type="file" ref={selectFileInputRef} />
                </Box>

                <Button
                  component="label"
                  variant="contained"
                  color="primary"
                  disabled={!selectedFile || processing}
                  onClick={() => handleImportFile()}
                >
                  Import
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Card>
              <CardHeader
                title="Import Metadata Reports"
                sx={{ paddingBottom: 3 }}
              />

              <Divider />

              <CardContent>
                <Typography variant="subtitle1" color="#637381" paddingLeft={2}>
                  {`Total Videos - ${reportsData.totalRows}`}
                </Typography>
                <Typography variant="body1" color="#637381" paddingLeft={2}>
                  {`Imported Videos - ${reportsData.succeedRows}`}
                </Typography>
                <Typography variant="body1" color="#637381" paddingLeft={2}>
                  {`Failed Importing - ${reportsData.failedRows}`}
                </Typography>

                <Box display="flex" alignItems="flex-end" gap={2}>
                  <ImportExportDataList
                    id="import_report"
                    name="import_report"
                    defaultValue={downloadFile}
                    onChange={(e) => setDownloadFile(e.target.value)}
                    validOptions={reportsData?.importedData || []}
                    sx={{ width: '100%' }}
                  />

                  <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    disabled={!reportsData?.importedData?.length || processing}
                    onClick={() => handleDownloadFile()}
                  >
                    Download
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListImport;
