import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Player from '../../components/common/player.component';
import { viewDataById } from '../../services/videos.service';
import SnackbarInfo from '../../components/common/snackbar-info.component';

const VideoPreviewView = () => {
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState('');
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    viewDataById(id)
      .then((res) => {
        setVideoUrl(res.data.videoUrl);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  }, []);

  return (
    <>
      <Player videoUrl={videoUrl} height="100%" width="100%" autoplay />

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default VideoPreviewView;
