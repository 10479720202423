import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListManageRules from '../../components/ruleset/list-manage-rules.component';

const ManageRules = () => {
  const pageTitle = 'Manage Channels / Manage Rules';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListManageRules />
    </AppLayout>
  );
};

export default ManageRules;
