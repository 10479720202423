import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  FormControlLabel,
  Switch,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import MpaaRating from '../form-elements/mpaa-rating.component';
import RestrictedContent from '../form-elements/restricted-content.component';
import RestrictedContentReason from '../form-elements/restricted-content-reason.component';
import ExcludeFromDBReason from '../form-elements/exclude-db-reason.component';
import AdTimingStatus from '../form-elements/ad-timing-status.component';
import GenreStatus from '../form-elements/genre-status.component';
import Datepicker from '../form-elements/datepicker.component';

const VideoStatus = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      offline: videoDetail.offline === 'YES',
      offlineReason: videoDetail.offlineReason || '',
      mpaaRating: videoDetail.mpaaRating || 'NR',
      dateLicenseExpiration: videoDetail.dateLicenseExpiration || '',
      restrictedContent: videoDetail.restrictedContent || 'NC',
      restrictedContentReason: videoDetail.restrictedContentReason || 'NA',
      excludeFromDB: videoDetail.excludeFromDB === 'YES',
      excludeFromDBReason: videoDetail.excludeFromDBReason || 'null',
      adTimingStatus: videoDetail.adTimingStatus || 'NC',
      genreStatus: videoDetail.genreStatus || 'NC',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      dateLicenseExpiration: data.dateLicenseExpiration
        ? dayjs(data.dateLicenseExpiration).format('YYYY-MM-DD')
        : '',
      offline: data.offline ? 'YES' : 'NO',
      excludeFromDB: data.excludeFromDB ? 'YES' : 'NO',
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Status data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="video-status-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="offline"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Offline"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="offlineReason"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Offline Reason"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.offlineReason}
                  helperText={errors?.offlineReason?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="mpaaRating"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MpaaRating
                  id="mpaa_rating"
                  name="mpaa_rating"
                  label="Mpaa Rating"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.mpaaRating?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="dateLicenseExpiration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  id="fl_start_date"
                  name="fl_start_date"
                  label="Date License Expiration"
                  defaultValue={value}
                  clearable
                  onChange={onChange}
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="restrictedContent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RestrictedContent
                  id="restricted_content"
                  name="restricted_content"
                  label="Restricted Content"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.restrictedContent?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="restrictedContentReason"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RestrictedContentReason
                  id="restricted_content_reason"
                  name="restricted_content_reason"
                  label="Restricted Content Reason"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.restrictedContentReason?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="excludeFromDB"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Exclude From DB"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="excludeFromDBReason"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ExcludeFromDBReason
                  id="exclude_from_db_reason"
                  name="exclude_from_db_reason"
                  label="Exclude From DB Reason"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.excludeFromDBReason?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="adTimingStatus"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AdTimingStatus
                  id="ad_timing_status"
                  name="ad_timing_status"
                  label="Ad Timing Status"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.adTimingStatus?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="genreStatus"
              control={control}
              render={({ field: { onChange, value } }) => (
                <GenreStatus
                  id="genre_status"
                  name="genre_status"
                  label="Genre Status"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.genreStatus?.message || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="video-status-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Status
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoStatus.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoStatus;
