import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import RestrictedContentReason from '../form-elements/restricted-content-reason.component';
import RestrictedContent from '../form-elements/restricted-content.component';
import Countries from '../form-elements/countries.component';
import Trashy from '../form-elements/trashy.component';
import GoodBad from '../form-elements/good-bad.component';
import AdTimingStatus from '../form-elements/ad-timing-status.component';
import PublicDomain from '../form-elements/public-domain.component';
import Poster16x9Status from '../form-elements/poster-16x9-status.component';
import Genres from '../form-elements/genres.component';
import TimeInput from '../form-elements/time-input.component';
import Iconify from '../common/iconify.component';
import Datepicker from '../form-elements/datepicker.component';
import GenerateAdTimingsModal from '../content/generate-ad-timings.modal.component';

const EditableCellValue = ({ type, dataKey, value, onChange }) => {
  if (type === 'textarea') {
    return (
      <TextField
        type="text"
        multiline
        minRows={3}
        maxRows={3}
        value={value}
        onChange={onChange}
        fullWidth
        variant="filled"
      />
    );
  }
  if (type === 'switch') {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
            color="primary"
          />
        }
      />
    );
  }
  if (type === 'date') {
    return (
      <Datepicker
        id="date"
        name="date"
        defaultValue={value}
        clearable
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (
    type === 'selection' &&
    (dataKey === 'restricted_content' || dataKey === 'avod_validated')
  ) {
    return (
      <RestrictedContent
        id="restricted_content"
        name="restricted_content"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'restricted_content_reason') {
    return (
      <RestrictedContentReason
        id="restricted_content_reason"
        name="restricted_content_reason"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'good_bad') {
    return (
      <GoodBad
        id="good_bad"
        name="good_bad"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'trashy') {
    return (
      <Trashy
        id="trashy"
        name="trashy"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'country') {
    return (
      <Countries
        id="country"
        name="country"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'ad_timing_status') {
    return (
      <AdTimingStatus
        id="ad_timing_status"
        name="ad_timing_status"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'public_domain') {
    return (
      <PublicDomain
        id="public_domain"
        name="public_domain"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'poster_16x9_status') {
    return (
      <Poster16x9Status
        id="poster_16x9_status"
        name="poster_16x9_status"
        defaultValue={value}
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'selection' && dataKey === 'genres') {
    return (
      <Genres
        id="genres"
        name="genres"
        defaultValue={typeof value === 'string' ? JSON.parse(value) : value}
        multiple
        onChange={onChange}
        sx={{ width: '100%' }}
      />
    );
  }
  if (type === 'timing' && dataKey === 'ad_timing') {
    const videoDuration = 240;
    const [inputValue, setInputValue] = useState('');
    const [adTimings, setAdTimings] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
      if (typeof value === 'string' && value.length > 0) {
        setAdTimings(value.split(',').map((time) => time.trim()));
      } else {
        setAdTimings([]);
      }
    }, [value]);

    const handleAddTiming = () => {
      if (!inputValue) return;

      const updatedTimings = [...adTimings, inputValue]
        .map((time) => time.trim())
        .filter((time) => time)
        .sort((a, b) =>
          dayjs(a, 'HH:mm:ss').isBefore(dayjs(b, 'HH:mm:ss')) ? -1 : 1
        );

      setAdTimings(updatedTimings);
      setInputValue('');

      onChange({
        target: { value: updatedTimings.join(',') },
      });
    };

    const handleRemoveTiming = (index) => {
      const updatedTimings = adTimings.filter((_, i) => i !== index);
      setAdTimings(updatedTimings);
      onChange({
        target: { value: updatedTimings.join(',') },
      });
    };

    const handleSaveGeneratedTimings = (timings) => {
      setAdTimings(timings);
      onChange({
        target: { value: timings.join(',') },
      });
      setIsDialogOpen(false);
    };

    return (
      <>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          spacing={2}
          style={{
            width: '200px',
            maxWidth: '200px',
          }}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Button
              onClick={() => setIsDialogOpen(true)}
              color="secondary"
              variant="contained"
              maxWidth="sm"
            >
              Generate Ad Timings
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TimeInput value={inputValue} onChange={(e) => setInputValue(e)} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            <IconButton onClick={handleAddTiming}>
              <Iconify icon="lets-icons:add-duotone" width={30} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
          >
            {adTimings.map((time, index) => (
              <Chip
                key={index}
                label={time}
                onDelete={() => handleRemoveTiming(index)}
                style={{ margin: '4px' }}
                variant="outlined"
              />
            ))}
          </Grid>
        </Grid>

        <GenerateAdTimingsModal
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          durationInMinutes={videoDuration}
          onSave={handleSaveGeneratedTimings}
        />
      </>
    );
  }

  return (
    <TextField
      type={type}
      value={value}
      onChange={onChange}
      fullWidth
      variant="filled"
    />
  );
};

EditableCellValue.propTypes = {
  type: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default EditableCellValue;
