import React, { useContext, useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Button,
  Typography,
  TableHead,
  Stack,
  Box,
  TablePagination,
  Card,
  Drawer,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import SnackbarInfo from '../common/snackbar-info.component';
import Channels from '../form-elements/channels.component';
import {
  channelVideoSchedule,
  getChannelVideoScheduleList,
  updateVideoSchedule,
} from '../../services/videos.service';
import { getChannelListByGenreId } from '../../services/channel-genres.service';
import ChannelGenres from '../form-elements/channel-genres.component';
import Loading from '../table-elements/loading.component';
import { FiltersContext } from '../../context/filter.context';
import FilterVideos from '../filter-videos/filter-video.component';
import Datepicker from '../form-elements/datepicker.component';
import ConfirmPopup from '../common/confirm-popup.component';
import Iconify from '../common/iconify.component';
import { formatDate } from '../../utils/datetime.util';
import useAccess from '../../hooks/access.hook';

const ChannelAssignment = () => {
  const { hasOperationAccess } = useAccess();

  const { control, setValue, getValues } = useForm({});

  const { openFilter, handleToggleFilters } = useContext(FiltersContext);
  const [channelsDetail, setChannelsDetail] = useState({
    rows: [],
    selectedChannelGenre: { id: null, name: 'None' },
    selectedChannel: { id: null, title: 'None', code: null },
  });
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    videoTotalRows: 0,
    videoRows: [],
    reloadCounter: 0,
    appliedFilters: {},
    loading: false,
    scheduleData: {},
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedAction, setSelectedAction] = useState({
    action: null,
    selectedVideo: null,
    selectedChannel: null,
    selectedVideoId: null,
    selectedChannelId: null,
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      loading: true,
      reloadCounter: options.reloadCounter + 1,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      loading: true,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {},
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const fetchVideoData = (id, type) => {
    setOptions({ ...options, loading: true });

    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (id && type === 'channelGenreId') {
      params.push(`channelGenreId=${id}`);
    } else if (id && type === 'channelId') {
      params.push(`channelId=${id}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));
    getChannelVideoScheduleList(paramsQuery, { filter: filters })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          videoTotalRows: res?.data?.videoDetail?.totalRows || 0,
          videoRows: res?.data?.videoDetail?.rows || [],
          scheduleData: res?.data?.channelVideoSchedules || {},
        });
        Object.keys(res.data.channelVideoSchedules).forEach((item) => {
          const fullDate = res.data.channelVideoSchedules[item];

          setValue(item, fullDate);
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          videoTotalRows: 0,
          videoRows: [],
        });
      });
  };

  const handleChannelGenreChange = (v) => {
    if (v?.id) {
      getChannelListByGenreId(v.id)
        .then((res) => {
          setChannelsDetail({
            ...channelsDetail,
            rows: res.data,
            selectedChannelGenre: v || {},
            selectedChannel: { id: null, title: 'None', code: null },
          });
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
        });

      fetchVideoData(v.id, 'channelGenreId');
    } else {
      setChannelsDetail({
        ...channelsDetail,
        rows: [],
        selectedChannelGenre: v || {},
        selectedChannel: { id: null, title: 'None', code: null },
      });
    }
  };

  const handleChannelChange = (v) => {
    setChannelsDetail({
      ...channelsDetail,
      rows: v?.id ? [v] : [],
      selectedChannel: v || {},
      selectedChannelGenre: { id: null, name: 'None' },
    });

    if (v?.id) fetchVideoData(v.id, 'channelId');
  };

  const handleSaveData = (changedKey, changedValue) => {
    const data = getValues();
    const parts = changedKey.split('_');

    if (parts.length !== 3) return;

    const type = parts[0];
    const videoId = parts[1];
    const channelId = parts[2];

    if (
      changedValue &&
      (dayjs(changedValue).year().toString().length !== 4 ||
        !dayjs(changedValue).isValid())
    ) {
      return;
    }

    const formattedDate = changedValue
      ? dayjs(changedValue).format('YYYY-MM-DD')
      : null;

    const otherType = type === 'from' ? 'to' : 'from';
    const otherDateKey = `${otherType}_${videoId}_${channelId}`;
    const otherDateValue =
      data[otherDateKey] && dayjs(data[otherDateKey]).isValid()
        ? dayjs(data[otherDateKey]).format('YYYY-MM-DD')
        : null;

    const updatedData = {
      scheduleData: [
        {
          videoId,
          channelId,
          from: type === 'from' ? formattedDate : otherDateValue,
          to: type === 'to' ? formattedDate : otherDateValue,
        },
      ],
    };

    updateVideoSchedule(updatedData)
      .then(() => {})
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  const handleActionCancel = () => {
    setSelectedAction({
      action: null,
      selectedVideo: null,
      selectedChannel: null,
      selectedVideoId: null,
      selectedChannelId: null,
    });
  };

  const handleAddRemoveVideo = () => {
    channelVideoSchedule({
      videoId: selectedAction.selectedVideoId,
      channelId: selectedAction.selectedChannelId,
      action: selectedAction.action,
    })
      .then((res) => {
        if (res.data.success) {
          setOptions({
            ...options,
            scheduleData: {
              ...options.scheduleData,
              [`${selectedAction.selectedVideoId}_${selectedAction.selectedChannelId}`]:
                selectedAction.action === 'add',
            },
          });
          handleActionCancel();
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Video added successfully.',
          });
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    if (options.reloadCounter) {
      fetchVideoData(
        channelsDetail.selectedChannel.id ||
          channelsDetail.selectedChannelGenre.id,
        channelsDetail.selectedChannel.id ? 'channelId' : 'channelGenreId'
      );
    }
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 0, py: 2 }}
      >
        <Box display="flex" alignItems="center" width="50%" gap={1}>
          <ChannelGenres
            id="channel_genres"
            name="channel_genres"
            label="Select Channel Genre"
            defaultValue={channelsDetail.selectedChannelGenre}
            onChange={(v) => handleChannelGenreChange(v)}
            sx={{ width: '100%' }}
          />
          OR
          <Channels
            id="channels"
            name="channels"
            label="Select Channel"
            defaultValue={channelsDetail.selectedChannel}
            onChange={(v) => handleChannelChange(v)}
            sx={{ width: '100%' }}
            showNoneOption
          />
        </Box>
        <Box>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      <Card>
        <form id="add-edit-form">
          {options.videoTotalRows > 0 && channelsDetail.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.videoTotalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}

          <TableContainer>
            {options.loading && <Loading />}

            {!options.loading && !channelsDetail.rows.length && (
              <Table>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body2" sx={{ my: 20 }}>
                      {options.videoRows.length > 0 &&
                      channelsDetail.selectedChannelGenre.id
                        ? 'No assigned channel found for selected channel genre'
                        : 'Select channel genre or channel'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            )}

            {!options.loading && channelsDetail.rows.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">
                      <Typography variant="subtitle2">Date Added</Typography>
                    </TableCell>
                    <TableCell width="10%">
                      <Typography variant="subtitle2">Video</Typography>
                    </TableCell>
                    <TableCell width="10%">
                      <Typography variant="subtitle2">Series</Typography>
                    </TableCell>
                    <TableCell width="10%">
                      <Typography variant="subtitle2">S#E#</Typography>
                    </TableCell>
                    <TableCell width="15%" sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle2">Distributor</Typography>
                    </TableCell>
                    <TableCell width="50%">
                      {channelsDetail.rows?.map((c) => (
                        <TableCell
                          key={`channel-${c.id}`}
                          sx={{ textAlign: 'center' }}
                        >
                          <Typography variant="subtitle2" minWidth="200px">
                            {c.title}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!options.videoRows.length && (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={channelsDetail.rows.length + 3}
                      >
                        <Typography variant="body2" sx={{ my: 20 }}>
                          No video Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {options.videoRows.length > 0 &&
                    options.videoRows.map((v) => (
                      <TableRow key={`row-${v.id}`}>
                        <TableCell width="10%" sx={{ verticalAlign: 'center' }}>
                          <Typography variant="body2" component="span">
                            {formatDate(v.dateCreation || null, 'll')}
                          </Typography>
                        </TableCell>
                        <TableCell width="10%" sx={{ verticalAlign: 'center' }}>
                          {v.title}
                        </TableCell>
                        <TableCell width="10%" sx={{ verticalAlign: 'center' }}>
                          {v.series_name}
                        </TableCell>
                        <TableCell width="10%" sx={{ verticalAlign: 'center' }}>
                          {v.season && v.episodeNum
                            ? `S${v.season}E${v.episodeNum}`
                            : ''}
                        </TableCell>
                        <TableCell
                          width="15%"
                          sx={{
                            textAlign: 'center',
                            verticalAlign: 'center',
                          }}
                        >
                          {v.distributor_name || '-'}
                        </TableCell>
                        <TableCell width="50%">
                          {hasOperationAccess('channel_assignment', 'manage') &&
                            channelsDetail.rows?.map((c) => (
                              <TableCell
                                key={`${v.id}-${c.id}`}
                                sx={{
                                  border: 0,
                                  textAlign: 'center',
                                  verticalAlign: 'center',
                                }}
                              >
                                {options.scheduleData[`${v.id}_${c.id}`] ? (
                                  <>
                                    <Controller
                                      name={`from_${v.id}_${c.id}`}
                                      control={control}
                                      defaultValue=""
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Datepicker
                                          id="fl_start_date"
                                          name="fl_start_date"
                                          label="From Date"
                                          defaultValue={value}
                                          clearable
                                          onChange={(date) => {
                                            handleSaveData(
                                              `from_${v.id}_${c.id}`,
                                              date
                                            );
                                            onChange(date);
                                          }}
                                          sx={{ width: '200px' }}
                                        />
                                      )}
                                    />
                                    <Controller
                                      name={`to_${v.id}_${c.id}`}
                                      control={control}
                                      defaultValue=""
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Datepicker
                                          id="fl_start_date"
                                          name="fl_start_date"
                                          label="From Date"
                                          defaultValue={value}
                                          clearable
                                          onChange={(date) => {
                                            handleSaveData(
                                              `to_${v.id}_${c.id}`,
                                              date
                                            );
                                            onChange(date);
                                          }}
                                          sx={{ width: '200px' }}
                                        />
                                      )}
                                    />
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="small"
                                      sx={{
                                        minWidth: 24,
                                        padding: '2px 6px',
                                        fontSize: '0.7rem',
                                        width: 'fit-content',
                                        marginTop: 1,
                                      }}
                                      onClick={() =>
                                        setSelectedAction({
                                          action: 'remove',
                                          selectedVideo: v.title,
                                          selectedChannel: c.title,
                                          selectedVideoId: v.id,
                                          selectedChannelId: c.id,
                                        })
                                      }
                                    >
                                      Remove
                                    </Button>
                                  </>
                                ) : (
                                  <Box sx={{ minWidth: '200px' }}>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      sx={{
                                        minWidth: 24,
                                        padding: '2px 6px',
                                        fontSize: '0.7rem',
                                        width: 'fit-content',
                                      }}
                                      onClick={() =>
                                        setSelectedAction({
                                          action: 'add',
                                          selectedVideo: v.title,
                                          selectedChannel: c.title,
                                          selectedVideoId: v.id,
                                          selectedChannelId: c.id,
                                        })
                                      }
                                    >
                                      Add
                                    </Button>
                                  </Box>
                                )}
                              </TableCell>
                            ))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          {options.videoTotalRows > 0 && channelsDetail.rows.length > 0 && (
            <TablePagination
              component="div"
              count={options.videoTotalRows}
              page={options.page}
              onPageChange={(e, newPage) => handlePageChange(newPage)}
              rowsPerPage={options.rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(Number(e.target.value));
              }}
            />
          )}
        </form>
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterVideos
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {(selectedAction.action === 'add' ||
        selectedAction.action === 'remove') && (
        <ConfirmPopup
          title={selectedAction.action === 'add' ? 'Add Video' : 'Remove Video'}
          message={
            selectedAction.action === 'add'
              ? `Are you sure you want to add ${selectedAction.selectedVideo} video for ${selectedAction.selectedChannel} channel?`
              : `Are you sure you want to remove ${selectedAction.selectedVideo} video for ${selectedAction.selectedChannel} channel?`
          }
          onCancel={handleActionCancel}
          onSuccess={handleAddRemoveVideo}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ChannelAssignment;
