import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/distributor.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewUser from './view-content-owner.component';
import AddEditUser from './add-edit-content-owner.component';
import ChangePassword from './change-password.component';
import FilterUsers from './filter-content-owners.component';
import {
  getListData,
  deleteDataById,
  loginAsAdminUser,
  addVideosById,
  removeVideosById,
} from '../../services/distributor.service';
import CreateAdminUser from './create-admin-user.component';
import { getDeviceModel, getDeviceOs } from '../../utils/device-info.util';
import VideoAssignment from '../content/video-assignment.component';
import useFilteredActions from '../../hooks/filtered-action.hook';
import { FiltersContext } from '../../context/filter.context';

const ListContentOwners = () => {
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions('distributors', LISTING_ACTIONS);

  const navigate = useNavigate();
  const { openFilter, handleToggleFilters } = useContext(FiltersContext);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: { name: 'Search', value: '' },
      type: { name: 'Type', value: null },
      status: { name: 'Status', value: null },
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    if (selectedAction === ACTIONS.LOGIN_AS_THIS_USER.value) {
      const autoLoginPayload = {
        id: selectedData.id,
        deviceDetails: {
          os: getDeviceOs(),
          model: getDeviceModel(),
        },
      };
      loginAsAdminUser(autoLoginPayload)
        .then((res) => {
          const url = `/auto-login?auth=${res?.data.token || ''}`;
          navigate(url);
        })
        .catch(() => {});
      return;
    }
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('Distributor deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search?.value.trim()) counter += 1;
    if (selectedFilters.type?.value) counter += 1;
    if (selectedFilters.status?.value !== null) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {
        search: { name: 'Search', value: '' },
        type: { name: 'Type', value: null },
        status: { name: 'Status', value: null },
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleVideoAdd = (data) =>
    addVideosById(doAction.data.id, data)
      .then((response) => response)
      .catch((error) => Promise.reject(error));

  const handleVideoRemove = (data) =>
    removeVideosById(doAction.data.id, data)
      .then((response) => response)
      .catch((error) => Promise.reject(error));

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }
    const updatedFilters = { ...options.appliedFilters };
    updatedFilters[field] = {
      ...updatedFilters[field],
      value: Array.isArray(updatedFilters[field]?.value) ? [] : null || '',
    };

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = Object.keys(options.appliedFilters).reduce((acc, key) => {
      acc[key] = options.appliedFilters[key].value;
      return acc;
    }, {});
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.type) {
      params.push(`type=${filters.type}`);
    }
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 0, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
        </div>
        <div>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      {options.appliedFilters &&
        Object.values(options.appliedFilters).some(
          (filter) =>
            (Array.isArray(filter.value) && filter.value.length > 0) ||
            (!Array.isArray(filter.value) && filter.value)
        ) && (
          <Box mb={2}>
            <Typography variant="body1" fontWeight="bold" display="inline">
              Filters Applied:{' '}
              <Chip
                key="all"
                label="Clear all"
                onDelete={() => handleDeleteFilter('clear-all')}
                sx={{ ml: 1, color: 'red' }}
              />
            </Typography>
            {Object.entries(options.appliedFilters)
              .filter(
                ([, filter]) =>
                  (Array.isArray(filter.value) && filter.value.length > 0) ||
                  (!Array.isArray(filter.value) && filter.value)
              )
              .map(([field, filter]) => (
                <Chip
                  key={field}
                  label={filter.name || 'Active'}
                  onDelete={() => handleDeleteFilter(field)}
                  sx={{ ml: 1 }}
                />
              ))}
          </Box>
        )}

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterUsers
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditUser
          title="Create New Distributor"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditUser
          title={`Edit Distributor Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewUser
          title={`Distributor Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
      {doAction.action === ACTIONS.ENABLE_LOGIN.value && (
        <CreateAdminUser
          title={`Enable Login for - ${doAction.data.username}`}
          defaultData={doAction.data}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Distributor"
          message={`Do you want to delete ${doAction.data.email} distributor? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.CHANGE_PASSWORD.value && (
        <ChangePassword
          title={`Change Password For Distributor - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_VIDEOS.value && (
        <VideoAssignment
          dataId={doAction.data.id}
          title={`Manage Distributor Videos - ${doAction.data.name}`}
          onCancel={handleActionCancel}
          onAddData={(data) => handleVideoAdd(data)}
          onRemoveData={(data) => handleVideoRemove(data)}
          from="distributorVideoAssignment"
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ListContentOwners;
