import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListGenres from '../../components/content/list-genres.component';

const GenresView = () => {
  const pageTitle = 'Organize Content / Genres';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListGenres />
    </AppLayout>
  );
};

export default GenresView;
