import React from 'react';
import AppLayout from '../../layout/app.layout';
import ContentAssign from '../../components/ruleset/assign-content.component';

const AssignContentView = () => {
  const pageTitle = 'Manage Channels / Assign Content';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ContentAssign />
    </AppLayout>
  );
};

export default AssignContentView;
