/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Stack,
  TableBody,
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';
import Loading from '../table-elements/loading.component';
import {
  getRulesetScheduleData,
  manageChannelScheduleContentInfo,
} from '../../services/ruleset.service';
import Sortable from '../table-elements/sortable.component';
import Error from '../table-elements/error.component';
import Channels from '../form-elements/channels.component';
import AddEditScheduleRulesetRules from './add-edit-schedule-ruleset-rules.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { formatDate } from '../../utils/datetime.util';
import Iconify from '../common/iconify.component';
import useAccess from '../../hooks/access.hook';
import EditScheduleInfo from './edit-schedule-info.component';

const ContentSchedule = ({ type }) => {
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { hasOperationAccess } = useAccess();

  const [options, setOptions] = useState({
    columns: [],
    rows: [],
    info: {},
    error: false,
    loading: false,
    reloadCounter: 0,
  });
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedActionData, setSelectedActionData] = useState({
    id: null,
    data: {},
    open: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [showScheduleInfoDialog, setShowScheduleInfoDialog] = useState(false);

  const handleRefreshData = () => {
    setSelectedActionData({ open: false, id: null, data: {} });
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const manageScheduleInfoData = (payload, fromDefault = false) => {
    manageChannelScheduleContentInfo(selectedChannel?.id, payload)
      .then(() => {
        if (!fromDefault) {
          setSnackbarInfo({
            show: true,
            type: 'success',
            message: 'Data updated successfully.',
          });
          setOptions({
            ...options,
            info: {
              ...options.info,
              targetMovies: payload.targetMovies,
              targetEpisodes: payload.targetEpisodes,
            },
          });
          setFormSubmitted(false);
          setShowScheduleInfoDialog(false);
        } else {
          handleRefreshData();
        }
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          columns: [],
          rows: [],
          info: {},
          error: true,
        });
      });
  };

  useEffect(() => {
    if (selectedChannel?.id) {
      setOptions({
        ...options,
        loading: true,
      });
      getRulesetScheduleData(selectedChannel?.id, type)
        .then((res) => {
          if (!res.data?.info?.id) {
            const payload = {
              targetMovies: 0,
              targetEpisodes: 0,
              type,
            };
            manageScheduleInfoData(payload, true);
          } else {
            const columns = [
              {
                id: 'title',
                label: '',
                align: 'left',
                dataKey: 'rulesetName',
                width: '250px',
              },
            ];
            res?.data?.columns?.forEach((item) => {
              columns.push({
                id: item.date,
                label: `${formatDate(item.date, 'MM/DD/YY')} ${
                  item.date?.includes(':')
                    ? new Date(
                        `${item.date.replace(' ', 'T')}Z`
                      ).toLocaleTimeString()
                    : ''
                }`,
                align: 'center',
                dataKey: item.date,
              });
            });
            setOptions({
              ...options,
              loading: false,
              columns,
              rows: res?.data?.rows || [],
              info: res.data.info,
              error: false,
            });
          }
        })
        .catch(() => {
          setOptions({
            ...options,
            loading: false,
            columns: [],
            rows: [],
            error: true,
          });
        });
    } else {
      setOptions({
        ...options,
        columns: [],
        rows: [],
        info: {},
        error: false,
        loading: false,
      });
    }
  }, [options.reloadCounter]);

  const handleSaveScheduleInfo = (data) => {
    setFormSubmitted(true);
    const payload = {
      targetMovies: data.targetMovies,
      targetEpisodes: data.targetEpisodes,
      type,
    };
    manageScheduleInfoData(payload);
  };

  const showSnackbarMessage = (message = '', messageType = 'success') => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: messageType,
          message,
        });
      }, 50);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box width="25%">
            <Channels
              id="channel"
              name="channel"
              label="Select Channel"
              defaultValue={selectedChannel}
              onChange={(c) => {
                setSelectedChannel(c);
                handleRefreshData();
              }}
              disabled={options.loading}
              sx={{ width: '100%' }}
            />
          </Box>
          {selectedChannel?.id && (
            <>
              <Box>
                <Typography variant="body2" mb={1}>
                  <strong>Total Videos:</strong>{' '}
                  {options.info.totalMovies ||
                    0 + options.info.totalEpisodes ||
                    0}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total Movies:</strong> {options.info.totalMovies || 0}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Total Episodes:</strong>{' '}
                  {options.info.totalEpisodes || 0}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" mb={1}>
                  <strong>Always Scheduled:</strong>{' '}
                  {options.info.alreadyScheduled || 0}
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Target #Movies:</strong>{' '}
                  {options.info.targetMovies || 0}
                  <IconButton
                    aria-label="edit"
                    onClick={() => setShowScheduleInfoDialog(true)}
                    size="small"
                    disabled={options.loading || formSubmitted}
                    sx={{ py: 0, pl: 1.5 }}
                  >
                    <Iconify icon="bx:edit" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" mb={1}>
                  <strong>Target #Episodes:</strong>{' '}
                  {options.info.targetEpisodes || 0}
                  <IconButton
                    aria-label="edit"
                    onClick={() => setShowScheduleInfoDialog(true)}
                    size="small"
                    disabled={options.loading || formSubmitted}
                    sx={{ py: 0, pl: 1.5 }}
                  >
                    <Iconify icon="bx:edit" />
                  </IconButton>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Stack>
      {options.loading && <Loading />}

      {!options.loading && !selectedChannel?.id && (
        <Table>
          <TableRow>
            <TableCell align="center">
              <Typography variant="body2" sx={{ my: 20 }}>
                Select channel to manage schedule
              </Typography>
            </TableCell>
          </TableRow>
        </Table>
      )}

      {!options.loading && selectedChannel?.id && (
        <TableContainer>
          <Table>
            {!options.error && options.rows.length > 0 && (
              <TableHead>
                <TableRow>
                  {options.columns.map((c, cindex) => (
                    <TableCell
                      key={`col-${c.id}`}
                      align={c.align}
                      width={c.width}
                      sx={{
                        cursor: c.sortable ? 'pointer' : 'inherit',
                        padding: '16px',
                        fontSize: '0.875rem !important',
                        position: cindex === 0 && 'sticky',
                        left: cindex === 0 && 0,
                        minWidth: cindex === 0 && c.width,
                        maxWidth: cindex === 0 && c.width,
                      }}
                    >
                      <Stack direction="row" justifyContent={c.align}>
                        {c.sortable && (
                          <Sortable
                            dataKey={c.dataKey}
                            sortBy={options.sortBy}
                            sortOrder={options.sortOrder}
                          />
                        )}
                        {c.label}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {!options.error &&
                options.rows.length > 0 &&
                options.rows.map((r, index) => (
                  <TableRow key={`row-${r.id}-${index}`}>
                    {options.columns.map((c, cindex) => (
                      <TableCell
                        key={`data-col-${c.id}-${r[c.dataKey]}`}
                        align={c.align}
                        width={c.width}
                        sx={{
                          padding: '16px',
                          fontSize: '0.875rem !important',
                          backgroundColor: '#FFF',
                          position: cindex === 0 && 'sticky',
                          left: cindex === 0 && 0,
                          minWidth: cindex === 0 && c.width,
                          maxWidth: cindex === 0 && c.width,
                          whiteSpace: cindex === 0 && 'nowrap',
                          overflow: cindex === 0 && 'hidden',
                          textOverflow: cindex === 0 && 'ellipsis',
                        }}
                        variant="string"
                      >
                        {c.id === 'title' ? (
                          <Tooltip title={r[c.dataKey] || '0'} arrow>
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                cursor: r.rulesetId ? 'pointer' : 'default',
                              }}
                              onClick={() => {
                                if (r.rulesetId) {
                                  setSelectedActionData({
                                    open: true,
                                    id: r.rulesetId,
                                    data: r,
                                  });
                                }
                              }}
                            >
                              {r[c.dataKey] || '0'}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body2" component="span">
                            {r[c.dataKey] || '0'}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              {!options.error && !options.rows.length && (
                <TableRow>
                  <TableCell align="center" colSpan={options.columns.length}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      my={20}
                    >
                      <Typography variant="body2" mb={1}>
                        There are no ruleset defined yet. Please create a new
                        ruleset.
                      </Typography>
                      {hasOperationAccess('rulesets', 'create') && (
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<Iconify icon="ic:round-add" />}
                          onClick={() =>
                            setSelectedActionData({
                              open: true,
                              id: null,
                              data: {},
                            })
                          }
                        >
                          Create New Ruleset
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              )}

              {options.error && (
                <TableRow>
                  <TableCell align="center" colSpan={options.columns.length}>
                    <Error />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!options.loading && options.rows.length > 0 && selectedChannel?.id && (
        <Button
          color="primary"
          variant="contained"
          disabled={options.loading || formSubmitted}
          onClick={() =>
            setSelectedActionData({ open: true, id: null, data: {} })
          }
          sx={{ my: 2 }}
        >
          Add Ruleset
        </Button>
      )}
      {!options.loading && selectedChannel?.id && selectedActionData.open && (
        <Grid
          item
          sx={{
            position: 'fixed',
            bottom: 0,
            left: isMobileView ? '24px' : '300px',
            width: isMobileView ? 'calc(100% - 48px)' : 'calc(100% - 324px)',
            height: '450px',
            backgroundColor: 'white',
            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
            padding: '8px 16px',
            overflowY: 'auto',
            zIndex: 1200,
            mb: 1.5,
          }}
        >
          <Stack spacing={2}>
            <AddEditScheduleRulesetRules
              title={
                selectedActionData.id
                  ? `Edit ${selectedActionData?.data?.rulesetName} Ruleset`
                  : 'Add New Ruleset'
              }
              channelContentScheduleInfoId={options.info.id}
              dataId={selectedActionData.id}
              rulesetType="SCHEDULING"
              channelId={selectedChannel?.id}
              onSave={handleRefreshData}
              onCancel={() =>
                setSelectedActionData({
                  id: null,
                  data: {},
                  open: false,
                })
              }
              showSnackbarMessage={showSnackbarMessage}
              selectedRulesetName={selectedActionData.data.rulesetName}
            />
          </Stack>
        </Grid>
      )}

      {showScheduleInfoDialog && (
        <EditScheduleInfo
          title={`Edit Schedule Info - ${selectedChannel.title}`}
          onCancel={() => setShowScheduleInfoDialog(false)}
          onSuccess={handleSaveScheduleInfo}
          defaultValues={options.info}
          formSubmitted={formSubmitted}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ContentSchedule.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ContentSchedule;
