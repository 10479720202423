import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getRulesetScheduleData = async (channelId, type) => {
  const options = prepareRequestOptions(
    `/admin/channel/${channelId}/content/schedule/list?type=${type}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const manageChannelScheduleContentInfo = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/content/schedule/manage`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChannelAssignRulesetVideos = async (
  id,
  channelId,
  params = ''
) => {
  const options = prepareRequestOptions(
    `/admin/channel/${channelId}/content/schedule-info/${id}/videos${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const rulesetbaseAddVideoById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/ruleset/add-videos`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getRulesetById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/content/schedule/ruleset/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const createRuleset = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/content/schedule/ruleset/create`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateRuleset = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/content/schedule/ruleset/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteRulesetDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/content/schedule/ruleset/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
