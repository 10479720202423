import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import { maxLengthValidation } from '../../utils/validations.util';
import Trashy from '../form-elements/trashy.component';
import GoodBad from '../form-elements/good-bad.component';
import ImdbRanking from '../form-elements/imdb-ranking.component';

const VideoRatingClassification = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      trashy: videoDetail.trashy || 'NO',
      goodBad: videoDetail.goodBad || 'NA',
      pnmExclusive: videoDetail.pnmExclusive === 'YES',
      ratingAverage: videoDetail.ratingAverage || '',
      ratingCount: videoDetail.ratingCount || '',
      ratingManipulated: videoDetail.ratingManipulated || '',
      ratingOverride: videoDetail.ratingOverride || '',
      fiveStarRating: videoDetail.fiveStarRating || '',
      trashyRating: videoDetail.trashyRating || '',
      imdbRanking: videoDetail.imdbRanking || 'null',
      imdbRating: videoDetail.imdbRating || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      pnmExclusive: data.pnmExclusive ? 'YES' : 'NO',
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Rating & Classification data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form
        id="rating-classification-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="trashy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Trashy
                  id="trashy"
                  name="trashy"
                  label="Trashy"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.trashy?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="goodBad"
              control={control}
              render={({ field: { onChange, value } }) => (
                <GoodBad
                  id="good_bad"
                  name="good_bad"
                  label="Good Bad"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.goodBad?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="pnmExclusive"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="PNM Exclusive"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingAverage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Average"
                  type="number"
                  error={!!errors.ratingAverage}
                  helperText={errors?.ratingAverage?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Count"
                  type="number"
                  error={!!errors.ratingCount}
                  helperText={errors?.ratingCount?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingManipulated"
              control={control}
              rules={maxLengthValidation(3)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Manipulated"
                  type="text"
                  error={!!errors.ratingManipulated}
                  helperText={errors?.ratingManipulated?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingOverride"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Override"
                  type="number"
                  error={!!errors.ratingOverride}
                  helperText={errors?.ratingOverride?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="fiveStarRating"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Five Star Rating"
                  type="number"
                  error={!!errors.fiveStarRating}
                  helperText={errors?.fiveStarRating?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="trashyRating"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Trashy Rating"
                  type="number"
                  error={!!errors.trashyRating}
                  helperText={errors?.trashyRating?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbRanking"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ImdbRanking
                  id="imdb_ranking"
                  name="imdb_ranking"
                  label="IMDB Ranking"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.imdbRanking?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="imdbRating"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IMDB Rating"
                  type="number"
                  error={!!errors.imdbRating}
                  helperText={errors?.imdbRating?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="rating-classification-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Rating & Classification
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoRatingClassification.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoRatingClassification;
