import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/videos.service';
import { maxLengthValidation } from '../../utils/validations.util';
import PublicDomain from '../form-elements/public-domain.component';
import Poster16x9Status from '../form-elements/poster-16x9-status.component';
import RestrictedContent from '../form-elements/restricted-content.component';
import VideoStatus from '../form-elements/video-status.component';
import VideoStatusReason from '../form-elements/video-status-reason.component';
import TrailerStatus from '../form-elements/trailer-status.component';
import TrailerStatusReason from '../form-elements/trailer-status-reason.component';
import TVODPrice from '../form-elements/tvod-price.component';
import QualityCheck from '../form-elements/quality-check.component';

const VideoOthers = ({
  videoDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      descriptionStatus: videoDetail.descriptionStatus || '',
      restrictedContentSource: videoDetail.restrictedContentSource || '',
      svodEnabled: !!(Number(videoDetail.svodEnabled) || 0),
      svodWatched: videoDetail.svodWatched || '',
      avodValidated: videoDetail.avodValidated || 'NC',
      avodWatched: videoDetail.avodWatched || '',
      avodWatched7: videoDetail.avodWatched7 || '',
      tvodEnabled: !!(Number(videoDetail.tvodEnabled) || 0),
      tvodIap: videoDetail.tvodIap || '1',
      tagline: videoDetail.tagline || '',
      language: videoDetail.language || '',
      budget: videoDetail.budget || '',
      contractNo: videoDetail.contractNo || '',
      mg: videoDetail.mg || '',
      publicDomain: videoDetail.publicDomain || 'NO',
      customRoyality: videoDetail.customRoyality || '',
      poster16x9Status: videoDetail.poster16x9Status || 'NC',
      videoStatus: videoDetail.videoStatus || 'NC',
      videoStatusReason: videoDetail.videoStatusReason || 'NC',
      videoStatusReasonDetails: videoDetail.videoStatusReasonDetails || '',
      trailerStatus: videoDetail.trailerStatus || 'NC',
      trailerStatusReason: videoDetail.trailerStatusReason || 'NC',
      qualityVideo: videoDetail.qualityVideo || 'NC',
      qualityPoster3x4: videoDetail.qualityPoster3x4 || 'NC',
      qualitySubtitle: videoDetail.qualitySubtitle || 'NC',
      qualityDescription: videoDetail.qualityDescription || 'NC',
      qualityPoster16x9: videoDetail.qualityPoster16x9 || 'NC',
      qualityTrailer: videoDetail.qualityTrailer || 'NC',
      qualityGenres: videoDetail.qualityGenres || 'NC',
      qualityCategories: videoDetail.qualityCategories || 'NC',
      qualitySourceUrl: videoDetail.qualitySourceUrl || 'NC',
      minutesWatchedWeek: videoDetail.minutesWatchedWeek || '',
      minutesWatchedMonth: videoDetail.minutesWatchedMonth || '',
      awardWins: videoDetail.awardWins || '',
      awardNominations: videoDetail.awardNominations || '',
      reviewsUser: videoDetail.reviewsUser || '',
      reviewsCritic: videoDetail.reviewsCritic || '',
      aspectRatio: videoDetail.aspectRatio || '',
      color: videoDetail.color || '',
      parentalSummarySexNudity: videoDetail.parentalSummarySexNudity || '',
      parentalSummaryViolenceGore:
        videoDetail.parentalSummaryViolenceGore || '',
      parentalSummaryProfanity: videoDetail.parentalSummaryProfanity || '',
      parentalSummaryAlcoholDrugsSmoking:
        videoDetail.parentalSummaryAlcoholDrugsSmoking || '',
      parentalSummaryFrighteningScenes:
        videoDetail.parentalSummaryFrighteningScenes || '',
      parentalCertification: videoDetail.parentalCertification || '',
      parentalSexNudity: videoDetail.parentalSexNudity || '',
      parentalViolenceGore: videoDetail.parentalViolenceGore || '',
      parentalProfanity: videoDetail.parentalProfanity || '',
      parentalAlcoholDrugsSmoking:
        videoDetail.parentalAlcoholDrugsSmoking || '',
      parentalFrighteningScenes: videoDetail.parentalFrighteningScenes || '',
      rating10: videoDetail.rating10 || '',
      rating9: videoDetail.rating9 || '',
      rating8: videoDetail.rating8 || '',
      rating7: videoDetail.rating7 || '',
      rating6: videoDetail.rating6 || '',
      rating5: videoDetail.rating5 || '',
      rating4: videoDetail.rating4 || '',
      rating3: videoDetail.rating3 || '',
      rating2: videoDetail.rating2 || '',
      rating1: videoDetail.rating1 || '',
      ratingDemogAllAgeMF: videoDetail.ratingDemogAllAgeMF || '',
      ratingDemogAllAgeM: videoDetail.ratingDemogAllAgeM || '',
      ratingDemogAllAgeF: videoDetail.ratingDemogAllAgeF || '',
      ratingDemog18MF: videoDetail.ratingDemog18MF || '',
      ratingDemog18M: videoDetail.ratingDemog18M || '',
      ratingDemog18F: videoDetail.ratingDemog18F || '',
      ratingDemog1829MF: videoDetail.ratingDemog1829MF || '',
      ratingDemog1829M: videoDetail.ratingDemog1829M || '',
      ratingDemog1829F: videoDetail.ratingDemog1829F || '',
      ratingDemog3040MF: videoDetail.ratingDemog3040MF || '',
      ratingDemog3040M: videoDetail.ratingDemog3040M || '',
      ratingDemog3040F: videoDetail.ratingDemog3040F || '',
      ratingDemog45MF: videoDetail.ratingDemog45MF || '',
      ratingDemog45M: videoDetail.ratingDemog45M || '',
      ratingDemog45F: videoDetail.ratingDemog45F || '',
      ratingDemogTop1000Votes: videoDetail.ratingDemogTop1000Votes || '',
      ratingDemogUS: videoDetail.ratingDemogUS || '',
      ratingDemogNonUS: videoDetail.ratingDemogNonUS || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(videoDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Others data updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box component={Paper} padding={2}>
      <form id="others-data-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="descriptionStatus"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description Status"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.descriptionStatus}
                  helperText={errors?.descriptionStatus?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="tagline"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tagline"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.tagline}
                  helperText={errors?.tagline?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="restrictedContentSource"
              control={control}
              rules={maxLengthValidation(64)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Restricted Content Source"
                  type="text"
                  error={!!errors.restrictedContentSource}
                  helperText={errors?.restrictedContentSource?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="svodEnabled"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="SVOD Enabled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="svodWatched"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SVOD Watched (In Minutes)"
                  type="number"
                  error={!!errors.svodWatched}
                  helperText={errors?.svodWatched?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="avodValidated"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RestrictedContent
                  id="avod_validated"
                  name="avod_validated"
                  label="AVOD License"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.avodValidated?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="avodWatched"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="AVOD Watched (In Minutes)"
                  type="number"
                  error={!!errors.avodWatched}
                  helperText={errors?.avodWatched?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="avodWatched7"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="AVOD Weekly Watched (In Minutes)"
                  type="number"
                  error={!!errors.avodWatched7}
                  helperText={errors?.avodWatched7?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="tvodEnabled"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="TVOD Enabled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="tvodIap"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TVODPrice
                  id="tvod_iap"
                  name="tvod_iap"
                  label="TVOD Price"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.tvodIap?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="language"
              control={control}
              rules={maxLengthValidation(32)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Language"
                  type="text"
                  error={!!errors.language}
                  helperText={errors?.language?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="budget"
              control={control}
              rules={maxLengthValidation(32)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Budget"
                  type="text"
                  error={!!errors.budget}
                  helperText={errors?.budget?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="contractNo"
              control={control}
              rules={maxLengthValidation(100)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Contract No"
                  type="text"
                  error={!!errors.contractNo}
                  helperText={errors?.contractNo?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="mg"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="MG"
                  type="number"
                  error={!!errors.mg}
                  helperText={errors?.mg?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="publicDomain"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PublicDomain
                  id="public_domain"
                  name="public_domain"
                  label="Public Domain"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.publicDomain?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="customRoyality"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custom Royality"
                  type="number"
                  error={!!errors.customRoyality}
                  helperText={errors?.customRoyality?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="poster16x9Status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Poster16x9Status
                  id="poster_16x9_status"
                  name="poster_16x9_status"
                  label="Poster 16x9 Status"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.poster16x9Status?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoStatus"
              control={control}
              render={({ field: { onChange, value } }) => (
                <VideoStatus
                  id="video_status"
                  name="video_status"
                  label="Video Status"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.videoStatus?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoStatusReason"
              control={control}
              render={({ field: { onChange, value } }) => (
                <VideoStatusReason
                  id="video_status_reason"
                  name="video_status_reason"
                  label="Video Status Reason"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.videoStatusReason?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="videoStatusReasonDetails"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Video Status Reason Details"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.videoStatusReasonDetails}
                  helperText={errors?.videoStatusReasonDetails?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="trailerStatus"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrailerStatus
                  id="trailer_status"
                  name="trailer_status"
                  label="Trailer Status"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.trailerStatus?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="trailerStatusReason"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TrailerStatusReason
                  id="trailer_status_reason"
                  name="trailer_status_reason"
                  label="Trailer Status Reason"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.trailerStatusReason?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityVideo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_video"
                  name="quality_video"
                  label="Quality Video"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityVideo?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityPoster3x4"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_poster_3x4"
                  name="quality_poster_3x4"
                  label="Quality Poster 3x4"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityPoster3x4?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualitySubtitle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_subtitle"
                  name="quality_subtitle"
                  label="Quality Subtitle"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualitySubtitle?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityDescription"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_description"
                  name="quality_description"
                  label="Quality Description"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityDescription?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityPoster16x9"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_poster_16x9"
                  name="quality_poster_16x9"
                  label="Quality Poster 16x9"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityPoster16x9?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityTrailer"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_trailer"
                  name="quality_trailer"
                  label="Quality Trailer"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityTrailer?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityGenres"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_genres"
                  name="quality_genres"
                  label="Quality Genres"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityGenres?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualityCategories"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_categories"
                  name="quality_categories"
                  label="Quality Categories"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualityCategories?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="qualitySourceUrl"
              control={control}
              render={({ field: { onChange, value } }) => (
                <QualityCheck
                  id="quality_source_url"
                  name="quality_source_url"
                  label="Quality Source URL"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.qualitySourceUrl?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="minutesWatchedWeek"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Minutes Watched Week"
                  type="number"
                  error={!!errors.minutesWatchedWeek}
                  helperText={errors?.minutesWatchedWeek?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="minutesWatchedMonth"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Minutes Watched Month"
                  type="number"
                  error={!!errors.minutesWatchedMonth}
                  helperText={errors?.minutesWatchedMonth?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="awardWins"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Award Wins"
                  type="number"
                  error={!!errors.awardWins}
                  helperText={errors?.awardWins?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="awardNominations"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Award Nominations"
                  type="number"
                  error={!!errors.awardNominations}
                  helperText={errors?.awardNominations?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="reviewsUser"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Review User"
                  type="number"
                  error={!!errors.reviewsUser}
                  helperText={errors?.reviewsUser?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="reviewsCritic"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Review Critic"
                  type="number"
                  error={!!errors.reviewsCritic}
                  helperText={errors?.reviewsCritic?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="aspectRatio"
              control={control}
              rules={maxLengthValidation(64)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Aspect Ratio"
                  type="text"
                  error={!!errors.aspectRatio}
                  helperText={errors?.aspectRatio?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="color"
              control={control}
              rules={maxLengthValidation(32)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Color"
                  type="text"
                  error={!!errors.color}
                  helperText={errors?.color?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSummarySexNudity"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sum Sex Nudity"
                  type="text"
                  error={!!errors.parentalSummarySexNudity}
                  helperText={errors?.parentalSummarySexNudity?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSummaryViolenceGore"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sum Violence Gore"
                  type="text"
                  error={!!errors.parentalSummaryViolenceGore}
                  helperText={
                    errors?.parentalSummaryViolenceGore?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSummaryProfanity"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sum Profanity"
                  type="text"
                  error={!!errors.parentalSummaryProfanity}
                  helperText={errors?.parentalSummaryProfanity?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSummaryAlcoholDrugsSmoking"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sum Alcohol Drugs Smoking"
                  type="text"
                  error={!!errors.parentalSummaryAlcoholDrugsSmoking}
                  helperText={
                    errors?.parentalSummaryAlcoholDrugsSmoking?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSummaryFrighteningScenes"
              control={control}
              rules={maxLengthValidation(128)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sum Frightening Intense Scenes"
                  type="text"
                  error={!!errors.parentalSummaryFrighteningScenes}
                  helperText={
                    errors?.parentalSummaryFrighteningScenes?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} />

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalCertification"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Certification"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalCertification}
                  helperText={errors?.parentalCertification?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalSexNudity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Sex Nudity"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalSexNudity}
                  helperText={errors?.parentalSexNudity?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalViolenceGore"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Violence Gore"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalViolenceGore}
                  helperText={errors?.parentalViolenceGore?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalProfanity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Profanity"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalProfanity}
                  helperText={errors?.parentalProfanity?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalAlcoholDrugsSmoking"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Alcohol Drugs Smoking"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalAlcoholDrugsSmoking}
                  helperText={
                    errors?.parentalAlcoholDrugsSmoking?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="parentalFrighteningScenes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Parental Frightening Intense Scenes"
                  type="text"
                  multiline
                  minRows={5}
                  maxRows={5}
                  error={!!errors.parentalFrighteningScenes}
                  helperText={
                    errors?.parentalFrighteningScenes?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating10"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 10"
                  type="number"
                  error={!!errors.rating10}
                  helperText={errors?.rating10?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating9"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 9"
                  type="number"
                  error={!!errors.rating9}
                  helperText={errors?.rating9?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating8"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 8"
                  type="number"
                  error={!!errors.rating8}
                  helperText={errors?.rating8?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating7"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 7"
                  type="number"
                  error={!!errors.rating7}
                  helperText={errors?.rating7?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating6"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 6"
                  type="number"
                  error={!!errors.rating6}
                  helperText={errors?.rating6?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating5"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 5"
                  type="number"
                  error={!!errors.rating5}
                  helperText={errors?.rating5?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating4"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 4"
                  type="number"
                  error={!!errors.rating4}
                  helperText={errors?.rating4?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating3"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 3"
                  type="number"
                  error={!!errors.rating3}
                  helperText={errors?.rating3?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 2"
                  type="number"
                  error={!!errors.rating2}
                  helperText={errors?.rating2?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="rating1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating 1"
                  type="number"
                  error={!!errors.rating1}
                  helperText={errors?.rating1?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogAllAgeMF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog All Age Male/Female"
                  type="number"
                  error={!!errors.ratingDemogAllAgeMF}
                  helperText={errors?.ratingDemogAllAgeMF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogAllAgeM"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog All Age Male"
                  type="number"
                  error={!!errors.ratingDemogAllAgeM}
                  helperText={errors?.ratingDemogAllAgeM?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogAllAgeF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog All Age Female"
                  type="number"
                  error={!!errors.ratingDemogAllAgeF}
                  helperText={errors?.ratingDemogAllAgeF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog18MF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 Male/Female"
                  type="number"
                  error={!!errors.ratingDemog18MF}
                  helperText={errors?.ratingDemog18MF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog18M"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 Male"
                  type="number"
                  error={!!errors.ratingDemog18M}
                  helperText={errors?.ratingDemog18M?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog18F"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 Female"
                  type="number"
                  error={!!errors.ratingDemog18F}
                  helperText={errors?.ratingDemog18F?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog1829MF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 to 29 Male/Female"
                  type="number"
                  error={!!errors.ratingDemog1829MF}
                  helperText={errors?.ratingDemog1829MF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog1829M"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 to 29 Male"
                  type="number"
                  error={!!errors.ratingDemog1829M}
                  helperText={errors?.ratingDemog1829M?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog1829F"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 18 to 29 Female"
                  type="number"
                  error={!!errors.ratingDemog1829F}
                  helperText={errors?.ratingDemog1829F?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog3040MF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 30 to 40 Male/Female"
                  type="number"
                  error={!!errors.ratingDemog3040MF}
                  helperText={errors?.ratingDemog3040MF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog3040M"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 30 to 40 Male"
                  type="number"
                  error={!!errors.ratingDemog3040M}
                  helperText={errors?.ratingDemog3040M?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog3040F"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 30 to 40 Female"
                  type="number"
                  error={!!errors.ratingDemog3040F}
                  helperText={errors?.ratingDemog3040F?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog45MF"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 45 Male/Female"
                  type="number"
                  error={!!errors.ratingDemog45MF}
                  helperText={errors?.ratingDemog45MF?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog45M"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 45 Male"
                  type="number"
                  error={!!errors.ratingDemog45M}
                  helperText={errors?.ratingDemog45M?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemog45F"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog 45 Female"
                  type="number"
                  error={!!errors.ratingDemog45F}
                  helperText={errors?.ratingDemog45F?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogTop1000Votes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog Top 1000 Votes"
                  type="number"
                  error={!!errors.ratingDemogTop1000Votes}
                  helperText={errors?.ratingDemogTop1000Votes?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogUS"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog Us"
                  type="number"
                  error={!!errors.ratingDemogUS}
                  helperText={errors?.ratingDemogUS?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ratingDemogNonUS"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rating Demog Non Us"
                  type="number"
                  error={!!errors.ratingDemogNonUS}
                  helperText={errors?.ratingDemogNonUS?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="others-data-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Others
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

VideoOthers.propTypes = {
  videoDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default VideoOthers;
