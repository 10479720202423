import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
  Box,
} from '@mui/material';

const RuleFilter = ({
  id,
  label,
  defaultValue,
  multiple,
  disabled,
  onChange,
  error,
  values,
}) => {
  const selectedValue = defaultValue || (multiple ? [] : null);
  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  return (
    <FormControl fullWidth error={!!error} disabled={disabled}>
      <Box>
        <Autocomplete
          id={id}
          options={values}
          getOptionLabel={(option) => String(option.label)}
          value={value}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          multiple={multiple}
          isOptionEqualToValue={(option, val) => option.value === val.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="filled"
              className="text-sm"
            />
          )}
          disabled={disabled}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </Box>
    </FormControl>
  );
};

RuleFilter.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      cmsId: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

RuleFilter.defaultProps = {
  multiple: false,
  disabled: false,
  defaultValue: null,
  error: '',
};

export default RuleFilter;
