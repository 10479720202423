// eslint-disable-next-line no-unused-vars
import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListContentVideos from '../../components/content/list-content-videos.component';

const VideosView = () => {
  const pageTitle = 'Manage Content / Video Library';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListContentVideos />
    </AppLayout>
  );
};

export default VideosView;
