import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import StyledDialogActions from '../../theme/dialogActionStyles';
import {
  manageRulesData,
  viewRuleById,
} from '../../services/ruleset-rules.service';
import RULE_ASSIGNMENT_FIELDS from '../../config/rule-assignment-fields.config';
import RuleAssignmentFields from '../form-elements/rule-assignment-fields.component';
import VideoTypes from '../form-elements/video-types.component';
import { DECADES } from '../../config/const.config';
import Decades from '../form-elements/decades.component';
import Countries from '../form-elements/countries.component';
import Regions from '../form-elements/regions.component';
import Trashy from '../form-elements/trashy.component';
import GoodBad from '../form-elements/good-bad.component';
import Booleans from '../form-elements/boolean.component';
import ImdbRanking from '../form-elements/imdb-ranking.component';
import MpaaRating from '../form-elements/mpaa-rating.component';
import RestrictedContent from '../form-elements/restricted-content.component';
import RestrictedContentReason from '../form-elements/restricted-content-reason.component';
import AdTimingStatus from '../form-elements/ad-timing-status.component';
import GenreStatus from '../form-elements/genre-status.component';
import Resolution from '../form-elements/resolution.component';
import Series from '../form-elements/series.component';
import Distributors from '../form-elements/distributors.component';
import RuleFilter from '../rules/rule-filter.component';
import PrimaryGenres from '../form-elements/primary-genres.component';
import SecondaryGenres from '../form-elements/secondary-genres.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditRules = ({ title, dataId, onCancel, onSuccess }) => {
  const operators = [
    {
      label: 'Equals to',
      value: 'EQUALS_TO',
      type: ['number', 'text', 'select'],
    },
    { label: 'Not equals to', value: 'NOT_EQUALS_TO', type: ['number'] },
    { label: 'Greater than', value: 'GREATER_THEN', type: ['number'] },
    { label: 'Less than', value: 'LESS_THEN', type: ['number'] },
    { label: 'Contains', value: 'CONTAINS', type: ['text'] },
    { label: 'Does not contains', value: 'DOES_NOT_CONTAINS', type: ['text'] },
    { label: 'Blank', value: 'BLANK', type: ['number', 'text', 'select'] },
    {
      label: 'Not Blank',
      value: 'NOT_BLANK',
      type: ['number', 'text', 'select'],
    },
  ];

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      field: '',
      operator: '',
      value: '',
      secondaryValue: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedPrimaryGenre, setSelectedPrimaryGenre] = useState(null);

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    let value = '';
    if (data.field === 'rel_genre_id') {
      value = `${data.value?.name === '* (All)' ? '*' : data.value.name} | ${
        // eslint-disable-next-line no-nested-ternary
        data.secondaryValue?.name === '* (All)'
          ? '*'
          : data.secondaryValue?.name === '/ (None)'
          ? '/'
          : data.secondaryValue?.name
      }`;
    } else if (data.field === 'series_id' || data.field === 'distributor_id') {
      value = data.value?.id;
    } else {
      value = data.value;
    }

    const payload = {
      title: data.title,
      field: data.field,
      operator: data.operator.value,
      value,
      id: dataId || null,
    };

    manageRulesData(payload)
      .then((res) => {
        onSuccess('Rule saved successfully.', res.data);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const renderOperator = (field) => {
    const fieldValue = watch(`field`);

    const renderOperatorType =
      RULE_ASSIGNMENT_FIELDS[fieldValue]?.type || 'text';
    let options = [];
    if (renderOperatorType === 'text') {
      options = operators.filter((operator) => operator.type.includes('text'));
    } else if (
      renderOperatorType === 'number' ||
      renderOperatorType === 'date'
    ) {
      options = operators.filter((operator) =>
        operator.type.includes('number')
      );
    } else if (renderOperatorType === 'select') {
      options = operators.filter((operator) =>
        operator.type.includes('select')
      );
    }

    return (
      <RuleFilter
        id="operator-label"
        name="operator"
        label="Operator *"
        values={options}
        {...field}
        defaultValue={field.value}
        onChange={field.onChange}
        error={errors?.operator?.message}
      />
    );
  };

  const renderValue = (field) => {
    const fieldValue = watch(`field`);
    const renderOperatorType =
      RULE_ASSIGNMENT_FIELDS[fieldValue]?.type || 'text';

    if (
      renderOperatorType === 'text' ||
      renderOperatorType === 'number' ||
      renderOperatorType === 'date'
    ) {
      return (
        <TextField
          {...field}
          fullWidth
          type={
            renderOperatorType === 'text' || renderOperatorType === 'date'
              ? 'text'
              : 'number'
          }
          variant="filled"
          onChange={field.onChange}
          defaultValue={field.value}
          value={field.value}
          label={`Value ${
            renderOperatorType === 'date' ? '(YYYY-MM-DD)' : ''
          } *`}
          error={errors?.value?.message}
          helperText={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'video_type') {
      return (
        <VideoTypes
          id="video_type"
          name="videoType"
          label="Type"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'decade') {
      return (
        <Decades
          id="decade"
          name="decade"
          label="Decade"
          defaultValue={field.value}
          onChange={field.onChange}
          validOptions={Object.keys(DECADES)}
          sx={{ width: '100%', my: 1 }}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'country') {
      return (
        <Countries
          id="country"
          name="country"
          label="Country"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'region') {
      return (
        <Regions
          id="region"
          name="region"
          label="Region"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'series_id') {
      return (
        <Series
          id="series"
          name="series"
          label="Series"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'rel_genre_id') {
      return (
        <PrimaryGenres
          id="primary_genres"
          name="primary_genres"
          label="Primary Genre"
          defaultValue={field.value}
          onChange={(e) => {
            field.onChange(e);
            setSelectedPrimaryGenre(e);
          }}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'distributor_id') {
      return (
        <Distributors
          id="distributor"
          name="distributor"
          label="Distributor"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'trashy') {
      return (
        <Trashy
          id="trashy"
          name="trashy"
          label="Trashy"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'good_bad') {
      return (
        <GoodBad
          id="good_bad"
          name="goodBad"
          label="Good Bad"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'pnm_exclusives') {
      return (
        <Booleans
          id="pnm_exclusives"
          name="exclusive"
          label="Exclusive"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'imdb_ranking') {
      return (
        <ImdbRanking
          id="imdb_ranking"
          name="imdbRanking"
          label="IMDB Ranking"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'mpaa_rating') {
      return (
        <MpaaRating
          id="mpaa_rating"
          name="mpaaRating"
          label="Mpaa Rating"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'offline') {
      return (
        <Booleans
          id="offline"
          name="offline"
          label="Offline"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (
      renderOperatorType === 'select' &&
      fieldValue === 'restricted_content'
    ) {
      return (
        <RestrictedContent
          id="restricted_content"
          name="restrictedContent"
          label="Restricted Content"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (
      renderOperatorType === 'select' &&
      fieldValue === 'restricted_content_reason'
    ) {
      return (
        <RestrictedContentReason
          id="restricted_content_reason"
          name="restrictedContentReason"
          label="Restricted Content Reason"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'exclude_from_db') {
      return (
        <Booleans
          id="exclude_from_db"
          name="excludeFromDb"
          label="Exclude From DB"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'ad_timing_status') {
      return (
        <AdTimingStatus
          id="ad_timing_status"
          name="adTimingStatus"
          label="Ad Timing Status"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'genre_status') {
      return (
        <GenreStatus
          id="genre_status"
          name="genreStatus"
          label="Genre Status"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'duplicate_imdb') {
      return (
        <Booleans
          id="duplicate_imdb"
          name="duplicateImdb"
          label="Duplicate IMDB"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'avod_validated') {
      return (
        <Booleans
          id="avod_validated"
          name="avodEnabled"
          label="avod Enabled"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (renderOperatorType === 'select' && fieldValue === 'resolution') {
      return (
        <Resolution
          id="resolution"
          name="resolution"
          label="Resolution"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    if (
      renderOperatorType === 'select' &&
      (fieldValue === 'quality_video' ||
        fieldValue === 'quality_poster_16x9' ||
        fieldValue === 'quality_trailer' ||
        fieldValue === 'quality_poster_3x4' ||
        fieldValue === 'quality_subtitle' ||
        fieldValue === 'quality_source_url')
    ) {
      return (
        <GenreStatus
          id="url_check"
          name="url_check"
          label="URL Check"
          defaultValue={field.value}
          onChange={field.onChange}
          sx={{ width: '100%', my: 1 }}
          error={errors?.value?.message}
        />
      );
    }
    return (
      <TextField
        {...field}
        fullWidth
        variant="filled"
        label="Value *"
        error={errors?.value?.message}
        helperText={errors?.value?.message}
      />
    );
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewRuleById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue(`field`, res.data?.field || null);
        setValue(
          `operator`,
          operators.find((opt) => opt.value === res.data.operator)
        );
        if (res.data?.field === 'rel_genre_id') {
          let value = '';
          let secondaryValue = '';
          if (res.data.value?.split('|')?.[0].trim() === '*') {
            value = {
              name: '* (All)',
            };
          } else {
            value = {
              id: res.data.primaryGenreId,
              name: res.data.value?.split('|')?.[0].trim(),
            };
          }

          if (res.data.value?.split('|')?.[1].trim() === '*') {
            secondaryValue = {
              name: '* (All)',
            };
          } else if (res.data.value?.split('|')?.[1].trim() === '/') {
            secondaryValue = {
              name: '/ (None)',
            };
          } else {
            secondaryValue = {
              name: res.data.value?.split('|')?.[1].trim(),
            };
          }

          setValue(`value`, value);
          setValue(`secondaryValue`, secondaryValue);
        } else {
          setValue(`value`, res.data.value);
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid
              container
              spacing={2}
              sx={{ marginBlock: 2 }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: 'Please enter a title' }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      variant="filled"
                      onChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                      label="Title *"
                      error={errors?.title?.message}
                      helperText={errors?.title?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="field"
                  control={control}
                  rules={{ required: 'Please select a field' }}
                  render={({ field, fieldState: { error } }) => (
                    <RuleAssignmentFields
                      id="field-label"
                      name="field-label"
                      label="Field *"
                      defaultValue={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue(`operator`, null);
                        setValue(`value`, null);
                      }}
                      validOptions={Object.keys(RULE_ASSIGNMENT_FIELDS)}
                      sx={{ width: '100%' }}
                      error={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="operator"
                  control={control}
                  rules={{ required: 'Please select an operator' }}
                  render={({ field }) => renderOperator(field)}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="value"
                  control={control}
                  rules={{ required: 'Please select a value' }}
                  render={({ field }) => renderValue(field)}
                />
              </Grid>
              {watch('field') === 'rel_genre_id' && (
                <Grid item xs={12}>
                  <Controller
                    name="secondaryValue"
                    control={control}
                    rules={{ required: 'Please select a value' }}
                    render={({ field }) => (
                      <SecondaryGenres
                        id="secondary_genres"
                        name="secondary_genres"
                        label="Secondary Genre"
                        defaultValue={field.value}
                        onChange={field.onChange}
                        sx={{ width: '100%', my: 1 }}
                        error={errors?.secondaryValue?.message}
                        primaryGenreId={selectedPrimaryGenre?.id || null}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>

          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditRules.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditRules;
