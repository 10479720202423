import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Playlists module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channels',
    label: 'Channels',
    width: '25%',
    align: 'left',
    dataKey: 'channels',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playListLevel',
    label: 'Level',
    width: '10%',
    align: 'left',
    dataKey: 'playListLevel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'live',
    label: 'Live',
    width: '5%',
    align: 'left',
    dataKey: 'live',
    sortable: true,
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '10%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
  {
    action: ACTIONS.MANAGE_VIDEOS.value,
    label: ACTIONS.MANAGE_VIDEOS.label,
    conditional: (val) => val.playListLevel !== '2-Level playlist',
  },
  {
    action: ACTIONS.MANAGE_ORDER.value,
    label: ACTIONS.MANAGE_ORDER.label,
    conditional: (val) => val.playListLevel === '2-Level playlist',
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channelsName',
    label: 'Channels',
    dataKey: 'channelsName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playListLevel',
    label: 'Level',
    dataKey: 'playListLevel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentPlaylistName',
    label: 'Parent Playlist',
    dataKey: 'parentPlaylistName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'live',
    label: 'Live',
    dataKey: 'live',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'global',
    label: 'Global',
    dataKey: 'globalPlaylist',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'checkChannelVideoAssignment',
    label: 'Check Channel Video Assignment',
    dataKey: 'checkChannelVideoAssignment',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'image',
    label: 'Image',
    dataKey: 'image',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
