import React from 'react';
import AppLayout from '../../layout/app.layout';
import DeployedTags from '../../components/advertisers/list-deployed-tags.component';

const DeployedTagsView = () => {
  const pageTitle = 'Advertising / Deploy Tags';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <DeployedTags />
    </AppLayout>
  );
};

export default DeployedTagsView;
