import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Container, Grid, Typography } from '@mui/material';
import ROUTE_PATH from '../../config/routes.config';
import { validateLoginAsAdminUser } from '../../services/distributor.service';
import { setAdminUserLoggedIn } from '../../utils/local-cache.util';

const useQuery = () => new URLSearchParams(useLocation().search);

// Auto login page
const AutoLogin = () => {
  const query = useQuery();
  const tokenAuth = query.get('auth') || 0;

  useEffect(() => {
    validateLoginAsAdminUser({ token: tokenAuth })
      .then((res) => {
        const loggedInUserPayload = {
          token: res.data?.user?.token || '',
          name: res.data?.user?.name || '',
          email: res.data?.user?.email || '',
          role: res.data?.user?.role || '',
        };
        setAdminUserLoggedIn(loggedInUserPayload);
        window.location.href = ROUTE_PATH.DASHBOARD;
      })
      .catch(() => {});
  }, []);

  return (
    <Grid
      container
      spacing={0}
      align="center"
      justify="center"
      direction="column"
    >
      <Container maxWidth="xs">
        <CircularProgress color="inherit" />

        <Typography component="p">Logging...</Typography>
      </Container>
    </Grid>
  );
};

export default AutoLogin;
