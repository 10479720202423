import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Iconify from '../common/iconify.component';
import validationRules from '../../utils/validations.util';
import RulesetCondition from './condition.component';
import useAccess from '../../hooks/access.hook';
import Rules from '../form-elements/rules.component';
import AddEditRules from './add-edit-rules.component';
import {
  createRuleset,
  deleteRulesetDataById,
  getRulesetById,
  updateRuleset,
} from '../../services/ruleset.service';
import Loading from '../table-elements/loading.component';
import ConfirmPopup from '../common/confirm-popup.component';

const AddEditScheduleRulesetRules = ({
  title,
  channelContentScheduleInfoId,
  dataId,
  rulesetType,
  onSave,
  onCancel,
  showSnackbarMessage,
  selectedRulesetName,
}) => {
  const { hasOperationAccess } = useAccess();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [createNewRule, setCreateNewRule] = useState(false);
  const [reloadRules, setReloadRules] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
      condition: 'AND',
      type: 'MOVIES',
      seriesContentType: 'SERIES_EPISODES',
      scheduleXDaysAdvance: '',
      timeBeetweenSeason: '',
      videosCountPerWeek: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rules',
  });
  const watchContentType = watch('type');
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onSubmit = (data) => {
    setFormSubmitted(true);

    const rulesetPayload = {
      name: data.title,
      rulesCondition: data.condition,
      rulesetType,
      rules: data.rules.map((item) => item.id?.id && item.id.id),
    };
    rulesetPayload.type =
      data.type === 'MOVIES' ? data.type : data.seriesContentType;
    rulesetPayload.numberOfDaysInAdvance = !(
      data.seriesContentType === 'SERIES_SEASON' && data.type === 'SERIES'
    )
      ? Number(data.scheduleXDaysAdvance)
      : null;
    rulesetPayload.numberOfContentPerWeek = Number(data.videosCountPerWeek);
    rulesetPayload.numberOfDaysBetweenContent =
      data.seriesContentType === 'SERIES_SEASON' && data.type === 'SERIES'
        ? Number(data.timeBeetweenSeason)
        : null;
    rulesetPayload.channelContentScheduleInfoId = channelContentScheduleInfoId;
    if (dataId) {
      rulesetPayload.rulesetId = dataId;
    }

    if (!dataId) {
      createRuleset(rulesetPayload)
        .then(() => {
          onSave();
          setFormSubmitted(false);
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          showSnackbarMessage(message, 'error');
          setFormSubmitted(false);
        });
    } else {
      updateRuleset(rulesetPayload)
        .then(() => {
          onSave();
          setFormSubmitted(false);
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          showSnackbarMessage(message, 'error');
          setFormSubmitted(false);
        });
    }
  };

  const handleRuleOpen = () => {
    setCreateNewRule(true);
  };
  const handleRuleCancel = () => {
    setCreateNewRule(false);
  };
  const handleRuleSuccess = (_, newRule) => {
    setCreateNewRule(false);
    append({
      id: newRule,
      rule: newRule.rule,
    });
    setReloadRules((prev) => !prev);
  };

  const handleDeleteData = () => {
    deleteRulesetDataById(dataId)
      .then(() => {
        const message = 'Ruleset deleted successfully.';
        showSnackbarMessage(message, 'success');
        onSave();
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        showSnackbarMessage(message, 'error');
        setShowDeleteConfirmation(false);
      });
  };

  useEffect(() => {
    if (!dataId) {
      setValue('title', '');
      setValue('condition', 'AND');
      setValue('type', 'MOVIES');
      setValue('seriesContentType', 'SERIES_EPISODES');
      setValue('videosCountPerWeek', '');
      setValue('scheduleXDaysAdvance', '');
      setValue('timeBeetweenSeason', '');
      setValue('rules', []);
      setLoading(false);
      return;
    }

    getRulesetById(dataId)
      .then((res) => {
        setValue('title', res.data?.name || '');
        setValue('condition', res.data?.rulesCondition || 'AND');
        setValue(
          'type',
          res.data?.type !== 'MOVIES' ? 'SERIES' : 'MOVIES' || 'MOVIES'
        );
        setValue(
          'seriesContentType',
          res.data?.type !== 'MOVIES' ? res.data?.type : 'SERIES_EPISODES'
        );
        setValue('videosCountPerWeek', res.data?.numberOfContentPerWeek || '');
        setValue('scheduleXDaysAdvance', res.data?.numberOfDaysInAdvance || '');
        setValue(
          'timeBeetweenSeason',
          res.data?.numberOfDaysBetweenContent || ''
        );
        setValue('rules', []);
        if (res.data?.rules?.length) {
          res.data?.rules.forEach((rule, index) => {
            setValue(`rules.${index}.id`, res.data?.rules[index]);
            setValue(`rules.${index}.rule`, rule.rule);
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        showSnackbarMessage(message, 'error');
      });
  }, [dataId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" py={1}>
          {title}
        </Typography>
        <Box py={1}>
          {(hasOperationAccess('rulesets', 'create') ||
            hasOperationAccess('rulesets', 'edit')) && (
            <>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                disabled={formSubmitted}
                onClick={onCancel}
                sx={{ mr: 1 }}
              >
                cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                form="add-edit-ruleset"
                type="submit"
                disabled={formSubmitted}
              >
                Save
              </Button>
              {dataId && (
                <IconButton onClick={() => setShowDeleteConfirmation(true)}>
                  <Iconify icon="material-symbols:delete-forever-outline-rounded" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
      <Divider style={{ margin: 0 }} />
      {loading && <Loading />}
      {!loading && (
        <form
          id="add-edit-ruleset"
          onSubmit={handleSubmit(onSubmit)}
          className="w-full"
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingTop: '8px',
            marginTop: '8px',
          }}
        >
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={rulesetType === 'ASSIGNMENT' ? 6 : 4}>
              <Controller
                name="title"
                control={control}
                rules={validationRules.REQUIRED}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    label="Name"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={rulesetType === 'ASSIGNMENT' ? 6 : 4}>
              <Controller
                name="condition"
                control={control}
                rules={validationRules.REQUIRED}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <RulesetCondition
                    name="condition"
                    id="ruleset"
                    defaultValue={value}
                    onChange={onChange}
                    label="Condition"
                    error={error?.message}
                  />
                )}
              />
            </Grid>
            {rulesetType !== 'ASSIGNMENT' && (
              <>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="videosCountPerWeek"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="filled"
                        label={
                          watchContentType === 'SERIES' &&
                          watch('seriesContentType') === 'SERIES_SEASON'
                            ? 'Seasons Per Event'
                            : 'Videos Count Per Week'
                        }
                        type="number"
                        error={!!errors.videosCountPerWeek}
                        helperText={errors?.videosCountPerWeek?.message || null}
                      />
                    )}
                  />
                </Grid>
                {!(
                  watchContentType === 'SERIES' &&
                  watch('seriesContentType') === 'SERIES_SEASON'
                ) && (
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="scheduleXDaysAdvance"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="filled"
                          label="Schedule X Days Advance"
                          type="number"
                          error={!!errors.scheduleXDaysAdvance}
                          helperText={
                            errors?.scheduleXDaysAdvance?.message || null
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
                {watchContentType === 'SERIES' &&
                  watch('seriesContentType') === 'SERIES_SEASON' && (
                    <Grid item xs={12} sm={4}>
                      <Controller
                        name="timeBeetweenSeason"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            fullWidth
                            variant="filled"
                            label="Time Beetween Season"
                            type="number"
                            error={!!errors.timeBeetweenSeason}
                            helperText={
                              errors?.timeBeetweenSeason?.message || null
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="type"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field }) => (
                      <FormControl component="fieldset">
                        <p
                          style={{
                            marginBottom: '4px',
                            marginTop: 0,
                            fontWeight: 'bold',
                          }}
                        >
                          Select Content Type
                        </p>
                        <RadioGroup
                          {...field}
                          style={{ flexDirection: 'row' }}
                          aria-label="type"
                          name="type"
                        >
                          <FormControlLabel
                            value="MOVIES"
                            control={<Radio />}
                            label="Movies"
                          />
                          <FormControlLabel
                            value="SERIES"
                            control={<Radio />}
                            label="Series"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </Grid>
                {watchContentType === 'SERIES' && (
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name="seriesContentType"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field }) => (
                        <FormControl component="fieldset">
                          <p
                            style={{
                              marginBottom: '4px',
                              marginTop: 0,
                              fontWeight: 'bold',
                            }}
                          >
                            Select Series Content Type
                          </p>
                          <RadioGroup
                            {...field}
                            style={{ flexDirection: 'row' }}
                            aria-label="type"
                            name="type"
                          >
                            <FormControlLabel
                              value="SERIES_EPISODES"
                              control={<Radio />}
                              label="Episode"
                            />
                            <FormControlLabel
                              value="SERIES_SEASON"
                              control={<Radio />}
                              label="Season"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Typography variant="subtitle1" marginTop={4}>
            Content Filter Conditions:
          </Typography>
          <Box
            sx={{
              border: '1px dashed #e3e3e3',
              borderRadius: 2,
              mt: 1,
              p: 1,
            }}
          >
            {fields.map((field1, index) => (
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignItems: 'center',
                    marginBlock: 2,
                  }}
                  justifyContent="space-between"
                  key={field1.id}
                >
                  <Grid item xs={12} sm={4} sx={{ pt: 0 }}>
                    <FormControl fullWidth required>
                      <Controller
                        name={`rules.${index}.id`}
                        control={control}
                        rules={{ required: 'Please select a rule' }}
                        render={({ field: { onChange, value } }) => (
                          <Rules
                            id={`field-rule-${index}`}
                            name={`field-rule-${index}`}
                            label="Select Rule"
                            defaultValue={value}
                            onChange={(e) => {
                              onChange(e);
                              setValue(`rules.${index}.rule`, e?.rule);
                            }}
                            reload={reloadRules}
                            sx={{ width: '100%' }}
                            error={errors?.rules?.[index]?.id?.message || ''}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ pt: 0 }}>
                    <FormControl fullWidth required>
                      <Controller
                        name={`rules.${index}.rule`}
                        control={control}
                        render={({ field: { value } }) => (
                          <Typography variant="body1">
                            {value || '-'}
                          </Typography>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  {(hasOperationAccess('rulesets', 'create') ||
                    hasOperationAccess('rulesets', 'edit') ||
                    hasOperationAccess('rulesets', 'delete')) && (
                    <Grid item xs={12} sm={0.5} sx={{ pt: 0, pr: 0 }}>
                      {fields.length && (
                        <IconButton onClick={() => remove(index)}>
                          <Iconify icon="material-symbols:delete-forever-outline-rounded" />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            ))}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                py: fields.length === 0 ? 5 : 1,
              }}
            >
              {fields.length === 0 && (
                <Typography variant="body2" mb={1}>
                  Please add content filter conditions for this set. Ruleset
                  will only work if it has at least one condition defined for
                  filtering content.
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {(hasOperationAccess('rulesets', 'create') ||
                  hasOperationAccess('rulesets', 'edit')) && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => append({ id: null, rule: '' })}
                    sx={{
                      minWidth: 24,
                      padding: '2px 6px',
                      fontSize: '0.7rem',
                      width: 'fit-content',
                    }}
                  >
                    Choose from existing
                  </Button>
                )}
                <Typography variant="body2">or</Typography>
                {(hasOperationAccess('rulesets', 'create') ||
                  hasOperationAccess('rulesets', 'edit')) && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRuleOpen()}
                    sx={{
                      minWidth: 24,
                      padding: '2px 6px',
                      fontSize: '0.7rem',
                      width: 'fit-content',
                    }}
                  >
                    Add New Condition
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      )}
      {createNewRule && (
        <AddEditRules
          title="Create New Rule"
          dataId={0}
          onCancel={handleRuleCancel}
          onSuccess={handleRuleSuccess}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmPopup
          title="Delete Ruleset"
          message={`Do you want to delete ${selectedRulesetName} ruleset? You can not undo this action!`}
          onCancel={() => setShowDeleteConfirmation(false)}
          onSuccess={() => handleDeleteData()}
        />
      )}
    </>
  );
};

AddEditScheduleRulesetRules.propTypes = {
  title: PropTypes.string.isRequired,
  channelContentScheduleInfoId: PropTypes.number.isRequired,
  dataId: PropTypes.number.isRequired,
  rulesetType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showSnackbarMessage: PropTypes.func.isRequired,
  selectedRulesetName: PropTypes.string.isRequired,
};

export default AddEditScheduleRulesetRules;
