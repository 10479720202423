import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Button,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import Iconify from '../common/iconify.component';
import Sortable from './sortable.component';
import NoData from './no-data.component';
import Error from './error.component';
import Loading from './loading.component';
import CellValue from './cell-value.component';
import { DYNAMIC_COLUMNS_CONFIG } from '../../config/module-configs/content-videos.config';
import EditableCellValue from './editable-cell-value.component';
import { getEditableCellDefaultValue } from '../../utils/dynamic-columns.util';
import { inlineUpdateDataByIds } from '../../services/videos.service';
import SnackbarInfo from '../common/snackbar-info.component';

const ListVideosData = ({
  columns,
  rows,
  page,
  rowsPerPage,
  totalRows,
  loading,
  actions,
  error,
  sortBy,
  sortOrder,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onAction,
  fromDailog,
  selectedRows,
  setSelectedRows,
  selectAllPages,
  allowInlineEdit,
}) => {
  const colSpan = columns.length + (actions.length ? 1 : 0);
  const WrapperElement = allowInlineEdit ? 'form' : 'div';
  const { control, handleSubmit, getValues, setValue } = useForm();

  const [anchorActionEl, setAnchorActionEl] = useState(null);
  const [openActions, setOpenActions] = useState({
    show: false,
    data: null,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [clickedRowData, setClickedRowData] = useState({});
  const initialFormValue = useRef({});

  const handleActionOpen = (e, selectedRow) => {
    setAnchorActionEl(e.currentTarget);
    setOpenActions({
      ...openActions,
      show: true,
      data: selectedRow,
    });
  };
  const handleActionClose = () => {
    setAnchorActionEl(null);
    setOpenActions({
      ...openActions,
      show: false,
      data: null,
    });
  };
  const handleActionSelect = (selectedAction) => {
    onAction(selectedAction, openActions.data);
    handleActionClose();
  };
  const handleSingleActionSelect = (data) => {
    onAction(actions[0].action, data);
    handleActionClose();
  };

  const isPageSelected =
    rows.length > 0 && rows.every((row) => selectedRows.includes(row.id));

  const handleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handlePageSelection = () => {
    const pageRowIds = rows.map((row) => row.id);
    if (isPageSelected) {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => !pageRowIds.includes(id))
      );
    } else {
      setSelectedRows((prevSelected) => [
        ...new Set([...prevSelected, ...pageRowIds]),
      ]);
    }
  };

  const onFormSubmit = (data) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    setFormSubmitted(true);

    if (formSubmitted) return;

    const preparePayload = Object.entries(data).reduce((acc, [key, value]) => {
      const [, videoId, field] = key.split('-');

      if (initialFormValue.current[`cellvalue-${videoId}-${field}`] !== value) {
        if (!acc[videoId]) {
          acc[videoId] = {};
        }
        acc[videoId][field] =
          typeof value === 'boolean' ? Number(value) : value;
      }

      return acc;
    }, {});

    if (!Object.keys(preparePayload).length) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'info',
          message: 'There are no edits made in the data.',
        });
        setFormSubmitted(false);
      }, 50);
      return;
    }

    const payload = {
      videosData: preparePayload,
    };
    inlineUpdateDataByIds(payload)
      .then((res) => {
        if (res.data?.success) {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'Video details updated successfully.',
          });
          initialFormValue.current = getValues();
          setFormSubmitted(false);
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    rows.forEach((r) => {
      columns.forEach((c) => {
        setValue(
          `cellvalue-${r.id}-${c.dataKey}`,
          r[c.dataKey] ||
            getEditableCellDefaultValue(
              c.dataKey,
              DYNAMIC_COLUMNS_CONFIG[c.dataKey]?.editCellType
            )
        );
      });
    });
    if (fromDailog && selectAllPages) {
      setSelectedRows(rows.map((row) => row.id));
    }
    initialFormValue.current = getValues();
  }, [selectAllPages, rows]);

  return (
    <>
      <WrapperElement
        id={allowInlineEdit ? 'inline-edit-form' : undefined}
        onSubmit={allowInlineEdit ? handleSubmit(onFormSubmit) : () => {}}
      >
        {totalRows > 0 && (
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              onRowsPerPageChange(Number(e.target.value));
            }}
          />
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {fromDailog && !loading && !error && rows.length !== 0 && (
                  <TableCell align="center" width="2%">
                    <Checkbox
                      checked={isPageSelected}
                      onChange={handlePageSelection}
                    />
                  </TableCell>
                )}

                {actions.length > 0 && (
                  <TableCell align="center" width="5%">
                    Action
                  </TableCell>
                )}
                {columns.map((c) => (
                  <TableCell
                    key={`col-${c.id}`}
                    align={c.align}
                    width={c.width}
                    sx={{
                      cursor: c.sortable ? 'pointer' : 'inherit',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                    onClick={() => {
                      if (c.sortable) {
                        if (c.dataKey === sortBy) {
                          if (sortOrder === '') {
                            onSortChange(c.dataKey, 'asc');
                          } else if (sortOrder === 'asc') {
                            onSortChange(c.dataKey, 'desc');
                          } else if (sortOrder === 'desc') {
                            onSortChange('', '');
                          }
                        } else {
                          onSortChange(c.dataKey, 'asc');
                        }
                      }
                    }}
                  >
                    <Stack direction="row" justifyContent={c.align}>
                      {c.sortable && (
                        <Sortable
                          dataKey={c.dataKey}
                          sortBy={sortBy}
                          sortOrder={sortOrder}
                        />
                      )}
                      {c.label}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {!loading && !error && (
                <>
                  {rows.map((r, index) => (
                    <TableRow key={`row-${r[columns[0].dataKey]}-${index}`}>
                      {fromDailog && (
                        <TableCell align="center" width="2%">
                          <Checkbox
                            checked={selectedRows.includes(r.id)}
                            onChange={() => {
                              handleRowSelection(r.id);
                            }}
                          />
                        </TableCell>
                      )}

                      {actions.length > 1 && (
                        <TableCell align="center" width="5%">
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={(e) => handleActionOpen(e, r)}
                          >
                            <Iconify icon="eva:more-vertical-fill" />
                          </IconButton>
                        </TableCell>
                      )}

                      {actions.length === 1 && (
                        <TableCell align="right" width="5%">
                          <Button
                            size="small"
                            color="info"
                            variant="contained"
                            onClick={() => handleSingleActionSelect(r)}
                          >
                            {actions[0].label}
                          </Button>
                        </TableCell>
                      )}
                      {columns.map((c) => (
                        <TableCell
                          key={`data-col-${c.id}-${r[c.dataKey]}`}
                          align={c.align}
                          width={c.width}
                          sx={{
                            padding: '16px',
                            fontSize: '0.875rem !important',
                          }}
                          variant="string"
                        >
                          {c.editable && allowInlineEdit ? (
                            <Controller
                              name={`cellvalue-${r.id}-${c.dataKey}`}
                              control={control}
                              defaultValue={
                                r[c.dataKey] ||
                                getEditableCellDefaultValue(
                                  c.dataKey,
                                  DYNAMIC_COLUMNS_CONFIG[c.dataKey]
                                    ?.editCellType
                                )
                              }
                              render={({ field: { onChange, value } }) => (
                                <EditableCellValue
                                  type={
                                    DYNAMIC_COLUMNS_CONFIG[c.dataKey]
                                      .editCellType
                                  }
                                  dataKey={c.dataKey}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                          ) : (
                            <CellValue
                              type={c.type}
                              dataKey={c.dataKey}
                              value={r[c.dataKey]}
                              rowData={r}
                              clickedRowData={clickedRowData}
                              handleShowTooltip={() => {
                                if (r?.id === clickedRowData?.id) {
                                  setClickedRowData({});
                                } else {
                                  setClickedRowData(r);
                                }
                              }}
                            />
                          )}

                          {!allowInlineEdit && c.secondaryFields && (
                            <>
                              {c.secondaryFields.map((sf, sfIdx) => (
                                <React.Fragment
                                  key={`sf-data-col-${c.id}-${sfIdx}`}
                                >
                                  <br />
                                  <CellValue
                                    type={sf.type}
                                    dataKey={sf.dataKey}
                                    value={r[sf.dataKey]}
                                    color="secondary"
                                  />
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              )}

              {!loading && !error && totalRows === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={colSpan}>
                    <NoData />
                  </TableCell>
                </TableRow>
              )}

              {!loading && error && totalRows === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={colSpan}>
                    <Error />
                  </TableCell>
                </TableRow>
              )}

              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={colSpan}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalRows > 0 && (
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={(e, newPage) => onPageChange(newPage)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {
              onRowsPerPageChange(Number(e.target.value));
            }}
          />
        )}

        {openActions.show && (
          <Menu
            id="actions-menu"
            anchorEl={anchorActionEl}
            open
            onClose={() => handleActionClose()}
            size="small"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {actions.map((a, index) => {
              let render = true;
              if (a.conditional) {
                render = a.conditional(openActions.data);
              }

              if (render) {
                return (
                  <MenuItem
                    key={`mia-${a.action}-${index}`}
                    onClick={() => handleActionSelect(a.action)}
                    disabled={
                      a.conditionalDisabled &&
                      a.conditionalDisabled(openActions.data)
                    }
                  >
                    <Typography variant="body2">{a.label}</Typography>
                  </MenuItem>
                );
              }

              return null;
            })}
          </Menu>
        )}
      </WrapperElement>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListVideosData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  fromDailog: PropTypes.bool,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectAllPages: PropTypes.bool,
  allowInlineEdit: PropTypes.bool,
};
ListVideosData.defaultProps = {
  onAction: () => {},
  fromDailog: false,
  selectedRows: [],
  setSelectedRows: () => {},
  selectAllPages: false,
  allowInlineEdit: false,
};

export default ListVideosData;
