import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
  alpha,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import PlaylistLevels from '../form-elements/playlist-level.component';
import Channels from '../form-elements/channels.component';
import useResponsive from '../../hooks/responsive.hook';

const FilterContentPlaylists = ({ values, onCancel, onSuccess, onClear }) => {
  const isDesktop = useResponsive('up', 'lg');
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  useEffect(() => {
    setFilters({ ...values });
  }, [values]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 1, py: 2, minHeight: isDesktop ? '92px' : 76 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1, textAlign: 'center' }}>
          Filters
        </Typography>
        <IconButton
          color="inherit"
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: '100%',
            width: 'auto',
            padding: '8px',
            bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
              boxShadow: 'none',
            },
          }}
          onClick={onCancel}
        >
          <Iconify icon="mdi:menu-close" />
        </IconButton>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <TextField
              id="fl_search"
              name="fl_search"
              label="Search"
              variant="filled"
              value={filters.search?.value}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  search: { ...filters.search, value: e.target.value },
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />
            <Channels
              id="fl_channels"
              name="fl_channels"
              label="Channels"
              defaultValue={filters.channels?.value}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  channels: { ...filters.channels, value: v },
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />
            <PlaylistLevels
              id="fl_playlist_level"
              name="fl_playlist_level"
              label="Playlist level"
              defaultValue={filters.playlistLevel?.value}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  playlistLevel: {
                    ...filters.playlistLevel,
                    value: e.target.value,
                  },
                });
              }}
              validOptions={[1, 2]}
              sx={{ width: '100%', my: 1 }}
            />
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterContentPlaylists.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterContentPlaylists;
