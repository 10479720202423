import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import { viewDataById } from '../../services/videos.service';
import ROUTE_PATH from '../../config/routes.config';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import PageNotFoundView from '../errors/not-found.view';
import ConfirmPopup from '../../components/common/confirm-popup.component';
import VideoMetadata from '../../components/content/video-metadata.component';
import VideoSeriesCategorization from '../../components/content/video-series-categorization.component';
import VideoRatingClassification from '../../components/content/video-rating-classification.component';
import VideoStatus from '../../components/content/video-status.component';
import VideoDistributorMonetization from '../../components/content/video-distributor-monetization.component';
import VideoScheduling from '../../components/content/video-scheduling.component';
import VideoSystemAdmin from '../../components/content/video-system-admin.component';
import VideoImdbSettings from '../../components/content/video-imdb-settings.component';
import VideoOthers from '../../components/content/video-others.component';
import VideoManageAssignments from '../../components/content/video-manage-assignments.component';

const TABS_CONFIG = [
  { value: 'metadata', label: 'Basic Metadata', component: VideoMetadata },
  {
    value: 'series',
    label: 'Series & Categorization',
    component: VideoSeriesCategorization,
  },
  {
    value: 'rating',
    label: 'Rating & Classification',
    component: VideoRatingClassification,
  },
  { value: 'status', label: 'Status', component: VideoStatus },
  {
    value: 'distributor',
    label: 'Distributor & Monetization',
    component: VideoDistributorMonetization,
  },
  { value: 'scheduling', label: 'Scheduling', component: VideoScheduling },
  {
    value: 'systemAdmin',
    label: 'System & Admin',
    component: VideoSystemAdmin,
  },
  { value: 'imdb', label: 'IMDB Settings', component: VideoImdbSettings },
  { value: 'others', label: 'Others', component: VideoOthers },
  {
    value: 'assignments',
    label: 'Assignments',
    component: VideoManageAssignments,
  },
];

const VideoEditView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('metadata');
  const [loading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [videoDetail, setVideoDetail] = useState({});
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [hasFormValueChanged, setHasFormValueChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const tabValueRef = useRef('');

  const formValueChangedCallback = (val) => {
    setHasFormValueChanged(val);
  };

  const handleRefreshData = () => {
    setReloadCounter(reloadCounter + 1);
  };

  const handleTabSwitchSuccess = () => {
    setTabValue(tabValueRef.current);
    setHasFormValueChanged(false);
    setShowWarning(false);
  };

  useEffect(() => {
    viewDataById(id)
      .then((res) => {
        setVideoDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShowPageNotFound(true);
          return;
        }
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [id, reloadCounter]);

  return Number.isNaN(Number(id)) || showPageNotFound ? (
    <PageNotFoundView />
  ) : (
    <AppLayout
      pageTitle={`Edit Video Details - ${videoDetail.title}`}
      showBack
      handleBack={() => navigate(ROUTE_PATH.VIDEOS)}
    >
      <Box height="100%" display="flex">
        <Tabs
          value={tabValue}
          aria-label="tabs"
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="transparent"
          sx={{ minWidth: 150, maxWidth: 150 }}
          onChange={(_, v) => {
            if (hasFormValueChanged) {
              tabValueRef.current = v;
              setShowWarning(true);
            } else {
              setTabValue(v);
            }
          }}
        >
          {TABS_CONFIG.map((tab, index) => (
            <Tab
              key={index + 1}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabValue === tab.value && '#fff',
                borderLeft: tabValue === tab.value && '2px solid #FD0100',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            />
          ))}
        </Tabs>

        <Box flexGrow={1}>
          {loading && <Loading />}

          {!loading && (
            <>
              {TABS_CONFIG.map(({ value, component: Component }) =>
                tabValue === value ? (
                  <Component
                    key={value}
                    videoDetail={videoDetail}
                    type={tabValue}
                    handleRefreshData={handleRefreshData}
                    formValueChangedCallback={formValueChangedCallback}
                  />
                ) : null
              )}
            </>
          )}
        </Box>
      </Box>

      {showWarning && (
        <ConfirmPopup
          title="Tab Switch Warning"
          message="Do you want to switch tab without saving data? Before switching tab, save your changes otherwise you lost all your changes and can not undo this action!"
          onCancel={() => setShowWarning(false)}
          onSuccess={() => handleTabSwitchSuccess()}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default VideoEditView;
