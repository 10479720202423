import CELL_DATA_TYPES from '../cell-types.config';

// Workspace module configurations
export const LISTING_COLUMNS = [
  {
    id: 'fields',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'fields',
    sortable: false,
    type: CELL_DATA_TYPES.SUB_LIST,
  },
  {
    id: 'name',
    label: 'Name',
    width: '25%',
    align: 'left',
    dataKey: 'name',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'right',
    dataKey: 'status',
    sortable: false,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const SUB_LISTING_COLUMNS = [
  {
    id: 'fieldName',
    align: 'left',
    dataKey: 'fieldName',
    type: CELL_DATA_TYPES.TEXT,
  },
];
