import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  Grid,
  Box,
  Stack,
} from '@mui/material';
import JsFileDownloader from 'js-file-download';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  downloadExportedCsvReport,
  exportCsvFile,
  getExportedCsvList,
} from '../../services/import-export.service';
import ImportExportDataList from '../form-elements/import-export-data-list.component';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';

const ListExport = () => {
  const [exportsData, setExportsData] = useState({});
  const [downloadFile, setDownloadFile] = useState(null);
  const [options, setOptions] = useState({
    reloadCounter: 0,
    loading: true,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [processing, setProccessing] = useState(false);

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleExportFile = () => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    setProccessing(true);

    exportCsvFile()
      .then((res) => {
        if (res.data.success) {
          setProccessing(false);
          handleRefreshData();
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setProccessing(false);
      });
    // }
  };

  const handleDownloadFile = () => {
    setProccessing(true);
    downloadExportedCsvReport(downloadFile.id)
      .then((res) => {
        setProccessing(false);
        // eslint-disable-next-line no-unused-vars
        const download = new JsFileDownloader(
          res,
          `${downloadFile.downloadFileName}`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setProccessing(false);
      });
  };

  useEffect(() => {
    getExportedCsvList()
      .then((res) => {
        setExportsData(res.data);
        setDownloadFile(res.data?.[0] || null);
        setOptions({ ...options, loading: false });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setOptions({ ...options, loading: false });
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 0, py: 2 }}>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      {options.loading && <Loading />}

      {!options.loading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                title="Export Database to CSV"
                subheader="Export current video database to CSV format for whitelabel provider. Select latest exports from options below."
              />

              <CardContent>
                <Box display="flex" alignItems="flex-end" gap={2}>
                  <ImportExportDataList
                    id="export_csv"
                    name="export_csv"
                    defaultValue={downloadFile}
                    onChange={(e) => setDownloadFile(e.target.value)}
                    validOptions={exportsData || []}
                    sx={{ width: '100%' }}
                  />

                  <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    disabled={!exportsData?.length || processing}
                    onClick={() => handleDownloadFile()}
                  >
                    Download
                  </Button>
                </Box>
              </CardContent>

              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Button
                  component="label"
                  variant="contained"
                  color="primary"
                  disabled={processing}
                  onClick={() => handleExportFile()}
                  startIcon={processing && <Iconify icon="eos-icons:loading" />}
                >
                  Export
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListExport;
